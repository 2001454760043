import axios from 'axios';
import { clearAuthData, getRefreshToken, getToken, saveToken, useAuthService } from './services/authService';
import { Navigate } from 'react-router-dom';
//https://rete-newra-api.zusedigital.com
//https://rete-newra-api.zusedigital.com
let isRefreshing = false;
let refreshSubscribers = [];
const excludedPaths = ['/auth/login']; 
function onRefreshed(token) {
  refreshSubscribers.map(callback => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

function createAPI() { 

  const API = axios.create({
    baseURL: 'https://rete-newra-api.zusedigital.com/v1',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  API.interceptors.request.use(
    async config => { 
      const requiresAuth = config?.headers['Requires-Auth'];
      if (requiresAuth && !excludedPaths.some(path => config.url.includes(path))) {
        const token = getToken();
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      return config;
    },
    error => Promise.reject(error)
  );

  API.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config; 
      if (error.response?.status === 401 && !originalRequest._retry && !excludedPaths.some(path => originalRequest.url.includes(path))) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            addRefreshSubscriber(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(API(originalRequest));
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const refreshToken = getRefreshToken();
          const response = await axios.post('https://rete-newra-api.zusedigital.com/v1/auth/refresh', { refreshToken });

          const newToken = response.data.tokens.accessToken;
          saveToken(newToken);

          API.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
          originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

          onRefreshed(newToken);

          return API(originalRequest);
        } catch (err) {
          clearAuthData();
          Navigate('/auth/signin');
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );

  return API;
}

export default createAPI;
