import { useCallback, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import DotsDropdown from "./components/DotsDropdown";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Canvas from "./view/Canvas";
import WorkFlow from "./view/home/workflows";
import NotFoundPage from "./view/404/404";
import Credentials from "./view/home/credentials";
import CustomNode from "./view/home/custom-node";
import Users from "./view/home/users";
import Signin from "./view/auth/signin";
import Signup from "./view/auth/signup";
import Forgot from "./view/auth/forgot";
import Reset from "./view/auth/reset";
import { ToastContainer } from "react-toastify";
import { AuthProvider, useAuth } from "./context/AuthContext";

// Protected Route: Only accessible when authenticated
const ProtectedRoute = ({ element: Component }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Component /> : <Navigate to="/auth/signin" />;
};

// Public Route: Accessible to all users, regardless of authentication
const PublicRoute = ({ element: Component }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/home/workflow" /> : <Component />;
};

// Define the router with Data Routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home/workflow" />,
  },
  {
    path: "/auth",
    children: [
      {
        path: "signin",
        element: <PublicRoute element={Signin} />,
      },
      {
        path: "signup",
        element: <PublicRoute element={Signup} />,
      },
      {
        path: "forgot",
        element: <PublicRoute element={Forgot} />,
      },
      {
        path: "reset",
        element: <PublicRoute element={Reset} />,
      },
    ],
  },
  {
    path: "/home",
    children: [
      {
        path: "workflow",
        element: <ProtectedRoute element={WorkFlow} />,
      },
      {
        path: "workflow/new",
        element: <ProtectedRoute element={Canvas} />,
      },
      {
        path: "workflow/:id",
        element: <ProtectedRoute element={Canvas} />,
      },
      {
        path: "credentials",
        element: <ProtectedRoute element={Credentials} />,
      },
      {
        path: "custom-node",
        element: <ProtectedRoute element={CustomNode} />,
      },
      {
        path: "users",
        element: <ProtectedRoute element={Users} />,
      },
    ],
  },
  {
    path: "/about",
    element: <ProtectedRoute element={Canvas} />,
  },
  {
    path: "/contact",
    element: <ProtectedRoute element={Canvas} />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default function App() {
  return (
    <AuthProvider>
      <ToastContainer />
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
