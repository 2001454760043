import { ClassicPreset, NodeEditor } from "rete"; 
import { Schemes } from "./rete/types";
import { ActionSocket, TextSocket } from "./editor";
import { format, formatDistanceToNow } from 'date-fns';
type Sockets = ActionSocket | TextSocket;
type Input = ClassicPreset.Input<Sockets>;
type Output = ClassicPreset.Output<Sockets>;

export function getConnectionSockets(
  editor: NodeEditor<Schemes>,
  connection: Schemes["Connection"]
) {
  const source = editor.getNode(connection.source);
  const target = editor.getNode(connection.target);

  const output =
    source &&
    (source.outputs as Record<string, Input>)[connection.sourceOutput];
  const input =
    target && (target.inputs as Record<string, Output>)[connection.targetInput];

  return {
    source: output?.socket,
    target: input?.socket
  };
}

export const URLS={
  BACK_END:'https://rete-newra-api.zusedigital.com',
  CLOUD_FRONT:'https://d36gmzmklsuppb.cloudfront.net/'
}
export const formatWorkflowInfo = (data) => {
  const { user, created_at, updated_at } = data;

  // Handle cases where the fields may be missing
  if (!created_at || !updated_at || !user || !user.fname) return '';

  // Format "Last updated X hours ago"
  const lastUpdated = formatDistanceToNow(new Date(updated_at), { addSuffix: true });

  // Format "Created DD Month"
  const createdDate = format(new Date(created_at), 'd MMMM');

  // Return the formatted string
  return `Last updated ${lastUpdated} by ${user.fname} • Created ${createdDate}`;
};
export function getSourceConnectionIds(editor){
  let sourceIds=[]
  editor.connections.forEach(val=>{
    console.log(val.source)
    sourceIds.push(val.source)
  })
  return sourceIds
}
export function getInputConnectedNodes(editor){
  let connectedIds= getSourceConnectionIds(editor)
  let connectedNodes=[]
  editor.nodes.forEach(val=>{
      if(connectedIds.includes(val.id)){
        connectedNodes.push({
          id:val.id,
          label:val.label,
        })
      } 
  }) 
  return connectedNodes
}

export function isValidJsonCustom(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
}
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const isExpression = (value) => {
  const regex = /\{\{(.*?)\}\}/g;
  return regex.test(value);
}
const extractKeysFromExpression = (expression) => {
  const regex = /\{\{(.*?)\}\}/g;
  let matches;
  const keys = [];
  while ((matches = regex.exec(expression)) !== null) {
    keys.push(matches[1]);
  }
  return keys;
};
export function getDropValue(keyData,inputData){
  const keys = extractKeysFromExpression(keyData);
  console.log(keys)
  const value = keys.map(key => getValueFromKey(key, inputData[0]));
  return value.join('')
}
export function createAxiosConfig(formData) {
  // Initialize the Axios config object
  let config = {
    method: formData.methord.toLowerCase(), // Set the HTTP method
    url: formData.url.trim(), // Set the URL
  };

  // Handle query parameters if they exist
  if (formData.sendQueryParameter && formData.queryparameter.length > 0) {
    const queryParams = formData.queryparameter
      .map(param => `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`)
      .join('&');
    config.url += `?${queryParams}`; // Append query parameters to the URL
  }

  // Handle headers if specified
  if (formData.sendHeaders && formData.specifyHeaders.length > 0) {
    config['headers'] = formData.specifyHeaders.reduce((headers, header) => {
      headers[header.key] = header.value;
      return headers;
    }, {});
  }

  // Handle body data for POST, PUT, or PATCH methods
  if (formData.sendBody && ['post', 'put', 'patch'].includes(config.method) && formData.body.length > 0) {
    config['data'] = formData.body.reduce((data, item) => {
      data[item.key] = item.value;
      return data;
    }, {});
  }

  return config; // Return the constructed Axios config object
}
export function replaceExpressionsInForm(formData, jsonArray) {
  // Regular expression to match all expressions like {{ $json['path'] }}
  const expressionRegex = /\{\{\s*\$json([^\}]+)\s*\}\}/g;

  // Helper function to get value from the JSON path
  function getValueFromPath(expression, jsonArray) {
    // Clean and split the expression to handle paths like $json['data'][0]['id']
    const pathSegments = expression.trim().match(/[^[\].]+/g); // Extract keys and indices

    // Traverse the JSON to find the value
    let value = jsonArray[0]; // Assuming we are using the first object in the array
    for (let segment of pathSegments) {
      if (value && typeof value === 'object') {
        if (!isNaN(segment)) {
          // Handle array indexing if the segment is numeric
          value = value[parseInt(segment, 10)];
        } else {
          value = value[segment]; // Handle object keys
        }
      } else {
        return null; // Return null if the path doesn't exist
      }
    }

    return value !== undefined ? value : null; // Return the value or null if not found
  }
 
  // Recursive function to process each part of the form data
  function processFormData(data) {
    if (typeof data === 'string') {
      // If data is a string, replace any expressions within it
      return data.replace(expressionRegex, (match, expression) => {
        const value = getValueFromPath(expression, jsonArray);
        return value !== null ? value : ''; // Replace expression with value or empty string
      });
    } else if (Array.isArray(data)) {
      // If data is an array, recursively process each element
      return data.map(item => processFormData(item));
    } else if (typeof data === 'object' && data !== null) {
      // If data is an object, recursively process each key-value pair
      const result = {};
      for (const key in data) {
        result[key] = processFormData(data[key]); // Recursively process the value
      }
      return result;
    }

    return data; // Return data as-is if it's not a string, array, or object
  }

  // Process the form data recursively
  return processFormData(formData);
}
export function hasWebhookNode(workflowDataJson) {
  // Step 1: Parse the JSON
  let workflowData;
  try {
    workflowData =  workflowDataJson;
  } catch (error) {
    console.error("Invalid JSON data:", error);
    return false;
  }

  // Step 2: Get Nodes
  const nodes = workflowData?.rete?.nodes;

  if (!nodes || !Array.isArray(nodes)) {
    console.error("Invalid nodes data");
    return false;
  }

  // Step 3: Check if Webhook Trigger Node Exists
 
  const webhookNode = nodes.find(node => node.nodeData.name === "Webhook");
  // If no "Webhook Trigger" node found, return false
  return webhookNode;
}
const getValueFromKey = (key, data) => {
  if (!key) return '';

  const keys = key.split(/\.|\[|\]/).filter(k => k); // Handle dot notation and array indices
  let value = data;

  for (const k of keys) {
    if (value[k] !== undefined) {
      value = value[k];
    } else {
      return '';
    }
  }

  return value;
};

export function countWords(str) { 
  // Split the string by spaces and filter out any empty strings
  return str !=undefined?str.length:0;
}

export function blobToBase64(blobUrl: string): Promise<string> {
  return fetch(blobUrl)
    .then(response => response.blob())
    .then(blob => {
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            // Strip the "data:*/*;base64," prefix
            resolve(reader.result.split(',')[1]);
          } else {
            reject(new Error('FileReader result is not a string'));
          }
        };
        reader.onerror = () => reject(new Error('Failed to read blob as data URL'));
        reader.readAsDataURL(blob);
      });
    });
}
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file); // This will encode the file as a Base64 string
  });
}
export const validateImageDimensions = (base64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === 24 && img.height === 24) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = () => reject(new Error("Error loading image"));
    img.src = base64.file; // Directly set the Base64 string as the image source
  });
};