import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CodeEditor from "./codeiditor";
import Switch from "react-switch";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HttpNode from "../customization/Httpnode";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import DynamicKeyValueInputPagenation from "./dynamicinputPagination";
import Popup from "reactjs-popup";
import { useFormik } from "formik";
import * as yup from "yup";
import DynamicTable from "../components/Dynamictable";
import {
  countWords,
  createAxiosConfig,
  fileToBase64,
  getDropValue,
  getInputConnectedNodes,
  isExpression,
  isValidJsonCustom,
  replaceExpressionsInForm,
  URLS,
  validateImageDimensions,
} from "../utils";
import JSONInputWrapper from "../components/JSONInputWrapper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RecursiveComponent from "../components/RecursiveComponent";
import DroppableForm from "../components/DroppableForm";
import DroppableInput from "../components/DroppableInput";
import FixedExpressionTabs from "../components/FixedExpressionTabs";
import DroppableSelect from "../components/DroppableSelect";
import axios from "axios";
import {
  copyNodeWithConnections,
  copyNodeWithConnectionsCopy,
  deleteNodes,
} from "../editor";
import parse from "@bany/curl-to-json";
import { toast } from "react-toastify";
import ImportCurlCode from "../components/view/importcurl-popup";
import DynamicKeyValueFields from "../components/form/dynamicFieldsvalues";
import AddCategoryPopup from "../components/form/addCategoryPopup";
import createAPI from "../api";
import * as Yup from 'yup';
import HttpNodeCustomNode from "../customization/HttpnodecustomNode";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: HttpNodeCustomNode & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid #babfc6;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 280px;
  height: 61px;
  padding-left: 11px;
  position: relative;
  user-select: none;
  outline: 0px solid #146de0;
  box-shadow: 0 0 0 0 #cad8f8;
  &:hover {
    outline: 2px solid #146de0;
  }
  &:focus {
    outline: 2px solid #146de0;
    box-shadow: 0 0 0 6px #cad8f8;
  }
  ${(props) =>
    props.selected &&
    css`
      outline: 2px solid #146de0;
      box-shadow: 0 0 0 6px #cad8f8;
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -9px;
    height: 20px;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background-image: url(../../images/node_main_connector.svg);
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: right;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -12px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  div[title="socket"] {
    display: none;
  }
  ${(props) => props.styles && props.styles(props)}
`;
export function HttpNodeComponentCustomNode(props: Props<Schemes>) {
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [outPutData, setOutputData] = React.useState([]);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [showCurlPopup, setCurlPopup] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [inputData, setInputData] = React.useState([]);
  const [isMainFormSubmitted, setIsMainFormSubmitted] = React.useState(false);
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const { id } = props.data;

  React.useEffect(() => {
    console.log("Updated outPutData:", outPutData);
  }, [outPutData]);
  React.useEffect(() => {
    console.log(props.data.response?.data);
    setOutputData([props.data.response?.data]);
  }, [props.data.response]);
  function handelInputChange(key, value) {
  }

  const closeCurlPopup = () => {
    setCurlPopup(false);
    // toggleVisibility(false);
  };

  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  const options2 = [
    { value: "DELETE", label: "DELETE", no: 1 },
    { value: "GET", label: "GET", no: 1 },
    { value: "HEAD", label: "HEAD", no: 1 },
    { value: "PATCH", label: "PATCH", no: 1 },
    { value: "POST", label: "POST", no: 1 },
    { value: "PUT", label: "PUT", no: 1 },
  ];

  const options3 = [
    { value: "Using Fields Below", label: "Using Fields Below", no: 1 },
    { value: "Using JSON", label: "Using JSON", no: 1 },
  ];

  React.useEffect(() => {
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      console.log(props.data.dataflow);
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val)
          if (isValidJsonCustom(val?.input) && typeof JSON.parse(val?.input[0]) != "object") {
            setInputData(JSON.parse(val?.input[0]));
          } else if (isValidJsonCustom(val?.input) && typeof JSON.parse(val?.input[0]) == "object" && !Array.isArray(JSON.parse(val?.input[0]))) {
            setInputData([JSON.parse(val?.input[0])]);
          } else if (isValidJsonCustom(val?.input) && Array.isArray(JSON.parse(val?.input[0]))) {
            setInputData(JSON.parse(val?.input[0]));
          }
        });
    }
  }, [showpopUp]);

  const formik = useFormik({
    initialValues:
      props.data.controls.dataJson.value != "" &&
        isValidJsonCustom(props.data.controls.dataJson.value)
        ? JSON.parse(props.data.controls.dataJson.value)
        : {
          methord: props.data.customNode?.method,
          url: props.data.customNode?.url,
          sendQueryParameter: props.data.customNode?.queryParameters.length ? true : false,
          specifyQueryParameters: "Using Fields Below",
          queryparameter: [],
          specifyBody: "Using Fields Below",
          sendHeaders: props.data.customNode?.headers.length ? true : false,
          specifyHeadersType: "Using Fields Below",
          specifyHeaders: [],
          sendBody: props.data.customNode?.body.length ? true : false,
          body: props.data.customNode?.body,
        },
    onSubmit: async (values) => {
      try {
        setIsMainFormSubmitted(true);
        props.data.controls.dataJson.value = JSON.stringify(values);

        if (props.data.validateData(values)) {
          let config = replaceExpressionsInForm(values, inputData);
          console.log(config);
          config = createAxiosConfig(config);
          axios(config)
            .then((response) => {
              setIsMainFormSubmitted(false);
              props.data.httpData = JSON.stringify(response.data);
              setOutputData([response.data]); // Ensure only the response data is stored
            })
            .catch((error) => {
              setIsMainFormSubmitted(false);
              const errorMessage = error.response
                ? error.response.data.message || "An error occurred while submitting the form"
                : error.message;

              toast(errorMessage, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "light",
              });
              console.error("Error:", error);
            });
        }
      } catch (error) {
        setIsMainFormSubmitted(false);
        toast("Error submitting the form:", error.msg);
        console.error("Error submitting the form:", error);
      }
    },
  });
  React.useEffect(() => {
    //setAllFormValues(formik.values); // Assign all form values to a variable
    props.data.controls.dataJson.value = JSON.stringify(formik.values);
  }, [formik.values]);
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  const handleExpressionChange = (field, value) => {
    formik.setFieldValue(field, value);
  };
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label;
  }
  const handleChangeLable = (e) => {
    let newName = e.target.value;
    if (newName.length > 30) {
      // Limit the input length to 20 characters
      //console.log("Maximum character length is 20");
      return;
    }
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
  };



  const [leftWidth, setLeftWidth] = useState("calc(50% - 246px)");
  const [rightWidth, setRightWidth] = useState("calc(50% - 246px)");
  const containerRef = useRef(null);
  const dragging = useRef(false);

  const handleMouseDown = () => {
    dragging.current = true;
    document.body.classList.add("no-select");
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (dragging.current && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width; // Get container width
      const midOffset = 492; // Static size for the middle div
      const minSize = 400; // Minimum size for left and right divs

      // Calculate new left width based on the mouse position relative to the container
      const newLeftWidth = e.clientX - midOffset / 2;
      const newRightWidth = containerWidth - newLeftWidth - midOffset;

      // Apply minimum width constraint and update state
      if (newLeftWidth >= minSize && newRightWidth >= minSize) {
        setLeftWidth(`${newLeftWidth}px`);
        setRightWidth(`${newRightWidth}px`);
      }
    }
  };

  const handleMouseUp = () => {
    dragging.current = false;
    document.body.classList.remove("no-select");
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };
  const handleCurlImport = (curlCommand) => {
    console.log(curlCommand);
    try {
      const parsedCurl = parse(curlCommand); // Use parse-curl package
      console.log(parsedCurl);
      const queryParams = getQueryParams(parsedCurl.url);
      formik.resetForm();
      formik.setValues({
        methord: "GET",
        url: "",
        sendQueryParameter: false,
        specifyQueryParameters: "Using Fields Below",
        queryparameter: [],
        specifyBody: "Using Fields Below",
        sendHeaders: false,
        specifyHeadersType: "Using Fields Below",
        specifyHeaders: [],
        sendBody: false,
        body: [],
      });
      formik.setValues({
        methord: parsedCurl.method || "get", // Default method as "get" if not found
        url: parsedCurl.url || "", // Default empty if URL is not found
        sendQueryParameter: false, // Default to false, can be customized later
        specifyQueryParameters: "Using Fields Below", // Default value
        queryparameter:
          parsedCurl.params && Object.keys(parsedCurl.params).length > 0
            ? convertObjectToArray(parsedCurl.params)
            : [], // Default empty array
        specifyBody: "Using Fields Below", // Default value
        sendHeaders: Object.keys(parsedCurl.header).length > 0,
        specifyHeadersType: "Using Fields Below", // Default value
        specifyHeaders:
          Object.keys(parsedCurl.header).length > 0
            ? Object.keys(parsedCurl.header).map((key) => ({
              key,
              value: parsedCurl.header[key],
            }))
            : [],
        sendBody: Object.keys(parsedCurl.data).length > 0, // Send body if data is available
        body:
          Object.keys(parsedCurl.data).length > 0
            ? convertObjectToArray(parsedCurl?.data)
            : [],
      });
      toast.success("cURL command imported successfully!");
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      // Inspect what methods are exported
      toast.error("Failed to import cURL command.");
      console.error(error);
    }
  };
  function getQueryParams(url) {
    try {
      // Create a URL object
      const urlObj = new URL(url);

      // Extract the query parameters
      const queryParams = new URLSearchParams(urlObj.search);

      // Convert query parameters to a plain object
      const paramsObject = {};
      queryParams.forEach((value, key) => {
        paramsObject[key] = value;
      });

      return paramsObject;
    } catch (error) {
      console.error("Invalid URL:", error.message);
      return null;
    }
  }
  function convertObjectToArray(data) {
    try {
      // Check if input is an object
      if (typeof data !== "object" || data === null || Array.isArray(data)) {
        throw new Error("Input must be a valid object");
      }

      // Convert the object to an array of key-value pairs
      const result = Object.keys(data).map((key) => {
        if (typeof key !== "string") {
          throw new Error("Object keys must be strings");
        }
        return { key: key, value: data[key] };
      });

      return result;
    } catch (error) {
      console.error("Error converting object:", error.message);
      return []; // Return an empty array in case of an error
    }
  }
  useEffect(() => {
    console.log(props.data.label);
  }, []);

  /* 
  Custom Http Node Codes
  */
  const [httpCategory, setHttpCategory] = React.useState([
    { value: "create_new", label: "Create New Caregory" },
  ]);
  const API = createAPI();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [saveAsDefaultValue, setSaveAsDefaultValue] = React.useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),

    category: Yup.string().required('Category is required'),
    mode: Yup.string().oneOf(['public', 'private']).required('Mode is required'),
  });
  const formikSaveNode = useFormik({
    initialValues: {
      name: "",
      description: "",
      nodeIcon: null,
      method: "GET",
      url: "",
      queryParameters: [],
      headers: [],
      body: [],
      //  authentication: "", 
      category: "",
      mode: "public",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        let apiData = API.post(
          `custom-node/create`,
          values,
          {
            headers: {
              "Requires-Auth": true, // This tells the interceptor to add the auth token
            },
          }
        )
          .then((data) => {
            //formikSaveNode.resetForm(); 
            //setId()
            toast("Custom node save successfully", {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          })
          .catch((err) => {

            toast(err.message, {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
          });
      } catch (error) {
        toast("Error submitting the form:", error.message);
        console.error("Error submitting the form:", error);
      }
    },
  });
  function handleTabChangeSaveAs(index) {
    if (index == 2) {
      API.get('node-category/get', {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }).then(response => {
        const transformedOptions = response.data.catigores.map((item) => ({
          value: item.id, // Adjust this based on your data structure
          label: item.name, // Adjust this based on your data structure
        }));
        setHttpCategory([]);
        setHttpCategory([{ value: "create_new", label: "Create New Credential" }]);
        setHttpCategory((prevOptions) => [...prevOptions, ...transformedOptions]);
      }).catch(err => {
        console.error("Error fetching workflows:", err);
      });
    }
    let formData = JSON.parse(props.data.controls.dataJson.value)
    setSelectedIndex(index);
    setSaveAsDefaultValue(formData)
    formikSaveNode.setFieldValue('url', formData.url)
    formikSaveNode.setFieldValue('method', formData.method)
  }
  const handlePopupSubmit = (values) => {

    API.get('node-category/get', {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then(response => {

      const transformedOptions = response.data.catigores.map((item) => ({
        value: item.id, // Adjust this based on your data structure
        label: item.name, // Adjust this based on your data structure
      }));
      setHttpCategory([]);
      setHttpCategory([{ value: "create_new", label: "Create New Credential" }]);
      setHttpCategory((prevOptions) => [...prevOptions, ...transformedOptions]);
    }).catch(err => {
      console.error("Error fetching workflows:", err);
    });
    // Handle form submission logic here
  };
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile ? selectedFile.name : null);
      const base64String = await fileToBase64(selectedFile);
      setFile(URL.createObjectURL(selectedFile));
      formikSaveNode.setFieldValue('nodeIcon', base64String)
      console.log(base64String)
      console.log(formikSaveNode.values)
      // Reset input value so that choosing the same file triggers the event again
      fileInputRef.current.value = null;
    }
  };

  const handleFileRemove = () => {
    setFileName("");
    formikSaveNode.setFieldValue('nodeIcon', '')
    setFile(null);
    // Reset the input field value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>

        <Popup
          open={showCurlPopup}
          className="global-popup"
          onClose={closeCurlPopup}
        >
          <div className="global-popup-main w-[600px] bg-[#fff] rounded-[6px] overflow-hidden relative">
            <div className="global-popup-inn">
              <div className="global-popup-head bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
                <div className="global-popup-head-title text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">
                  Import cURL command
                </div>
                <button
                  className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                  onClick={closeCurlPopup}
                >
                  <img
                    src="../../images/close-popup.svg"
                    className="popup-close"
                  />
                </button>
              </div>
              <div className="global-popup-body">
                <div className="global-popup-body-field">
                  <div className="px-[29px] pt-[32px] pb-[32px]">
                    <div className="midform-field midform-field-select relative mb-[23px]">
                      <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                        cURL Command
                      </label>

                      <textarea
                        className="w-full p-[16px] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#020305] font-[inter-regular] text-[14px] not-italic font-normal leading-[19.6px]"
                        placeholder="Paste the cURL command here"
                      />
                    </div>

                    <button className="test-credential flex items-center justify-center hidden">
                      <img
                        src="../../images/cred_key.svg"
                        alt="cred_key"
                        className="mr-[4px]"
                      />
                      <span className="text-[#146DE0] font-[inter-medium] text-[14px] font-normal leading-[19.6px]">
                        Test credential
                      </span>
                    </button>

                    <div className="form_comm_actions flex items-center justify-center">
                      <button
                        className={`w-[150px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]`}
                      >
                        Cancel
                      </button>
                      <button
                        className={`primary_btn_hover w-[150px] py-2 px-4 rounded text-white [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]`}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            <ImportCurlCode
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onImportCurl={handleCurlImport}
            />
            <div className="node-popup webhook-node-popup fixed w-[calc(100%_-_80px)] h-[calc(100%_-_125px)] left-[0] right-[0] top-[60px] m-auto bg-[#FFF] z-[11] rounded-[8px]">
              <div className="node-popup-inn">
                <div className="node-popup-actions back node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[56px] bg-[#2C2D38] flex items-center justify-between rounded-tl-[8px] rounded-tr-[8px]">
                  <div className="node-popup-actions-left">
                    <button
                      onClick={closeModal}
                      className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                    >
                      <span className="primary_btn_hover flex h-[56px] items-center justify-center w-[56px] rounded-tl-[8px] bg-[#146DE0]">
                        <img
                          src="../../images/node_inn_back-square.svg"
                          alt="node_inn_back-square"
                        />
                      </span>
                      <span className="text-[#fff] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[24px] ml-[16px]">
                        Back
                      </span>
                    </button>
                  </div>
                  <div className="node-popup-actions-right flex items-center justify-end pr-[16px]">
                    <div className="node-popup-autosave-label text-[#fff] font-['inter-semibold'] text-[12px] not-italic font-normal leading-[15px] opacity-50 mr-[16px]">
                      Autosaved
                    </div>
                    <button
                      onClick={formik.submitForm}
                      type="button"
                      className="primary_btn_hover flex items-center justify-center [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[32px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px] mr-[16px]"
                    >
                      <span className="mr-[8px]">Test Step</span>
                      
                        <img
                          src="../../images/rete-btn-loader.svg"
                          alt="rete-btn-loader"
                          className={`rete-btn-loader w-[16px] ${isMainFormSubmitted?"":"hidden"}`}
                          width={16}
                        />
                          <img
                          src="../../images/node_inn_flask.svg"
                          alt="node_inn_flask"
                          className={`${isMainFormSubmitted?"hidden":""}`}
                        /> 
                    </button>
                    <button type='button' onClick={handelDeleteNode}>
                      <img
                        src="../../images/node_inn_delete.svg"
                        alt="node_inn_delete"
                      />
                    </button>
                  </div>
                </div>
                <div
                  className="node-popup-views flex h-[calc(100vh_-_120px)]"
                  ref={containerRef}
                >
                  <div
                    className="node-popup-view node-popup-input-view w-[calc(50%_-_246px)] left"
                    style={{ width: leftWidth }}
                  >
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                              <div className="node-popup-output-title mr-[16px] text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">
                                Input
                              </div>

                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              {!inputData.length && (
                                <div className="node-table-view-wrap">
                                  <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                    <DynamicTable data={inputData} />
                                    <div className="node_inn_blank_input_state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                      <div className="node_inn_blank_input_state-title font-['inter-semibold'] text-[16px] leading-[22.4px] text-[#30313D] mb-[16px]">
                                        No Input data yet
                                      </div>
                                      <button className="h-[40px] rounded-[6px] bg-[#E8F0FC] font-['inter-medium'] text-[14px] leading-[19.6px] text-[#146DE0] px-[20px] mb-[16px]">
                                        Execute previous node
                                      </button>
                                      <div className="node_inn_blank_input_state-subtitle font-['inter-medium'] text-[14px] leading-[19.6px] text-[#74757D]">
                                        (From the earliest node that has no{" "}
                                        <br />
                                        output data yet)
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {inputData.length && (
                                <div className="node-table-view-wrap">
                                  <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                    <DynamicTable data={inputData} />
                                  </div>
                                </div>
                              )}
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_298px)]">
                              <JSONInput
                                id="webhook-json"
                                placeholder={inputData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={true}
                              />

                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                                {inputData && Array.isArray(inputData) &&
                                  inputData != null &&
                                  inputData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={true}
                                    />
                                  ))}
                                {inputData && Array.isArray(inputData) &&
                                  inputData != null &&
                                  inputData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={true}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view w-[492px] relative -top-[56px] has-fixed-express-actions middle">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head">
                          <div className="midform-head-wrap relative">
                            {isLableEdit && (
                              <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                <>
                                  <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-normal mb-[10px]">
                                    Rename node
                                  </div>
                                  <input
                                    type="text"
                                    onChange={handleChangeLable}
                                    value={label}
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />
                                  <div className="flex items-center justify-end mt-[10px]">
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                      onClick={handelLableEdit}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                      onClick={handelSaveLableEdit}
                                    >
                                      Rename
                                    </button>
                                  </div>
                                  <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                </>
                              </div>
                            )}
                            <div
                              className="midform-dots absolute -top-[24px] left-[0] right-[0] m-auto flex items-center justify-center z-[1] w-[66px] h-[24px]"
                              onMouseDown={handleMouseDown}
                            ></div>
                            <div className="midform-head-inn relative flex items-center justify-between h-[70px] px-[20px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src={URLS.CLOUD_FRONT + '' + props.data.customNode?.nodeIcon}
                                  alt="http_node_inn_icon"
                                />
                                <span
                                  /*  onClick={handelLableEdit} */
                                  className="ml-[8px] text-[#30313D] font-['inter-semibold'] text-[20px] not-italic font-normal leading-[28px]"
                                  title={props.data.label}
                                >
                                  {props.data.label}
                                </span>
                              </div>
                              {/*   <button
                                type="submit"
                                className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button>  */}
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[#E8F0FC] rounded-tl-[8px] rounded-tr-[8px]"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="midform-body rounded-bl-[8px] rounded-br-[8px] bg-[#fff]">
                        <Tabs selectedIndex={selectedIndex} onSelect={handleTabChangeSaveAs}>
                          <TabList className="flex items-center justify-end">
                            <div className="node-inn-main-acts-tabs flex items-center justify-start w-full bg-[#F2F7FD]">
                              <Tab className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                Parameters
                              </Tab>
                              {/* <Tab className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                Settings
                              </Tab>
                              <Tab   className="relative text-center w-[33.33%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                Save as...
                              </Tab> */}
                            </div>
                          </TabList>
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full">
                                <div className="midform-fields">
                                  <div className="midform-field midform-field-has-dots-drop midform-field-select midform-select-subtitle relative mb-[15px] pt-[6px]">
                                    <div className="midform-field-dots-drop-wrap relative">
                                      <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        Method
                                      </label>
                                      <input
                                        type="text"
                                        className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[rgba(209,212,217,0.5)] bg-[rgba(234,234,236,0.5)] text-[rgba(48,49,61,0.5)] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                        name="methord"
                                        defaultValue={
                                          formik.values.methord
                                        }
                                        value={formik.values.methord}
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="midform-field midform-field-has-dots-drop mb-[23px] pt-[6px]">
                                    <div className="midform-field-dots-drop-wrap relative">
                                      <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        URL
                                      </label>
                                      <input
                                        type="text"
                                        className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[rgba(209,212,217,0.5)] bg-[rgba(234,234,236,0.5)] text-[rgba(48,49,61,0.5)] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                        name="url"
                                        defaultValue={
                                          formik.values.url
                                        }
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                                    <div className="midform-input-fields-switch node-comm-switch relative pt-[15px] flex items-center justify-start">
                                      <div className="text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px] mr-[8px]">
                                        Send Query Parameters
                                      </div>
                                    </div>
                                    {formik.values.sendQueryParameter && (
                                      <>

                                        <DynamicKeyValueFields fieldName="queryparameter" formikInstance={formik} data={props.data.customNode.queryParameters} userMode={true} />

                                      </>
                                    )}
                                  </div>
                                  <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                                    <div className="midform-input-fields-switch node-comm-switch relative pt-[15px] flex items-center justify-start">
                                      <div className="text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px] mr-[8px]">
                                        Send Headers
                                      </div>
                                    </div>
                                    {formik.values.sendHeaders && (
                                      <>
                                        <DynamicKeyValueFields fieldName="specifyHeaders" formikInstance={formik} data={props.data.customNode.headers} userMode={true} />

                                      </>
                                    )}

                                  </div>
                                  <div className="midform-field border-t [border-top-style:dashed] [border-top-color:#DCE4E8]">
                                    <div className="midform-input-fields-switch node-comm-switch relative pt-[15px] flex items-center justify-start">
                                      <div className="text-[#1A1C1E] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px] mr-[8px]">
                                        Send Body
                                      </div>
                                    </div>
                                    {formik.values.sendBody && (
                                      <>

                                        <DynamicKeyValueFields fieldName="body" formikInstance={formik} data={props.data.customNode.body} userMode={true} />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full min-h-[calc(100vh_-_233px)]">
                                <div className="midform-fields">
                                  <div className="midform-field mb-[30px] relative">
                                    <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                      Node description
                                    </label>
                                    <input
                                      type="number"
                                      className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px] h-[calc(100vh_-_231px)]">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full">
                                <div className="save-as-form p-[22px]">
                                  <form onSubmit={formikSaveNode.handleSubmit}>
                                    <div className="save-as-form-field mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        Name of node
                                      </label>
                                      <input
                                        name="name"
                                        value={formikSaveNode.values.name}
                                        onChange={formikSaveNode.handleChange}
                                        onBlur={formikSaveNode.handleBlur}
                                        className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                      />
                                      {formikSaveNode.touched.name && formikSaveNode.errors.name && (
                                        <div className="text-[#DF1A41] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px]">{formikSaveNode.errors.name}</div>
                                      )}
                                    </div>
                                    <div className="midform-field midform-field-select relative mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        Select Category
                                      </label>
                                      <Select
                                        className="midform-select-main"
                                        classNamePrefix="midform-select"
                                        placeholder="Select Category"
                                        isSearchable={false}
                                        options={httpCategory}
                                        name="category"
                                        onChange={(e) => {
                                          formikSaveNode.setFieldValue(
                                            "category",
                                            e.value
                                          );
                                          if (e.value === "create_new") {
                                            setIsPopupOpen(true);
                                          }
                                        }}
                                      />
                                      {formikSaveNode.touched.category && formikSaveNode.errors.category && (
                                        <div className="text-[#DF1A41] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px]">{formikSaveNode.errors.category}</div>
                                      )}
                                    </div>
                                    <div className="midform-field relative mb-[15px]">
                                      <div className="mb-[4px] flex items-center justify-between">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px]">
                                          Description
                                        </label>
                                        <span className="save-as-form-textarea-count text-[#6C7278] font-[inter-medium] text-[12px] not-italic font-normal leading-[16.8px]">
                                          {countWords(
                                            formikSaveNode.values.description
                                          )}
                                          /150
                                        </span>
                                      </div>
                                      <textarea
                                        name="description"
                                        maxLength={150}
                                        className="w-full p-[16px] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#020305] font-[inter-regular] text-[14px] not-italic font-normal leading-[19.6px]"
                                        value={
                                          formikSaveNode.values.description
                                        }
                                        onChange={(e) => {
                                          formikSaveNode.setFieldValue(
                                            "description",
                                            e.target.value
                                          );
                                          formikSaveNode.handleChange(e)
                                        }}
                                        onBlur={formikSaveNode.handleBlur}
                                      />
                                      {formikSaveNode.touched.description && formikSaveNode.errors.description && (
                                        <div className="text-[#DF1A41] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px]">{formikSaveNode.errors.description}</div>
                                      )}
                                    </div>
                                    <div className="midform-custom_file_input midform-field midform-field-select relative mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                        Node Icon
                                      </label>
                                      <div className="midform-custom_file_input-inn relative flex items-center justify-start border border-solid border-[#D1D4D9] rounded-[6px] h-[40px] px-[15px]">
                                        <label
                                          htmlFor="fileInput"
                                          className="cursor-pointer"
                                        >
                                          <input
                                            id="fileInput"
                                            type="file"
                                            accept="image/*"
                                            name="nodeIcon"
                                            onBlur={formikSaveNode.handleBlur}
                                            onChange={handleFileChange}
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                          />
                                          {file ? (
                                            <img
                                              src={file}
                                              alt="File Icon"
                                              style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: 8,
                                              }}
                                            />
                                          ) : (
                                            <span className="absolute top-[0] left-[0] w-full h-full z-[1]"></span>
                                          )}
                                        </label>
                                        <div
                                          className="whitespace-nowrap w-[calc(100%_-_48px)] overflow-hidden overflow-ellipsis text-[#1A1C1E] font-[inter-medium] text-[14px] font-normal leading-[19.6px]"
                                          title={fileName}
                                        >
                                          {fileName || "Choose Icon"}
                                        </div>
                                        {!fileName && (
                                          <button
                                            style={{
                                              marginLeft: "auto",
                                              border: "none",
                                              background: "transparent",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          >
                                            <img
                                              src="../../images/upload-file-icon.svg"
                                              alt="upload-file-icon"
                                            />
                                          </button>
                                        )}
                                        {fileName && (
                                          <button
                                            onClick={handleFileRemove}
                                            style={{
                                              marginLeft: "auto",
                                              border: "none",
                                              background: "transparent",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          >
                                            <img
                                              src="../../images/delete_for_icon.svg"
                                              alt="delete_for_icon"
                                            />
                                          </button>
                                        )}
                                      </div>
                                      <div className="flex items-center justify-start mt-[4px]">
                                        <img
                                          src="../../images/upload-info-icon.svg"
                                          alt="upload-info-icon"
                                        />
                                        <span className="ml-[4px] text-[#74757D] font-[inter-regular] text-[12px] leading-[16.8px]">
                                          Your icons should be 24x24 pixels.
                                        </span>
                                        {/*  {formikSaveNode.touched.nodeIcon && formikSaveNode.errors.nodeIcon && (
                                            <div className="text-[#DF1A41] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px]">{formikSaveNode.errors.nodeIcon}</div>
                                          )} */}
                                      </div>
                                      <div className="midform-field midform-field-has-dots-drop midform-field-select midform-select-subtitle relative mb-[23px] pt-[6px]">
                                        <div className="midform-field-dots-drop-wrap relative">
                                          <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                            Method
                                          </label>
                                          <input
                                            type="text"
                                            className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[rgba(209,212,217,0.5)] bg-[rgba(234,234,236,0.5)] text-[rgba(48,49,61,0.5)] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                            name="method"
                                            defaultValue={
                                              saveAsDefaultValue['methord']
                                            }
                                            value={saveAsDefaultValue['methord']}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="midform-field midform-field-has-dots-drop mb-[23px] pt-[6px]">
                                        <div className="midform-field-dots-drop-wrap relative">
                                          <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                            URL
                                          </label>
                                          <input
                                            type="text"
                                            className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[rgba(209,212,217,0.5)] bg-[rgba(234,234,236,0.5)] text-[rgba(48,49,61,0.5)] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                            name="url"
                                            defaultValue={
                                              saveAsDefaultValue['url']
                                            }
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>


                                      {/*
                                        Query Parameter
                                      */}

                                      <div className="save_as_check_radio flex items-center justify-between mt-[15px]">
                                        <div className="save_as_check_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                                          Query Parameter
                                        </div>
                                        <DynamicKeyValueFields fieldName="queryParameters" formikInstance={formikSaveNode} data={saveAsDefaultValue['queryparameter']} />

                                      </div>
                                      {/*
                                        Header query
                                      */}
                                      <div className="save_as_check_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                                        Header Parameter
                                      </div>
                                      <DynamicKeyValueFields fieldName="headers" formikInstance={formikSaveNode} data={saveAsDefaultValue['specifyHeaders']} />
                                      {/*
                                        body query
                                      */}
                                      <div className="save_as_check_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                                        Body Parameter
                                      </div>
                                      <DynamicKeyValueFields fieldName="body" formikInstance={formikSaveNode} data={saveAsDefaultValue['body']} />


                                      <div className="save_as_check_radio flex items-center justify-between mt-[15px]">
                                        <div className="save_as_check_action flex items-center justify-center">
                                          <div className="save_as_check_action-input flex items-center justify-center relative">
                                            <input
                                              type="checkbox"
                                              name="mode"
                                              //checked={formik.values.mode!='public'?true:false}
                                              onChange={(e) => {
                                                console.log(e.target.value)
                                                formikSaveNode.setFieldValue('mode', e.target.value)
                                              }}
                                              className="absolute w-full h-full top-[0] left-[0] z-[1] cursor-pointer opacity-0"
                                            />
                                            <div className="save_as_check_action-input-inn flex items-center justify-center w-[16px] h-[16px] [transition:0.3s_all_ease] border-[1px] border-[solid] border-[#A0A0A6] rounded-[4px]">
                                              <svg
                                                width="10"
                                                height="8"
                                                viewBox="0 0 10 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8.85063 0.214824C8.91267 0.149281 8.98672 0.0972131 9.06844 0.0616672C9.15016 0.0261214 9.23791 0.0078125 9.32655 0.0078125C9.41519 0.0078125 9.50293 0.0261214 9.58465 0.0616672C9.66637 0.0972131 9.74042 0.149281 9.80247 0.214824C10.0625 0.486843 10.0661 0.926403 9.81156 1.20313L4.43599 7.78241C4.37496 7.8518 4.3009 7.90757 4.21833 7.94631C4.13577 7.98504 4.04644 8.00593 3.95581 8.00769C3.86518 8.00945 3.77515 7.99205 3.69124 7.95655C3.60733 7.92106 3.5313 7.86821 3.46779 7.80124L0.196815 4.36947C0.0706642 4.23627 0 4.05716 0 3.87061C0 3.68406 0.0706642 3.50495 0.196815 3.37175C0.258858 3.30621 0.332907 3.25414 0.414627 3.21859C0.496347 3.18305 0.584095 3.16474 0.672734 3.16474C0.761373 3.16474 0.849121 3.18305 0.930841 3.21859C1.01256 3.25414 1.08661 3.30621 1.14865 3.37175L3.92325 6.28301L8.83245 0.235531C8.8381 0.228257 8.84417 0.221342 8.85063 0.214824Z"
                                                  fill="white"
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                          <div className="save_as_check_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                                            Make this node as your private node
                                          </div>
                                        </div>
                                      </div>
                                      <button type="submit" className="primary_btn_hover mt-[15px] bg-[#146DE0] h-[36px] w-full text-[#FFF] font-[inter-medium] text-[14px] leading-[19.6px] rounded-[6px]">
                                        Save as new node
                                      </button>
                                    </div>
                                  </form>
                                  {isPopupOpen && (
                                    <AddCategoryPopup
                                      onClose={() => setIsPopupOpen(false)}
                                      onSubmit={handlePopupSubmit}
                                    />
                                  )}
                                </div>
                                <div className="hidden save-as-node-error-state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                  <img
                                    src="../../images/node_error_icon.svg"
                                    alt="node_error_icon"
                                    className="mx-auto"
                                  />
                                  <div className="save-as-node-error-state-title text-[#1A1C1E] font-[inter-semibold] text-[20px] leading-[28px] mt-[12px]">
                                    Oops!
                                  </div>
                                  <div className="save-as-node-error-state-subtitle">
                                    <p className="text-[#74757D] font-[inter-medium] text-[14px] leading-[19.6px] mt-[10px]">
                                      The configuration for this HTTP node does
                                      not <br />
                                      seem to be correct.
                                    </p>
                                    <p className="text-[#74757D] font-[inter-medium] text-[14px] leading-[19.6px] mt-[10px]">
                                      It is recommended that you have positively
                                      tested <br />
                                      the node before saving it as a template.
                                    </p>
                                  </div>
                                  <div className="save-as-node-error-state-actions">
                                    <button className="primary_btn_hover rounded-[6px] block w-full h-[40px] max-w-[268px] bg-[#146DE0] text-[#ffffff] font-[inter-medium] text-[14px] leading-[19.6px] mx-[auto] mt-[35px]">
                                      Continue to configure
                                    </button>
                                    <button className="rounded-[6px] block w-full h-[40px] max-w-[268px] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] leading-[19.6px] mx-[auto] mt-[12px]">
                                      Ignore, Save anyway!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div
                    className="node-popup-view node-popup-output-view w-[calc(50%_-_246px)] right"
                    style={{ width: rightWidth }}
                  >
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-title text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center w-[75px] h-[32px] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] bg-[#E8F0FC] mr-[8px] rounded-[6px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center w-[75px] h-[32px] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] bg-[#146DE0] rounded-[6px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}

                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_all_ease] flex items-center justify-center ml-[8px] rounded-[8px] bg-[#fff] w-[36px] h-[36px] border-[1px] border-[solid] border-[#D1D4D9] hover:border-[#74757D]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node_inn_edit.svg"
                                    alt="node_inn_edit"
                                  />
                                </button>
                              )}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                  {outPutData != null ||
                                    outPutData != undefined ? (
                                    <DynamicTable data={outPutData} />
                                  ) : (
                                    ""
                                  )}
                                  {outPutData == null ||
                                    (outPutData == undefined && (
                                      <div className="node_inn_blank_output_state text-center absolute top-2/4 left-[0] right-[0] m-auto -translate-y-1/2">
                                        <div className="node_inn_blank_output_state-title font-['inter-medium'] text-[16px] leading-[22.4px] text-[#30313D]">
                                          Execute this node to view <br />
                                          data or{" "}
                                          <button className="font-['inter-semibold'] text-[#146DE0] underline">
                                            Set mock data
                                          </button>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${editOutputJson ? "" : "node-json-view-readonly"
                                } webhook-node-json-view h-[calc(100vh_-_298px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                                {outPutData != null || outPutData != undefined
                                  ? outPutData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={false}
                                    />
                                  ))
                                  : ""}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <NodeStyles
          selected={selected}
          width={280}
          height={61}
          className="nodeWrap node-comm node-http"
          data-testid="node"
        >
          <div
            className="node-block-parent"
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              setShowpopUp((pop) => !pop);
              toggleVisibility(true);
              e.stopPropagation();
            }}
          >

            <div className="node-block flex items-center justify-center">
              <div className="node-block-actions absolute top-[100%] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pt-[17px] [cursor:initial]">
              <div className="node-block-action relative">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_run.svg"
                    alt="node_main_action_run"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Run</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_copy.svg"
                    alt="node_main_action_copy"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Copy</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_delete.svg"
                    alt="node_main_action_delete"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Delete</span>
                </div>
                <div className="node-block-action relative">
                <button className="cursor-pointer bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center">
                  <img
                    src="../../images/node_main_action_dots.svg"
                    alt="node_main_action_dots"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">More</span>
                </div>
              </div>
              <div className="node-block-icon-parent w-[36px] h-[36px] flex items-center justify-start mr-[8px]">
                <img
                  className="node-block-icon"
                  src={URLS.CLOUD_FRONT + '' + props.data.customNode?.nodeIcon}
                  alt="http_node_main_icon"
                />
              </div>
              <div className="node-block_text">
                <span
                  className="node-block-title block text-[#30313D] font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]"
                  title={props.data.label}
                >
                  {/* HTTP
                  <br />
                  Request */}
                  {props.data.label}
                </span>
                <span title={props.data.customNode.description} className="node-block-desc block text-[#74757D] font-[inter-medium] text-[12px] not-italic font-normal leading-[14.52px] mt-[3px] w-[200px] block whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {props.data.customNode.description}
                </span>
              </div>
            </div>
          </div>
          <div
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            className="title hidden"
            data-testid="title"
          >
            {label}
          </div>
          {outputs.map(
            ([key, output]) =>
              output && (
                <div className="output" key={key} data-testid={`output-${key}`}>
                  <div className="output-title" data-testid="output-title">
                    {output?.label}
                  </div>
                  <RefSocket
                    name="output-socket"
                    side="output"
                    emit={props.emit}
                    socketKey={key}
                    nodeId={id}
                    payload={output.socket}
                  />
                </div>
              )
          )}

          {inputs.map(
            ([key, input]) =>
              input && (
                <div className="input" key={key} data-testid={`input-${key}`}>
                  <RefSocket
                    name="input-socket"
                    emit={props.emit}
                    side="input"
                    socketKey={key}
                    nodeId={id}
                    payload={input.socket}
                  />
                  {input && (!input.control || !input.showControl) && (
                    <div className="input-title" data-testid="input-title">
                      {input?.label}
                    </div>
                  )}
                  {input?.control && input?.showControl && (
                    <span className="input-control">
                      <RefControl
                        key={key}
                        name="input-control"
                        emit={props.emit}
                        payload={input.control}
                      />
                    </span>
                  )}
                </div>
              )
          )}
        </NodeStyles>
      </DndProvider>
    </>
  );
}
