import React, { useEffect, useState } from "react";
import { format, formatDistanceToNow } from 'date-fns';
import DotsDropdown from "../../components/DotsDropdown";
import PopupCred from "../../components/cred-popup";
import DynamicFormPopup from "../../components/DynamicFormPopup";
import { formConfigurations } from "../../config/formField";
import createAPI from "../../api";
import { toast } from "react-toastify";
import CommonHeader from "../../components/view/common-header";
import CommonLayout from "../../components/view/common-layout";
import { Link } from "react-router-dom";
import ShareCredentials from "../../components/view/ShareCredentials";
import DynamicFormPopupWithSelect from "../../components/DynamicFormPopupWithSelect";
import { countWords, formatWorkflowInfo } from "../../utils";
import Select from 'react-select'; 
import CustomSelectIconOption from "../../components/CustomSelectIconOption";
import AppDropdown from "../../components/form/AppDropdown";
const Credentials = () => {
  const API = createAPI();


  const [isShareCredentialsPopupOpen, setShareCredentialsPopupOpen] = useState(false);
  const [isFirstPopupOpen, setFirstPopupOpen] = useState(false);
  const [isSecondPopupOpen, setSecondPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [buttonText, setButtonText] = useState("Submit");
  const [formConfig, setFormConfig] = useState(null);
  const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState(false); 
  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dynamicConfig, setDynamicConfig] = useState(formConfigurations.default || { fields: [] });
  const [sortDefaultVal, setSortDefaultVal] = useState("lastUpdated");
  const [filterCredType, setFilterCredType] = useState("");
  const [showCredFilter, setShowCredFilter] = useState(false);
  const [selectedFilterDropdown, setSelectedFilterDropdown] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const items = [
    { value: "mysql", label: "MySQL", image: "../../images/mysql-logo.png" },
    { value: "whatsAppBusiness", label: "WhatsApp Business", image: "../../images/whatsapp-logo.png" },
    { value: "telegram", label: "Telegram", image: "../../images/telegram-logo.png" },
    { value: "slack", label: "Slack", image: "../../images/slack-logo.png" },
    { value: "msTeams", label: "MS Teams", image: "../../images/ms-teams-logo.png" },
    { value: "intercom", label: "Intercom", image: "../../images/intercom-logo.png" },
    { value: "freshworkscrmaccount", label: "Freshworks CRM account", image: "../../images/freshworkscrm-logo.png" },
    { value: "freshdesk", label: "Freshdesk", image: "../../images/freshdesk-logo.png" },
    { value: "wordpress", label: "Wordpress", image: "../../images/wordpress-logo.png" },
    { value: "googleDoc", label: "Google Doc", image: "../../images/google-docs-logo.png" },
    { value: "googleSheets", label: "Google Sheets", image: "../../images/google-sheet-logo.png" },
    { value: "notion", label: "Notion", image: "../../images/notion-logo.png" },
    { value: "youTube", label: "YouTube", image: "../../images/youtube-logo.png" },
    // Add more items as needed
  ];
  const ShareCredentialsItems = [
    { value: "sharecredentials1", label: "Mahmadjakir Chavda", image: "../../images/sidebar-avatar.jpg", subtitle: "Mahadjakir.c@indianic.com" },
    { value: "sharecredentials2", label: "Mahmad al-saeed", image: "../../images/second-avatar.jpg", subtitle: "saeedalhassan@nicgulf.com" },
  ];
  function getCredIcon(iconType){
    return items.find(val => val.value==iconType).image
  }
  const handleOpenCredential = (credential) => {
    let selectedConfig = formConfigurations[credential.credType];
    setFormConfig(selectedConfig);
    let formFields = selectedConfig.fields
    formFields = formFields.map(val => {
      val['defaultValue'] = credential.credFields[val.name]
      return val
    })

    setSecondPopupOpen(true);
  };
  const handleContinue = (selectedOption) => {
    const selectedConfig = formConfigurations[selectedOption.value];
    console.log(selectedOption.value);
    setFormConfig(selectedConfig);
    setSelectedOption(selectedOption.value);
    setFirstPopupOpen(false);
    setSecondPopupOpen(true);
    setIsPopupOpen(true)
  };
  const handleFormSubmit = async (values) => {
    // API call to submit the form data
    setButtonText("Submiting....");
    await API.post(
      "mysql/create-credential",
      {
        credFields: values.values,
        name: values.workFlowName,
        credType: selectedDropdown.value,
      },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    );
    await getCerd()
    setButtonText("Submit");
    setFirstPopupOpen(false);
    setSelectedDropdown(null)
    setDynamicConfig({ fields: [] })
  };
  async function getCerd(data={}) {
     console.log(data)
    let body={
      search:data.search||"",
      credType:data.credType||"",
      currentPage:data.currentPage||1,
      limit:data.limt||10,
      sort:data.sort || 'lastUpdated'
    }
    API.post("mysql/filter-credentials",body, {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((res) => {
      console.log(res.data);
      setCredentials(res.data.credList.credentialData)
      if (res.data.credList.credentialData && res.data.credList.credentialData.length > 0) {
        setCredentials(prev => [...prev, ...res.data.credList.credentialData]);
        setPage(prev => prev + 1);
        setHasMore(res.data.credList.credentialData.length >= 10);
    } else {
        setHasMore(false);
    }
    });
  }
  useEffect(() => {
    API.post("mysql/filter-credentials",{
      search: "",
      credType: "",
      currentPage: 1,
      limit: 10,
      sort:sortDefaultVal
    }, {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    },).then((res) => { 
      setCredentials(res.data.credList.credentialData)
    });
  }, []);
  const togglePopup = () => {
    // API call to submit the form data
    setFirstPopupOpen((prev) => !prev);
  };
  const toggleShareCredentialsPopup = () => {
    // API call to submit the form data
    setShareCredentialsPopupOpen((prev) => !prev);
  };


  const confirmDelete = async () => {
    try {
      // Call the delete API here with the selectedItem
      await API.delete(`mysql/delete/`, {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      });
      toast.success('Item deleted successfully!');
      await getCerd()
      // Optionally remove the item from the list or refresh the data
    } catch (error) {
      toast.error('Failed to delete the item.');
    } finally {
      setConfirmationPopupOpen(false); // Close the confirmation popup
    }
  };

  const cancelDelete = () => {
    setConfirmationPopupOpen(false); // Close the confirmation popup
  };
  const dotoptions = [
    {
      imageSrc: '../../images/drop_share.svg',
      label: 'Share',
      onClick: () => {
        console.log('Share clicked')
        toggleShareCredentialsPopup();
      },
    },
    {
      imageSrc: '../../images/drop_delete.svg',
      label: 'Delete',
      onClick: () => { console.log('Delete clicked') },
    }
  ];
  const iconSrc = '../../images/common-module-dots.svg';

  const handleDropdownChange = (selectedOption) => {
    setSelectedDropdown(selectedOption);
    setDynamicConfig(formConfigurations[selectedOption.value]   || { fields: [] });
  };
  const handelSearch =(e)=>{
    console.log(e.target.value)
    if(countWords(e.target.value) > 2){
      getCerd({search:e.target.value})
    }else if(e.target.value == ''){
      getCerd()
    }
  }
  let sortoption=[
    {value:"lastUpdated",label:"Sort by last updated"},
    {value:"lastCreated",label:"Sort by last created"},
    {value:"nameAZ",label:"Sort by name (A-Z)"},
    {value:"nameZA",label:"Sort by name (Z-A)"}, 
  ];
  const handelSort=(e)=>{ 
    console.log(e.value)
    getCerd({sort:e.value})
    setSortDefaultVal(e.value)
    /* console.log(sortDefaultVal) */
  }
  const handelCredType =(e)=>{
    getCerd({credType:e.value})
    setSelectedFilterDropdown(e.value)
  }
  const handelFilter= (e)=>{
    setShowCredFilter((prev) => !prev)
    setSelectedFilterDropdown('')

      getCerd({credType:''}) 
    
  }
  return (
    <CommonLayout> 
      {isShareCredentialsPopupOpen && (
        <ShareCredentials
          isOpen={isShareCredentialsPopupOpen}
          onClose={() => setShareCredentialsPopupOpen(false)}
          items={ShareCredentialsItems}
          onContinue={handleContinue}
        />
      )}
      
        <DynamicFormPopupWithSelect
          isOpen={isFirstPopupOpen}
          onClose={() => setFirstPopupOpen(false)}
          config={dynamicConfig}
          onSubmit={handleFormSubmit}
          dropdownItems={items}
          selectedDropdown={selectedDropdown}
          onDropdownChange={handleDropdownChange}
          buttonText={buttonText}
        />
       

      <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
        <CommonHeader />
        <div className="common-module-list px-[40px]">
          <div className="common-module-list-head flex items-center justify-between py-[25px]">
            <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
              My Credentials
            </div>
            <button
              onClick={togglePopup}
              className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[36px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[16px] rounded-[6px]"
            >
              <span>Add Credential</span>
              <img
                src="../../images/add-node-plus.svg"
                alt="add-node-plus"
                className="ml-[8px] w-[16px]"
              />
            </button>
          </div>
          <div className="common-module-list-head-body bg-[#fff]">
            <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={16} />
                  <input type="text" onKeyUp={handelSearch} placeholder="Search credentials..." className="text-[#30313D] h-[36px] text-[14px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                  <div className="common_list_filter relative">
                  <div className="common-module-list-action-button common-module-list-action-button-filter cursor-pointer w-[89px] h-[36px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]" onClick={handelFilter}>
                    <span className="font-[inter-medium] text-[14px] text-[#30313D] mr-[8px]" >Filter</span>
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={18} />
                  </div>
                  {showCredFilter&&(<div className="cred-type absolute w-[300px] left-2/4 -translate-x-1/2 top-[50px] p-[10px] bg-[#fff] rounded-[6px] bg-[#FFF] [box-shadow:0px_0px_24px_0px_rgba(0,_0,_0,_0.05)] border border-solid border-[#DCE4E8]">
                    <AppDropdown items={items} selectedOption={selectedFilterDropdown} onChange={handelCredType} placeholder="" />
                  </div>)}
                  </div>
                  <div className="common-module-list-action-button common_list_sortby common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[117px] h-[36px] flex items-center justify-center rounded-[8px] border [border-style:solid] [border-color:#DCE4E8]"> 
                    <Select 
                      className="midform-select-main"
                      classNamePrefix="midform-select"
                      value={sortDefaultVal}
                      onChange={handelSort}
                      options={sortoption}
                      placeholder="Sort By" 
                      isSearchable={false}
                    />
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-group ml-[8px] cursor-pointer h-[36px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px] px-[3px]">
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#fff] active">By me</div>
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-medium] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#1A1C1E]">Team</div>
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-medium] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#1A1C1E]">Shared</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-module-list-rows p-[18px] pb-[4px] max-h-[calc(100vh_-_235px)] overflow-auto">
              <div className="common-module-list-rows-wrap ">

                {Array.isArray(credentials) && credentials.map((val,key)=>(<div key={key} className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] hover:[border-color:#146DE0] [transition:0.3s_all_ease] rounded-[12px] px-[16px] h-[68px] flex items-center justify-between">
                  <div className="common-module-list-row-left flex iterms-center justify-start">
                    <div className="common-module-list-row-icon">
                      <img src={getCredIcon(val.credType)} alt="gmail-icon" width={36} />
                    </div>
                    <div className="common-module-list-row-left-wrap ml-[15px]">
                      <div className="common-module-list-row-title font-[inter-semibold] text-[14px] leading-[19.6px] text-[#30313D] mb-[4px]">{val.name}</div>
                      <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">{formatWorkflowInfo(val)} </div>
                    </div>
                  </div>
                  <div className="common-module-list-row-right flex items-center justify-end">
                   {/*  <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                        <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                      </div>
                      <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                        <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-semibold] text-[12px]">+2</div>
                      </div>
                    </div> */}
                    <div className="common-module-list-row-created-by bg-[#EAEAEC] rounded-[4px] font-[inter-medium] text-[12px] leading-[18px] text-[#30313D] px-[7px] mr-[16px]">
                      Owned by me
                    </div>
                    <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer">
                      <DotsDropdown options={dotoptions} iconSrc={iconSrc} />
                    </div>
                  </div>
                </div>))}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {isConfirmationPopupOpen && (
        <div className="confirmation-popup  z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
          <div className="w-[350px] bg-[#fff] rounded-[6px] relative">
            <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
              <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Confirm Action</div>
              <button
                className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                onClick={cancelDelete}
              >
                <img src="../../images/close-popup.svg" alt="popup-close" />
              </button>

            </div>
            <div className="p-[30px]">
              <p className="text-[#000] font-['inter-regular'] text-[14px] not-italic font-normal leading-[normal] mb-[20px]">Are you sure you want to delete this item?</p>
              <div className="flex align-start justify-start">
                <button className="primary_btn_hover min-w-[80px] [transition:0.3s_opacity_ease] cursor-pointer opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-semibold] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={confirmDelete}>Yes</button>
                <button className="ml-[10px] min-w-[80px] [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#146DE0] border-[#146DE0] text-[#146DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]" onClick={cancelDelete}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </CommonLayout>
  );
};
export default Credentials;
