import React, { useState } from "react";
import DraggableField from "./DraggableField";

const RecursiveComponent = ({ item, onFieldDrop, draggable, parentKey = "" }) => {
  const [isOpen, setIsOpen] = useState({});

  const toggleOpen = (key) => {
    setIsOpen((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderContent = (key, value, fullKey) => {
    if (value && typeof value === "object" && !(value instanceof Date)) {
      return (
        <div key={fullKey} className="mb-[6px] ml-[20px]">
          <div className="-ml-[20px] inline-block px-[10px] py-[8.5px] bg-[#e9f0fc] rounded-[4px] text-[rgba(0,0,0,0.7)] font-['inter-regular'] text-[11px] not-italic font-normal leading-[normal] mr-[13px] mb-[6px]">
            <div
              onClick={() => toggleOpen(fullKey)}
              style={{ cursor: "pointer", fontWeight: "bold" }}
            >
              {key} {isOpen[fullKey] ? "-" : "+"}
            </div>
          </div>
          {isOpen[fullKey] && (
            <div className="ml-[20px]">
              {Array.isArray(value) ? (
                value.map((item, index) => (
                  item && typeof item === "object" && !(item instanceof Date) ? ( // Ensure valid objects in arrays
                    <div key={index}>
                      {renderContent(`${key}[${index}]`, item, `${fullKey}[${index}]`)}
                    </div>
                  ) : (
                    <DraggableField
                      key={`${fullKey}[${index}]`}
                      fieldKey={`${fullKey}[${index}]`}
                      fieldValue={item}
                      draggable={draggable}
                    />
                  )
                ))
              ) : (
                <RecursiveComponent item={value} onFieldDrop={onFieldDrop} draggable={draggable} parentKey={fullKey} />
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <DraggableField key={fullKey} fieldKey={fullKey} fieldValue={value} draggable={draggable} />
      );
    }
  };

  // Validate and filter out invalid items before rendering
  const isValidItem = (obj) => obj && typeof obj === "object" && !Array.isArray(obj);
  const validItem = isValidItem(item) ? item : {}; // Default to empty object if invalid

  return (
    <div>
      {Object.entries(validItem).map(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        return renderContent(key, value, fullKey);
      })}
    </div>
  );
};

export default RecursiveComponent;
