import React, { useState, useRef } from 'react';
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Schemes } from "../rete/types";
import ScheduleTrigger from "../customization/ScheduleTrigger";
import Popup from "reactjs-popup";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import DynamicTable from "../components/Dynamictable";
import RecursiveComponent from "../components/RecursiveComponent";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import moment from "moment";
import "moment-timezone";
import * as Yup from "yup";
import { deleteNodes, runNodes } from "../editor";
import createAPI from "../api";
import { isValidJsonCustom, URLS } from "../utils";
import io from "socket.io-client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DotsDropdown from '../components/DotsDropdown';

const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: ScheduleTrigger & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid #BABFC6;  
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 280px;
  height: 61px;
  padding-left: 11px;
  position: relative;
  user-select: none;
  outline: 0px solid #146de0;  
  box-shadow: 0 0 0 0 #cad8f8;  
  &:hover {
    outline: 2px solid #146de0;
  }
  &:focus {
    outline: 2px solid #146de0; 
    box-shadow: 0 0 0 6px #cad8f8;       
  }  
  ${(props) =>
    props.selected &&
    css`
      outline: 2px solid #146de0; 
      box-shadow: 0 0 0 6px #cad8f8;  
    `}
  .title {
    
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -9px;
    height: 20px;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;    
    background-image: url(../../images/node_main_connector.svg);
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: right;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: white;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }

  ${(props) => props.styles && props.styles(props)}
`;

export function TriggerNode(props: Props<Schemes>) {
  const API = createAPI();
  const timearray = [
    { value: "0:00", label: "Midnight" },
    { value: "1:00", label: "1AM" },
    { value: "2:00", label: "2AM" },
    { value: "3:00", label: "3AM" },
    { value: "4:00", label: "4AM" },
    { value: "5:00", label: "5AM" },
    { value: "6:00", label: "6AM" },
    { value: "7:00", label: "7AM" },
    { value: "8:00", label: "8AM" },
    { value: "9:00", label: "9AM" },
    { value: "10:00", label: "10AM" },
    { value: "11:00", label: "11AM" },
    { value: "12:00", label: "Noon" },
    { value: "13:00", label: "1PM" },
    { value: "14:00", label: "2PM" },
    { value: "15:00", label: "3PM" },
    { value: "16:00", label: "4PM" },
    { value: "17:00", label: "5PM" },
    { value: "18:00", label: "6PM" },
    { value: "19:00", label: "7PM" },
    { value: "20:00", label: "8PM" },
    { value: "21:00", label: "9PM" },
    { value: "22:00", label: "10PM" },
    { value: "23:00", label: "11PM" },
  ];

  function formatDate() {
    const date = new Date(); // This can be dynamic
    const options = { timeZoneName: "short" };
    const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      weekday: "long",
      timeZoneName: "short",
    });

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: weekday },
      ,
      { value: hour },
      ,
      { value: minute },
      ,
      { value: second },
      ,
      { value: timeZoneName },
    ] = dateTimeFormat.formatToParts(date);

    return {
      timestamp: date.toISOString(),
      "Readable date": `${month} ${day}th ${year}`,
      "Readable time": `${hour}:${minute}:${second} ${timeZoneName}`,
      "Day of week": weekday,
      Year: year,
      Month: month,
      "Day of month": day,
      Hour: hour,
      Minute: minute,
      Second: second,
      Timezone: timeZoneName,
    };
  }

  const [schduleNodeOutput, setSchduleNodeOutput] = React.useState(
    formatDate()
  );
  const { id, width, height } = props.data;
  const [intalvalType, setIntalvalType] = React.useState("");
  const [triggerData, setTriggerData] = React.useState({});
  const [showMainPopup, setShowMainPopup] = React.useState(false);
  const [showSubPopup, setShowSubPopup] = React.useState(false);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [outputData, setOutputData] = React.useState([]);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [isMainFormSubmitted, setIsMainFormSubmitted] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(
    {
    triggerInterval: "days",
    seconds: {
      second: 30,
    },
    minutes: {
      minutes: 5,
    },
    hours: {
      hours: 1,
      minutes: 0,
    },
    days: {
      daysBetweenTriggers: 1,
      hours: "0:00",
      minutes: 0,
    },
    weeks: {
      weeksBetweenTriggers: 1,
      triggeronWeekdays: ["sunday"],
      hours: "0:00",
      minutes: 0,
    },
    months: {
      monthsBetweenTriggers: 1,
      triggeratDayofMonth: 1,
      hours: "0:00",
      minutes: 0,
    },
    expression: "",
  });
  const [label, setLabel] = React.useState(props.data.label);
  const triggerInterval = [
    { value: "seconds", label: "Seconds" },
    { value: "minutes", label: "Minutes" },
    { value: "hours", label: "Hours" },
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "custom", label: "Custom (Cron)" },
  ];
  const weekdays = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  React.useEffect(() => {
    console.log(schduleNodeOutput);
    props.data.controls.triggerIntervalData.value = JSON.stringify(triggerData);
  }, [triggerData]);

  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.outputData = outputData;
      setEditOutputJson(false);
    }
  }

  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      setIsValidJson(false);
    }
  };

  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }

  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }

  const closeMainPopup = () => {
    setShowMainPopup(false);
    toggleVisibility(false);
  };

  const closeSubPopup = () => {
    setIsLableEdit(false);
  };

  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };

  React.useEffect(() => {
    const socket = io(URLS.BACK_END); // Connect to socket
    socket.on("schdule-trigger", (e) => {
      console.log("HIHIHIHI");
      console.log(e);
      props.data.outputData = JSON.stringify(e);
      runNodes();
    });
    return () => {
      socket.disconnect(); // Disconnect socket when the component unmounts
      console.log("Disconnected from server");
    };
  }, []);

  const validationSchema = Yup.object().shape({
    triggerInterval: Yup.string().required("Trigger Interval is required"),
    seconds: Yup.object().shape({
      second: Yup.number()
        .required("Second is required")
        .min(0, "Second must be at least 0")
        .max(59, "Second must be at most 59"),
    }),
    minutes: Yup.object().shape({
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    hours: Yup.object().shape({
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    days: Yup.object().shape({
      daysBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Days between triggers must be at least 0")
        .typeError("Days between triggers must be a number"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    weeks: Yup.object().shape({
      weeksBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Weeks between triggers must be at least 0")
        .typeError("Weeks between triggers must be a number"),
      triggeronWeekdays: Yup.string()
        .nullable()
        .matches(/^[0-6](,[0-6])*$/, "Invalid weekdays format"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    months: Yup.object().shape({
      monthsBetweenTriggers: Yup.number()
        .nullable()
        .min(0, "Months between triggers must be at least 0")
        .typeError("Months between triggers must be a number"),
      triggeratDayofMonth: Yup.number()
        .nullable()
        .min(1, "Day of month must be at least 1")
        .max(31, "Day of month must be at most 31")
        .typeError("Day of month must be a number"),
      hours: Yup.string()
        .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
        .required("Hours are required"),
      minutes: Yup.number()
        .nullable()
        .min(0, "Minutes must be at least 0")
        .max(59, "Minutes must be at most 59")
        .typeError("Minutes must be a number"),
    }),
    expression: Yup.string().nullable(),
  });

  const formatOutput = (timezone) => {
    const now = moment.tz(timezone);
    return [
      {
        timestamp: now.format(),
        "Readable date": now.format("MMMM Do YYYY, h:mm:ss a"),
        "Readable time": now.format("h:mm:ss a"),
        "Day of week": now.format("dddd"),
        Year: now.format("YYYY"),
        Month: now.format("MMMM"),
        "Day of month": now.format("DD"),
        Hour: now.format("HH"),
        Minute: now.format("mm"),
        Second: now.format("ss"),
        Timezone: now.format("z Z"),
      },
    ];
  };

  function handelFromSubmit(values) {
    setOutputData(formatOutput("UTC"));
    props.data.outputData = outputData;
    let data = {
      ...values,
      timezone: "UTC",
    };

    API.post("rete/schedules/dryrun", data, {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then((data) => {
      console.log(data);
    });

  }

  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }

  function handelEnableDisableNode() {
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }

  function handelCopyNode() {
    props.data.dupicateNode();
  }

  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };

  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label;
  }

  const handleChange = (e) => {
    setLabel(e.target.value);
  };
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(URL.createObjectURL(selectedFile));
      
      // Reset input value so that choosing the same file triggers the event again
      fileInputRef.current.value = null;
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFile(null);
    // Reset the input field value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  function pasreJsonDataFormData(jsonStingData,key){
    if(!isValidJsonCustom(jsonStingData)) return false
    let jsonData=JSON.parse(jsonStingData)
    const keys = key.split('.');
  
  // Reduce the keys to access the nested value
  return keys.reduce((accumulator, currentKey) => {
    // Check if the accumulator is an object and has the current key
    if (accumulator && accumulator.hasOwnProperty(currentKey)) {
      return accumulator[currentKey];
    }
    return false; // Return undefined if the key is not found
  }, jsonData); 
  }
  const formik = useFormik({
    initialValues:{
      triggerInterval: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'triggerInterval')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'triggerInterval'):"days",
      seconds: {
        second: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'seconds.second')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'seconds.second'):30,
      },
      minutes: {
        minutes:  pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'minutes.minutes')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'minutes.minutes'):5,
      },
      hours: {
        hours: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'hours.hours')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'hours.hours'):1,
        minutes: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'hours.minutes')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'hours.minutes'):0,
      },
      days: {
        daysBetweenTriggers: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.daysBetweenTriggers')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.daysBetweenTriggers'):1,
        hours:  pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.hours')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.hours'):"0:00",
        minutes: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.minutes')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'days.minutes'):0,
      },
      weeks: {
        weeksBetweenTriggers: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.weeksBetweenTriggers')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.weeksBetweenTriggers'):1,
        triggeronWeekdays: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.triggeronWeekdays')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.triggeronWeekdays'):["sunday"],
        hours: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.hours')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.hours'):"0:00",
        minutes:pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.minutes')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'weeks.minutes'):0,
      },
      months: {
        monthsBetweenTriggers: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.monthsBetweenTriggers')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.monthsBetweenTriggers'):1,
        triggeratDayofMonth: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.triggeratDayofMonth')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.triggeratDayofMonth'):1,
        hours: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.hours')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.hours'):"0:00",
        minutes: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.minutes')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'months.minutes'):0,
      },
      expression: pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'expression')?pasreJsonDataFormData(props.data.controls.triggerIntervalData.value,'expression'):"",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    enableReinitialize: false,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      setOutputData(formatOutput("UTC"));
      setIsMainFormSubmitted(true)
      props.data.outputData = outputData;
      let data = {
        ...values,
        timezone: "UTC",
      };

  

      API.post("rete/schedules/dryrun", data, {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }).then((data) => {
        setIsMainFormSubmitted(false)
        console.log(data);
      });
      //setSubmitting(false);
    },
  });
  React.useEffect(()=>{
    console.log('on change')
    props.data.controls.triggerIntervalData.value = JSON.stringify(formik.values);
    console.log(JSON.parse(props.data.controls.triggerIntervalData.value))
  },[formik.values])
  React.useEffect(() => {
    //setAllFormValues(formik.values); // Assign all form values to a variable 
    if(props.data.outputData != '' && isValidJsonCustom(props.data.outputData)){
      setOutputData(JSON.parse(props.data.outputData))
    }
  }, [props.data.outputData]);

  const nodeDotsActionOptions = [
    {      
      label: "Open",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Test step",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Rename",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Deactivate",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Pin",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Copy",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Duplicate",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Select all",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Clear selection",
      onClick: () => {
        console.log(" clicked");
      },
    },
    {      
      label: "Delete",
      onClick: () => {
        console.log(" clicked");
      },
    },
  ];
  const nodeDotsActionIcon = "../../images/node_main_action_dots.svg";
  return (
    <>
      <Popup
        open={showMainPopup}
        className="trigger-node-popup"
        onClose={closeMainPopup}
      >
        <div className="modal">
          <div className="node-popup webhook-node-popup fixed w-[calc(100%_-_80px)] h-[calc(100%_-_125px)] left-[0] right-[0] top-[60px] m-auto bg-[#FFF] z-[11] rounded-[8px]">
            <div className="node-popup-inn">
            <div className="node-popup-actions back node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[56px] bg-[#2C2D38] flex items-center justify-between rounded-tr-[8px] w-[calc(100%_-_492px)] ml-auto">
              <div className="node-popup-actions-left">
                  <button
                    onClick={closeMainPopup}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <span className="primary_btn_hover flex h-[56px] items-center justify-center w-[56px] bg-[#146DE0]">
                      <img
                        src="../../images/node_inn_back-square.svg"
                        alt="node_inn_back-square"
                      />
                    </span>
                    <span className="text-[#fff] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[24px] ml-[16px]">
                      Back
                    </span>
                  </button>
                  </div>     
                  <div className="node-popup-actions-right flex items-center justify-end pr-[16px]">
                  <div className="node-popup-autosave-label text-[#fff] font-['inter-semibold'] text-[12px] not-italic font-normal leading-[15px] opacity-50 mr-[16px]">Autosaved</div>  
                  <button
                  onClick={() => {
                    console.log(formik.values);
                    handelFromSubmit(formik.values);
                  }}
                                type="submit"
                                className="primary_btn_hover flex items-center justify-center [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[32px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px] mr-[16px]"
                              >
                                <span className="mr-[8px]">Test Step</span>
                                 
                                <img
                                  src="../../images/rete-btn-loader.svg"
                                  alt="rete-btn-loader"
                                  className={`rete-btn-loader w-[16px] ${isMainFormSubmitted?"":"hidden"}`}
                                  width={16}
                                />
                                  <img
                                  src="../../images/node_inn_flask.svg"
                                  alt="node_inn_flask"
                                  className={`${isMainFormSubmitted?"hidden":""}`}
                                /> 
                              </button>
                <button type='button' onClick={handelDeleteNode}>
                  <img src="../../images/node_inn_delete.svg" alt="node_inn_delete" />  
                </button>                             
                              </div>           
              </div>
              <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                <div className="node-popup-view node-popup-fields-view w-[492px] relative -top-[56px] has-fixed-express-actions">
                  <div className="node-popup-midform">
                  <form onSubmit={formik.handleSubmit}>
                       
                         
                          <div className="midform-head">
                            <div className="midform-head-wrap relative">
                              {isLableEdit && (
                                <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                  <>
                                    <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-normal mb-[10px]">
                                      Rename node
                                    </div>
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      value={label}
                                      className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    />
                                    <div className="flex items-center justify-end mt-[10px]">
                                      <button
                                        type="button"
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                        onClick={handelLableEdit}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                        onClick={handelSaveLableEdit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                  </>
                                </div>
                              )}
                              <div className="midform-dots absolute -top-[24px] left-[0] right-[0] m-auto flex items-center justify-center z-[1]">
                              {/* <img
                                  src="../../images/node_inn_dots.svg"
                                  alt="node_inn_dots"
                               /> */}
                            </div>
                              <div className="midform-head-inn relative flex items-center justify-between h-[70px] px-[20px]">
                                <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                  <img
                                    src="../../images/schedule_trigger_node_inn_icon.svg"
                                    alt="schedule_trigger_node_inn_icon"
                                    className="w-[24px]"
                                  />
                                  <span
                                    onClick={handelLableEdit}
                                    className="ml-[8px] text-[#30313D] font-['inter-semibold'] text-[20px] not-italic font-normal leading-[28px]"
                                    title={props.data.label}
                                  >
                                    {props.data.label}
                                  </span>
                                </div>
                                {/* <button
                                  type="button"
                                  onClick={() => {
                                    console.log(formik.values);
                                    handelFromSubmit(formik.values);
                                  }}
                                  className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                >
                                  Test Workflow
                                </button> */}
                                <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[#E8F0FC] rounded-tl-[8px]"></div>
                              </div>
                            </div>
                          </div>
                          <div className="midform-body rounded-bl-[8px] rounded-br-[8px] bg-[#fff]">
                        <Tabs>
                        <TabList className="flex items-center justify-end">
                              <div className="node-inn-main-acts-tabs flex items-center justify-center w-full bg-[#F2F7FD]">
                                <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Parameters
                                </Tab>
                                <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Settings
                                </Tab>                                
                              </div>
                            </TabList>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full">
                          <div className="midform-fields">
                            <div className="midform-field midform-field-select relative mb-[30px]">
                              <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                Trigger Interval
                              </label>
                              <Select
                                name="triggerInterval"
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={triggerInterval}
                                defaultValue={triggerInterval.find(
                                  (option) =>
                                    option.value == formik.values.triggerInterval
                                )}
                                onChange={(option) =>
                                  formik.setFieldValue(
                                    "triggerInterval",
                                    option.value
                                  )
                                }
                              />
                              {formik.touched.triggerInterval &&
                            typeof formik.errors.triggerInterval ===
                              "string" && (
                              <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                {formik.errors.triggerInterval}
                              </div>
                            )}
                            </div>
                            {formik.values.triggerInterval == "seconds" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Seconds Between Triggers
                                </label> 
                                  <input 
                                  name="seconds.second"
                                  type="number"
                                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  defaultValue={formik.values?.seconds?.second}
                                  onChange={(e)=>{ 
                                    formik.setFieldValue('seconds.second',e.target.value)
                                   }}
                                    />
                                {formik.touched?.seconds?.second &&
                                  typeof formik.errors?.seconds?.second ===
                                    "string" && (
                                      <div  className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                      {formik.errors?.seconds?.second}
                                    </div>
                                  )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "minutes" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Minutes Between Triggers
                                </label>
                                
                                 <input 
                            name="minutes.minutes"
                            type="number"
                            className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                            defaultValue={formik.values.minutes.minutes}
                            onChange={(e)=>{ 
                              formik.setFieldValue('minutes.minutes',e.target.value)
                             }}
                            />
                            {formik.touched?.minutes?.minutes &&
                              typeof formik.errors?.minutes?.minutes ===
                                "string" && (
                                <div  className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.minutes?.minutes}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "hours" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Hours Between Triggers
                                </label> 
                                <input 
                              name="hours.hours"
                              type="text"
                              placeholder="00:00"
                             className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                             defaultValue={formik.values.hours.hours}
                             onChange={(e)=>{ 
                              formik.setFieldValue('hours.hours',e.target.value)
                             }}
                            />
                            {formik.touched?.hours?.hours &&
                              typeof formik.errors?.hours?.hours === "string" && (
                                <div  className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.hours?.hours}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "hours" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label> 
                                 <input 
                               name="hours.minutes"
                               type="number"
                               className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                               defaultValue={formik.values?.hours?.minutes}
                               onChange={(e)=>{ 
                                formik.setFieldValue('hours.minutes',e.target.value)
                               }}
                            />
                            {formik.touched?.hours?.minutes &&
                              typeof formik.errors?.hours?.minutes ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.hours?.minutes}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "days" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Days Between Triggers
                                </label> 
                                 <input 
                               name="days.daysBetweenTriggers"
                               type="number"
                               className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                               defaultValue={formik.values?.days?.daysBetweenTriggers}
                               onChange={(e)=>{ 
                                formik.setFieldValue('days.daysBetweenTriggers',e.target.value)
                               }}
                            />
                            {formik.touched.days?.daysBetweenTriggers &&
                              typeof formik.errors.days?.daysBetweenTriggers ===
                                "string" && (
                                <div  className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors.days?.daysBetweenTriggers}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "weeks" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Weeks Between Triggers
                                </label> 
                                <input 
                               name="weeks.weeksBetweenTriggers"
                               type="number"
                               className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                               defaultValue={formik.values.weeks?.weeksBetweenTriggers}
                               onChange={(e)=>{ 
                                formik.setFieldValue('weeks.weeksBetweenTriggers',e.target.value)
                               }}
                            />
                            {formik.touched?.weeks?.weeksBetweenTriggers &&
                              typeof formik.errors?.weeks
                              ?.weeksBetweenTriggers === "string" && (
                                <div  className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.weeks?.weeksBetweenTriggers}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "weeks" && (
                              <div className="midform-field midform-field-select relative mb-[30px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger on Weekdays
                                </label> 
                                <Select
                              name="weeks.triggeronWeekdays"
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              defaultValue={weekdays.filter((option) =>
                                formik.values.weeks?.triggeronWeekdays.includes(option.value)
                              )}
                              onChange={(options) =>
                                formik.setFieldValue(
                                  "weeks.triggeronWeekdays",
                                  options.map((option) => option.value)
                                )
                              }
                              options={weekdays}
                              isMulti={true}
                            />
                            {formik.touched?.weeks?.triggeronWeekdays &&
                              typeof formik.errors?.weeks?.triggeronWeekdays ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.weeks?.triggeronWeekdays}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "weeks" && (
                              <div className="midform-field midform-field-select relative mb-[30px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                              name="weeks.hours"
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              defaultValue={timearray.find(
                                (option) =>
                                  option.value === formik.values.weeks.hours
                              )}
                              onChange={(option) =>
                                formik.setFieldValue(
                                  "weeks.hours",
                                  option.value
                                )
                              }
                              options={timearray}
                            />
                            {formik.touched?.weeks?.hours &&
                              typeof formik?.errors?.weeks?.hours === "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.weeks?.hours}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "days" && (
                              <div className="midform-field midform-field-select relative mb-[30px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                              name="days.hours"
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              defaultValue={timearray.find(
                                (option) =>
                                  option.value === formik.values.days?.hours
                              )}
                              onChange={(option) =>
                                formik.setFieldValue("days.hours", option.value)
                              }
                              options={timearray}
                            />
                            {formik.touched?.days?.hours &&
                              typeof formik.errors?.days?.hours === "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.days?.hours}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "weeks" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label>
                                
                                <input 
                               name="weeks.minutes"
                               type="number"
                               className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                               defaultValue={formik.values?.weeks?.minutes}
                               onChange={(e)=>{ 
                                formik.setFieldValue('weeks.minutes',e.target.value)
                               }}
                            />
                            {formik.touched?.weeks?.minutes &&
                              typeof formik.errors?.weeks?.minutes ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.weeks?.minutes}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "days" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label> 
                                 <input 
                             name="days.minutes"
                             type="number"
                            className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                            defaultValue={formik.values?.days?.minutes}
                            onChange={(e)=>{ 
                              formik.setFieldValue('days.minutes',e.target.value)
                             }}
                            />
                            {formik.touched?.days?.minutes &&
                              typeof formik.errors?.days?.minutes ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.days?.minutes}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Months Between Triggers
                                </label> 
                                  <input 
                                name="months.monthsBetweenTriggers"
                                type="number"
                                className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                defaultValue={formik.values?.months?.monthsBetweenTriggers}
                                onChange={(e)=>{ 
                                  formik.setFieldValue('months.monthsBetweenTriggers',e.target.value)
                                 }}
                            />
                            {formik.touched?.months?.monthsBetweenTriggers &&
                              typeof formik.errors?.months
                              ?.monthsBetweenTriggers === "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.months?.monthsBetweenTriggers}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Day of Month
                                </label> 
                                 <input 
                                name="months.triggeratDayofMonth"
                                type="number"
                                 className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                 defaultValue={formik.values?.months?.monthsBetweenTriggers}
                                 onChange={(e)=>{ 
                                  formik.setFieldValue('months.triggeratDayofMonth',e.target.value)
                                 }}
                            />
                            {formik.touched?.months?.triggeratDayofMonth &&
                              typeof formik.errors?.months
                              ?.triggeratDayofMonth === "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.months?.triggeratDayofMonth}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "months" && (
                              <div className="midform-field midform-field-select relative mb-[30px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Hour
                                </label>
                                <Select
                              name="months.hours"
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              defaultValue={timearray.find(
                                (option) =>
                                  option.value === formik.values?.months?.hours
                              )}
                              onChange={(option) =>
                                formik.setFieldValue(
                                  "months.hours",
                                  option.value
                                )
                              }
                              options={timearray}
                            />
                            {formik.touched?.months?.hours &&
                              typeof formik.errors?.months?.hours ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.months?.hours}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "months" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Trigger at Minute
                                </label> 
                                <input 
                              name="months.minutes"
                              type="number"
                              className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              defaultValue={formik.values?.months?.minutes}
                              onChange={(e)=>{ 
                                formik.setFieldValue('months.minutes',e.target.value)
                               }}
                            />
                            {formik.touched?.months?.minutes &&
                              typeof formik.errors?.months?.minutes ===
                                "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.months?.minutes}
                                </div>
                              )}
                              </div>
                            )}
                            {formik.values.triggerInterval == "custom" && (
                              <div className="midform-field mb-[30px] relative">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Expression
                                </label> 
                                 <input 
                             name="expression"
                             type="text"
                             className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                             defaultValue={formik.values?.expression}
                             onChange={(e)=>{ 
                              formik.setFieldValue('expression',e.target.value)
                             }}
                            />
                            {formik.touched?.expression &&
                              typeof formik.errors?.expression === "string" && (
                                <div className="trigg_node_error absolute text-[11px] left-0 top-full text-[red]">
                                  {formik.errors?.expression}
                                </div>
                              )}
                              </div>
                            )}
                          </div>
                          </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full min-h-[calc(100vh_-_233px)]">
                                  
                                <div className="midform-fields">
                                <div className="midform-field mb-[30px] relative">
                                  <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Node description
                                  </label>
                                  <input
                                    type="number"
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />                                  
                                </div>
                                </div>

                                </div>
                              </div>
                            </TabPanel>                            
                            </Tabs>                   
                        </div>  
                    </form>
                  </div>
                </div>
                <div className="node-popup-view node-popup-output-view [flex-basis:calc(100%_-_492px)]">
                  <div className="node-popup-output-view-inn">
                    <div className="node-popup-output-tabs">
                      <Tabs
                        selectedIndex={tabIndexOutput}
                        onSelect={(index) => HandelOnselectTab(index)}
                      >
                        <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                          <div className="node-popup-output-title text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">                            
                            Output
                          </div>
                          <TabList className="flex items-center justify-end">
                            {!editOutputJson ? (
                              <>
                                <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                  <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    Table
                                  </Tab>
                                  <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    JSON
                                  </Tab>
                                  <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    Schema
                                  </Tab>
                                </div>
                                <button
                                  className="[transition:0.3s_all_ease] flex items-center justify-center ml-[8px] rounded-[8px] bg-[#fff] w-[36px] h-[36px] border-[1px] border-[solid] border-[#D1D4D9] hover:border-[#74757D]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node_inn_edit.svg"
                                    alt="node_inn_edit"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                  onClick={handelJsonedit}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                  onClick={handelSaveOutputJson}
                                  disabled={!isValidJson}
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </TabList>
                        </div>
                        <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                <DynamicTable data={outputData} />
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel
                            className={`node-popup-output-tab-panel node-json-view  webhook-node-json-view h-[calc(100vh_-_298px)] ${
                              editOutputJson ? "" : "node-json-view-readonly"
                            }`}
                          >
                            <JSONInput
                              id="webhook-json"
                              placeholder={outputData}
                              locale={locale}
                              theme="light_mitsuketa_tribute"
                              colors={{
                                default: "#39ADB5",
                                keys: "#E53935",
                                string: "#91B859",
                                number: "#F76D47",
                                background: "#F7F7F7",
                              }}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                outerBox: {
                                  borderRadius: "6px",
                                  border: "1px solid #CCC",
                                  overflow: "hidden",
                                  background: "#F7F7F7",
                                },
                              }}
                              onChange={handleJsonChange}
                            />
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                            <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                            <DndProvider backend={HTML5Backend}>
                              {outputData.map((item, index) => (
                                <RecursiveComponent
                                  key={index}
                                  item={item}
                                  onFieldDrop={handleDrop}
                                  draggable={false}
                                />
                              ))}
                               </DndProvider>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <NodeStyles
        selected={selected}
        width={280}
        height={61}
        className="nodeWrap node-comm node-trigger"
        data-testid="node"
        
      >
        <div
       /*  onPointerDown={(e) => {
          console.log('mouse down')
          e.stopPropagation();
        }}
        onMouseEnter={(e) => {
          console.log('mouse enter')
          e.stopPropagation();
        }}
        onMouseLeave={(e) => {
          console.log('mouse leave')
          e.stopPropagation();
        }}
        onPointerUp={(e) => {
          console.log('mouse pointer UP')
          e.stopPropagation();
        }} */
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowMainPopup((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
          className="title"
          data-testid="title"
        >
          <img src="../../images/trigger_indicator.svg" alt="trigger_indicator" className="absolute -top-[12px] right-[8px] z-0" />
          <div className="node-block-parent">
            {/* <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
              <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
              </div>
              <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                <img
                  src="../../images/plus-icon-node.svg"
                  alt="plus-icon-node"
                />
              </button>
            </div> */}
            <div className="node-block flex items-center justify-center">
              <div className="node-block-actions absolute top-[100%] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pt-[17px] [cursor:initial]">
              <div className="node-block-action relative">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_run.svg"
                    alt="node_main_action_run"
                  />                  
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Run</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_copy.svg"
                    alt="node_main_action_copy"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Copy</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_delete.svg"
                    alt="node_main_action_delete"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Delete</span>
                </div>
                <div className="node-block-action relative">
                <button                  
                  className="cursor-pointer bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >                  
                  <DotsDropdown options={nodeDotsActionOptions} iconSrc={nodeDotsActionIcon} />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">More</span>
                </div>
              </div>
              <div className="node-block-icon-parent w-[36px] h-[36px] flex items-center justify-start mr-[8px]">
                <img
                  className="node-block-icon"
                  src="../../images/schedule_trigger_node_main_icon.svg"
                  alt="schedule_trigger_node_main_icon"
                />
              </div>
              <div className="node-block_text">
              <span className="node-block-title block text-[#30313D] font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]" title={props.data.label}>
                {props.data.label}
              </span>
              <span className="node-block-desc block text-[#74757D] font-[inter-medium] text-[12px] not-italic font-normal leading-[14.52px] mt-[3px]">
                Trigger node desc text   
              </span>
              </div>
            </div>
          </div>
        </div>
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
