import React, { useState } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = {
  FIELD: 'field',
};

const DraggableField = ({ fieldKey, fieldValue,draggable }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.FIELD,
    item: { fieldKey, fieldValue },
    canDrag: () => draggable,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} className="mb-[6px] cursor-grab">
      <span className="inline-block px-[10px] py-[8.5px] bg-[#e9f0fc] rounded-[4px] text-[rgba(0,0,0,0.7)] font-['inter-regular'] text-[11px] not-italic font-normal leading-[normal] mr-[13px]">
        <span className="flex items-center justify-start">
        {draggable && (<img src="../../images/drag-icon-blue.svg" alt="drag-icon" className="mr-[11px]" />)}
        {fieldKey}
        </span>
      </span>
      <span className="text-[rgba(0,0,0,0.5)] font-['inter-regular'] text-[11px] not-italic font-normal leading-[normal]">
        {fieldValue.toString()}
      </span>
    </div>
  );
};
export default DraggableField;