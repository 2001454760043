import React, { useRef } from 'react';
import Editor from '@monaco-editor/react';

function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.addExtraLib(`
        declare var $json: {};
    `, 'filename/fake.d.ts');
}

const CodeEditor = ({ codeval, fieldName, formik, code, setCode }) => {
    const outputRef = useRef(null);

    const handleEditorChange = (value) => {
        setCode(value);  // Update code state in parent component
        formik.setFieldValue(fieldName, value);  // Set formik value
    };

    const executeCode = () => {
        try {
            const globalVarStr = JSON.stringify(codeval);
            const fullCode = `
                const $json = ${globalVarStr};
                ${formik.values.code}
            `;
            const func = new Function(fullCode);
            outputRef.current.textContent = 'Output:\n' + func();
        } catch (error) {
            outputRef.current.textContent = 'Error:\n' + error.toString();
        }
    };

    return (
        <div>
            <div className="monaco_editor_parent">
                <Editor
                    height="300px"
                    defaultLanguage="javascript"
                    value={formik.values.code}  // Controlled component using code from the parent
                    theme="light"
                    beforeMount={handleEditorWillMount}
                    onChange={handleEditorChange}
                    options={{
                        minimap: { enabled: false },
                        automaticLayout: true,  // Ensure Monaco automatically adjusts to container size
                    }}
                />
            </div>
            <button className='Run-button' onClick={executeCode}>Run Code</button>
            <pre ref={outputRef} style={{ background: "#eee", padding: "10px" }}></pre>
        </div>
    );
};

export default CodeEditor;
