import { Link } from "react-router-dom";
import DotsDropdown from "../../components/DotsDropdown";
import CommonLayout from "../../components/view/common-layout";
import CommonHeader from "../../components/view/common-header";
import { useEffect, useState } from "react";
import createAPI from "../../api";
import { formatWorkflowInfo } from "../../utils";

const WorkFlow = () => {
  const API = createAPI();
  const [workFlow, setWorkFlow] = useState([]);

  const dotoptions = [
    {
      label: "Reset Value",
      onClick: () => console.log("Reset Value clicked"),
    },
    {
      label: "Fixed",
      onClick: () => console.log("Fixed clicked"),
    },
    {
      label: "Another Option",
      onClick: () => console.log("Another Option clicked"),
    },
  ];

  // Correct placement of useEffect
  useEffect(() => {
    API.get('rete/get', {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then(data => {
      console.log(data);
      setWorkFlow(data.data.workFlows);
    }).catch(err => {
      console.error("Error fetching workflows:", err);
    });
  }, []); // The empty dependency array ensures this runs only once when the component mounts

  const workRowOptions = [
    {
      imageSrc: '../../images/drop_edit.svg',
      label: 'Edit',
      onClick: () => {console.log('Edit clicked')},
    },
    {
      imageSrc: '../../images/drop_share.svg',
      label: 'Share',
      onClick: () => {console.log('Share clicked')},
    },
    {
      imageSrc: '../../images/drop_duplicate.svg',
      label: 'Duplicate',
      onClick: () => {console.log('Duplicate clicked')},
    },
    {
      imageSrc: '../../images/drop_delete.svg',
      label: 'Delete',
      onClick: () => {console.log('Delete clicked')},
    }
  ];

  const workRowSrc = '../../images/common-module-dots.svg';

  return (
    <CommonLayout>
      <div className="workflow-listing w-[calc(100%_-_80px)] h-screen ml-auto bg-[#F2F5F7]">
        <CommonHeader />
        <div className="common-module-list px-[40px]">
          <div className="common-module-list-head flex items-center justify-between py-[25px]">
            <div className="workflow-listing-head-title text-[#1C1E21] font-['inter-bold'] text-[20px] not-italic font-normal leading-[normal]">
              My Workflows
            </div>
            <Link
              to="/home/workflow/new"
              className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[36px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[16px] rounded-[6px]"
            >              
              <span>Add Workflow</span>
              <img
                src="../../images/add-node-plus.svg"
                alt="add-node-plus"
                className="ml-[8px] w-[16px]"
              />
            </Link>
          </div>
          <div className="common-module-list-head-body bg-[#fff]">
            <div className="common-module-list-actions p-[18px] pb-0">
              <div className="common-module-list-actions-wrap border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-start justify-between pb-[18px]">
                <div className="common-module-list-action-search flex align-center justify-start border [border-style:solid] [border-color:#DCE4E8] rounded-[6px] px-[14px] w-[267px]">
                  <img src="../../images/credentials-dropdown-search.svg" alt="credentials-dropdown-search" width={16} />
                  <input type="text" placeholder="Search workflow" className="text-[#30313D] h-[36px] text-[14px] w-full pl-[10px] font-[inter-medium]" />
                </div>

                <div className="common-module-list-action-buttons flex items-start justify-end">
                  <div className="common-module-list-action-button common-module-list-action-button-filter cursor-pointer w-[89px] h-[36px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">                    
                    <span className="font-[inter-medium] text-[14px] text-[#30313D] mr-[8px]">Filter</span>
                    <img src="../../images/filter-button-icon.svg" alt="filter-button-icon" width={18} />
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-sortby ml-[8px] cursor-pointer w-[117px] h-[36px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px]">
                    <span className="font-[inter-medium] text-[14px] text-[#30313D] mr-[8px]">Sort By</span>
                    <img src="../../images/sort-by-icon.svg" alt="sort-by-icon" width={18} />                    
                  </div>
                  <div className="common-module-list-action-button common-module-list-action-button-group ml-[8px] cursor-pointer h-[36px] flex items-center justify-center border [border-style:solid] [border-color:#DCE4E8] rounded-[8px] px-[3px]">
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-semibold] bg-[#6A737D] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#fff] active">By me</div>
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-medium] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#1A1C1E]">Team</div>
                    <div className="common-module-list-action-button-group-block h-[28px] font-[inter-medium] text-[12px] px-[12px] rounded-[6px] leading-[28px] text-[#1A1C1E]">Shared</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-module-list-rows p-[18px] pb-[4px] max-h-[calc(100vh_-_235px)] overflow-auto">
              <div className="common-module-list-rows-wrap ">
                {workFlow.map((val, key) => (
                  <div key={key} className="common-module-list-row mb-[12px] border [border-style:solid] [border-color:#DCE4E8] hover:[border-color:#146DE0] [transition:0.3s_all_ease] rounded-[12px] px-[16px] h-[68px] flex items-center justify-between">
                    <div className="common-module-list-row-left flex iterms-center justify-start">
                      <div className="common-module-list-row-icon">
                        <img src="../../images/gmail-icon.png" alt="gmail-icon" width={36} />
                      </div>
                      <div className="common-module-list-row-left-wrap ml-[15px]">
                        <div className="common-module-list-row-title font-[inter-semibold] text-[14px] leading-[19.6px] text-[#30313D] mb-[4px]">
                          <Link to={`/home/workflow/${val.id}`}>{val.title}</Link>
                        </div>
                        <div className="common-module-list-row-desc font-[inter-medium] text-[12px] leading-[14px] text-[#6C7278]">
                          {formatWorkflowInfo(val)} 
                        </div>
                      </div>
                    </div>
                    <div className="common-module-list-row-right flex items-center justify-end">
                      <div className="common-module-list-row-avatars mr-[16px] flex items-center justify-center">
                        <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                          <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                        </div>
                        <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                          <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                        </div>
                        <div className="common-module-list-row-avatar border-2 [border-style:solid] [border-color:#fff] rounded-[100%] -mr-[9px]">
                          <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                        </div>
                        <div className="common-module-list-row-avatar-count border-2 [border-style:solid] [border-color:#fff] rounded-[100%] bg-[#EBEEF1]">
                          <div className="w-[32px] h-[32px] flex items-center justify-center font-[inter-semibold] text-[12px]">+2</div>
                        </div>
                      </div>
                      <div className="common-module-list-row-created-by bg-[#EAEAEC] rounded-[4px] font-[inter-medium] text-[12px] leading-[18px] text-[#30313D] px-[7px] mr-[16px]">
                        Created by me
                      </div>
                      <div className="workflow-switch node-comm-switch flex items-center justify-center mr-[12px]">
                        <input
                          type="checkbox"
                          id="workflow-switch"
                          className="h-[0] w-[0] invisible"
                        />
                        <label
                          className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative mr-[6px]"
                          htmlFor="workflow-switch"
                        >
                          Toggle
                        </label>
                        <div className="switch-spans flex items-center justify-start w-[54px]">
                          <span className="switch-span switch-span-1 text-[#30313D] text-right font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px]">
                            Inactive
                          </span>
                          <span className="switch-span switch-span-2 text-[#30313D] text-right font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px]">
                            Active
                          </span>
                        </div>
                      </div>
                      <div className="common-module-list-row-dots-action w-[32px] h-[32px] cursor-pointer flex items-center justify-center">
                        <DotsDropdown options={workRowOptions} iconSrc={workRowSrc} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default WorkFlow;
