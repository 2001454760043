import React, { useState, useRef, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fileToBase64, validateImageDimensions } from '../../utils';
import createAPI from '../../api';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom validation function for image dimensions


// Validation schema with custom validation for categoryIcon
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  categoryIcon: Yup.mixed()
    .required('Category icon is required') 
    .test('fileDimensions', 'Icon must be 24x24 pixels', async (value) => {
      if (!value) return false;
      return await validateImageDimensions(value);
    }),
});

const AddCategoryPopup = ({ onClose, onSubmit }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isformikSaveNodeSubmited, setIsformikSaveNodeSubmited] = useState(false);
  const API = createAPI();
  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: '',
      categoryIcon: null,
    },
    validationSchema,
    onSubmit: (values) => {
      setIsformikSaveNodeSubmited(true)
      let apiData = API.post(
        `node-category/create`,
        values,
        {
          headers: {
            "Requires-Auth": true, // This tells the interceptor to add the auth token
          },
        }
      )
        .then((data) => {
          setIsformikSaveNodeSubmited(false)
            onSubmit({data,isDataAdded:true});
            formik.resetForm();
            onClose();
          //setId()
          toast("Category save successfully", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        })
        .catch((err) => {
          setIsformikSaveNodeSubmited(false)
            toast(err.message, {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
        });
      
    },
  });

  // const handleFileChangeCat = async (event) => {
  //   const file = event.currentTarget.files[0];
  //   setSelectedFile(file ? file.name : null);
  //   const base64String = await fileToBase64(file);
  //   await formik.setFieldValue('categoryIcon', {file:base64String,filename:file.name,folderPath: 'rete-newra'});
  // };

  const [fileNameCat, setFileNameCat] = useState("");
  const [fileCat, setFileCat] = useState(null);
  const fileInputRefCat = useRef(null);

  const handleFileChangeCat = async (event) => {
    const selectedFile = event.target.files[0];
    console.log('on change',selectedFile)
    if (selectedFile) {
      console.log('on change',selectedFile)
      setFileNameCat(selectedFile ? selectedFile.name : null);
      const base64String = await fileToBase64(selectedFile); 
      setFileCat(URL.createObjectURL(selectedFile));  
      await formik.setFieldValue('categoryIcon', {file:base64String,filename:selectedFile.name,folderPath: 'rete-newra'});
      // Reset input value so that choosing the same file triggers the event again
      fileInputRefCat.current.value = null;
    }
  };

  const handleFileRemoveCat = () => {
    setFileNameCat("");
    formik.setFieldValue('categoryIcon','')
    setFileCat(null);
    // Reset the input field value
    if (fileInputRefCat.current) {
      fileInputRefCat.current.value = null;
    }
  };

  return (
    
        <div className="credentials-popup credentials-popup-1 z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
        <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
        <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
        <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Add New Category</div>
        <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={onClose}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
        </div>
        <div className="px-[29px] pt-[32px] pb-[32px]">
        <form onSubmit={formik.handleSubmit}>
        <div className="midform-field midform-field-select relative mb-[30px]">
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[5px]">Category Name <span className="text-[#df1941]">*</span></label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Category name"
              className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-[#DF1A41] font-[inter-regular] text-[12px]">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="midform-custom_file_input midform-field midform-field-select relative mb-[15px]">
                                      <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                      Category Icon
                                      </label>
                                      <div className="midform-custom_file_input-inn relative flex items-center justify-start border border-solid border-[#D1D4D9] rounded-[6px] h-[40px] px-[15px]">
                                        <label
                                          htmlFor="fileInputCat"
                                          className="cursor-pointer"
                                        >
                                          <input 
                                          id="fileInputCat"
                                            type="file"
                                            accept="image/*"
                                            name="categoryIcon"
                                            onBlur={formik.handleBlur}
                                            onChange={handleFileChangeCat}
                                            style={{ display: "none" }}
                                            ref={fileInputRefCat}
                                          />
                                          {fileCat ? (
                                            <img
                                              src={fileCat}
                                              alt="File Icon"
                                              style={{
                                                width: 24,
                                                height: 24,
                                                marginRight: 8,
                                              }}
                                            />
                                          ) : (
                                            <span className="absolute top-[0] left-[0] w-full h-full z-[1]"></span>
                                          )}
                                        </label>
                                        <div
                                          className="whitespace-nowrap w-[calc(100%_-_48px)] overflow-hidden overflow-ellipsis text-[#1A1C1E] font-[inter-medium] text-[14px] font-normal leading-[19.6px]"
                                          title={fileNameCat}
                                        >
                                          {fileNameCat || "Choose Icon"}
                                        </div>
                                        {!fileNameCat && (
                                          <button
                                            style={{
                                              marginLeft: "auto",
                                              border: "none",
                                              background: "transparent",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          >
                                            <img
                                              src="../../images/upload-file-icon.svg"
                                              alt="upload-file-icon"
                                            />
                                          </button>
                                        )}
                                        {fileNameCat && (
                                          <button
                                            onClick={handleFileRemoveCat}
                                            style={{
                                              marginLeft: "auto",
                                              border: "none",
                                              background: "transparent",
                                              cursor: "pointer",
                                              color: "#555",
                                            }}
                                          >
                                            <img
                                              src="../../images/delete_for_icon.svg"
                                              alt="delete_for_icon"
                                            />
                                          </button>
                                        )}
                                      </div>
                                      
                                      {formik.touched.categoryIcon && formik.errors.categoryIcon ? (
              <div className="text-[#DF1A41] font-[inter-regular] text-[12px]">{String(formik.errors.categoryIcon)}</div>
            ) : (
              <div className="flex items-center justify-start mt-[4px]">
              <img
                src="../../images/upload-info-icon.svg"
                alt="upload-info-icon"
              />
              <span className="ml-[4px] text-[#74757D] font-[inter-regular] text-[12px] leading-[16.8px]">
                Your icons should be 24x24 pixels.
              </span> 
            </div>
            )}
                                      </div>
          {/* <div className="midform-field midform-field-select relative mb-[30px]">
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[5px]">Category Icon</label>
            <input
              type="file"
              name="categoryIcon"
              accept="image/*"
              onChange={handleFileChangeCat}
              onBlur={formik.handleBlur}
              className=""
            />
            {selectedFile && <p className="text-sm text-gray-700 mt-1">{selectedFile}</p>}
            {formik.touched.categoryIcon && formik.errors.categoryIcon ? (
              <div className="text-[#DF1A41] font-[inter-regular] text-[12px]">{formik.errors.categoryIcon}</div>
            ) : (
              <p className="text-xs text-gray-500 mt-1">Your icons should be 24×24 pixels.</p>
            )}
          </div> */}

          <div className="form_comm_actions flex items-center justify-center">
            <button
              type="button"
              onClick={onClose}
              className="w-[180px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="primary_btn_hover relative min-w-[180px] py-2 px-4 rounded text-white [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] flex items-center justify-center"
            >
              Create Category
              
               <img
                  src="../../images/rete-btn-loader.svg"
                  alt="rete-btn-loader"
                  className={`rete-btn-loader w-[16px] ml-[8px] absolute right-[12px] top-[0] bottom-[0] m-auto ${isformikSaveNodeSubmited?"":"hidden"}`}
                  width={16}
                /> 

            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default AddCategoryPopup;
