import React, { useState } from 'react';
import Select from 'react-select';
import CustomSelectIconOption from './CustomSelectIconOption';
import CustomSelectIconValue from './CustomSelectIconValue';
import AppDropdown from './form/AppDropdown';

const PopupCred = ({ isOpen, onClose, items, onContinue }) => {
    const [selectedOption, setSelectedOption] = useState(null);
  
    const handleChange = (selected) => {
      setSelectedOption(selected);
    };
  
    const handleContinue = () => {
      if (onContinue) {
        onContinue(selectedOption);
      }
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="credentials-popup credentials-popup-1 z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
        <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
        <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Add new credential</div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={onClose}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
          
          </div>
          <div className="px-[29px] pt-[32px] pb-[32px]">
            <div className="midform-field midform-field-select relative mb-[30px]">
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[5px]">Select app or service <span className="text-[#df1941]">*</span></label>
          
            <AppDropdown
              items={items}
              selectedOption={selectedOption}
              onChange={handleChange}
            />
          
          </div>
          
          <button className="test-credential flex items-center justify-center hidden">
            <img src="../../images/cred_key.svg" alt="cred_key" className="mr-[4px]" />
            <span className="text-[#146DE0] font-[inter-medium] text-[14px] font-normal leading-[19.6px]">Test credential</span>
          </button>
          
          <div className="form_comm_actions flex items-center justify-center">
          <button className={`w-[150px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]`}>
            Cancel
          </button>
          <button
            onClick={handleContinue}
            className={`primary_btn_hover w-[150px] py-2 px-4 rounded text-white ${
              selectedOption ?
              '[transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]'
              :
              '[transition:0.3s_opacity_ease] opacity-50 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] cursor-not-allowed'
            }`}
            disabled={!selectedOption}
          >
            Save
          </button>
          </div>
          </div>
        </div>
      </div>
    );
};

export default PopupCred;
