import React, { useState, useRef } from 'react';
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Switch from "react-switch";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HttpNode from "../customization/Httpnode";
import { Schemes } from "../rete/types";
import DynamicKeyValueInput from "./dynamicInput";
import DynamicKeyValueInputPagenation from "./dynamicinputPagination";
import WebHookTrigger from "../customization/webhookTrigger";
import io from "socket.io-client";
import { useEffect } from "react";
import { sleep, URLS } from "../utils";
import createAPI from "../api";
import WebhookPopUpUI from "./WebHookPopupUI";
import Popup from "reactjs-popup";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import DynamicTable from "../components/Dynamictable";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import RecursiveComponent from "../components/RecursiveComponent";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Formik,
  Form,
  ErrorMessage,
  FormikProps,
} from "formik";
import * as Yup from "yup";
import JSONInputWrapper from "../components/JSONInputWrapper";
import {
  deleteNodes,
  runNodes,
} from "../editor";
import { toast } from "react-toastify";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: WebHookTrigger & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid #BABFC6;  
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  // width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  width: 280px;
  height: 61px;
  padding-left: 11px;
  position: relative;
  user-select: none;
  outline: 0px solid #146de0;  
  box-shadow: 0 0 0 0 #cad8f8;  
  &:hover {
    outline: 2px solid #146de0;
  }
  &:focus {
    outline: 2px solid #146de0; 
    box-shadow: 0 0 0 6px #cad8f8;       
  }  
  ${(props) =>
    props.selected &&
    css`
      outline: 2px solid #146de0; 
      box-shadow: 0 0 0 6px #cad8f8;  
    `}
  .title {
    
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -9px;
    height: 20px;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;    
    background-image: url(../../images/node_main_connector.svg);
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: right;
  }
  .input-socket {
    text-align: left;
    margin-left: -1px;
    display: inline-block;
  }
  .input-title,
  .output-title {
    display: none;
    vertical-align: middle;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }

  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .sc-jsJBEP.ciTnqI {
    height: auto !important;
    width: 250px !important;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }
  .disabled-node {
    opacity: 0.5;
    pointer-events: none; /* Prevents interaction */
  }
  ${(props) => props.styles && props.styles(props)}
`;
interface FormValues {
  url: string;
  path: string;
  method: string;
  body: Object;
}
export function WebhookComponent(props: Props<Schemes>) {
  const API = createAPI();
  const [webhookData, setWebhookData] = React.useState({
    url: props.data.controls.url.value + "/" + props.data.controls.path.value,
    path: props.data.controls.path.value,
    method: props.data.controls.method.value,
  });
  const [runbuttonText, setRunbuttonText] = React.useState("Listen for Event");
  const [isListening, setIsListening] = React.useState(false);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls);
  const { id, width, height } = props.data;
  const [popup, setPopup] = React.useState(null);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [outputData, setOutputData] = React.useState([]);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const submitButtonRef = React.useRef(null);
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [isMainFormSubmitted, setIsMainFormSubmitted] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  useEffect(() => {
    const socket = io(URLS.BACK_END); // Connect to socket
    socket.on("webhookTrigger", async (e) => {
      console.log(e.uniqueId, webhookData.path);
      if (e.uniqueId === webhookData.path) {
        props.data.httpData = JSON.stringify(e.responce)
        //  await sleep(10000) 

        setOutputData(e.responce);
        API.post("rete/webhook/start-stop-webhook", {
          webhookPath: webhookData.path,
          isStarted: false,
          method: webhookData.method
        },
          {
            headers: {
              "Requires-Auth": true, // This tells the interceptor to add the auth token
            },
          }
        ).then((data) => {

          setIsListening(data.data.webhookJson.isStarted);
          //  props.data.reExecuteData()
          //props.data.editor.process(props.data.id);
          runNodes();
          if (data.data.webhookJson.isStarted) {
            props.data.log("Data received");
            setRunbuttonText("Stop listening");
          } else {
            setRunbuttonText("Listen for Event");
          }
        });
      }
    });
    // Return a cleanup function
    return () => {
      socket.disconnect(); // Disconnect socket when the component unmounts
      console.log("Disconnected from server");
    };
  }, []);
  useEffect(() => {
    console.log(props.data.httpData);
    /*  setOutputData(JSON.parse(props.data.httpData)) */
  }, [props.data.httpData]);
  useEffect(() => {
    let data = API.get(`rete/webhook/get-status/${webhookData.path}`).then(
      (data) => {
        console.log(data.data.isListing);
        setIsListening(data.data.isListing);
        if (data.data.isListing) {
          setRunbuttonText("Stop listening");
        } else {
          setRunbuttonText("Listen to webhook");
        }
      }
    );
  }, []);

  async function handelWebHook() {
    setRunbuttonText("Stop listening");
    /* if (formikRef.current) {
      formikRef.current.submitForm();
    } */
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
    setIsMainFormSubmitted(true)
    //runNodes();
    console.log(webhookData);
    API.post("rete/webhook/start-stop-webhook", {
      webhookPath: webhookData.path,
      isStarted: !isListening,
      method: webhookData.method
    },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    ).then((data) => {
      setIsListening(data.data.webhookJson.isStarted);
      setIsMainFormSubmitted(false)
      if (data.data.webhookJson.isStarted) {
        setRunbuttonText("Stop listening");
      } else {
        setRunbuttonText("Listen for Event");
      }
    }).catch(error=>{
      setIsMainFormSubmitted(false)
    });
  }
  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const options = [
    { value: "GET", label: "GET" },
    { value: "POST", label: "POST" },
    { value: "DELETE", label: "DELETE" },
    { value: "GET-1", label: "HEAD" },
    { value: "PATCH", label: "PATCH" },
    { value: "PUT", label: "PUT" },
  ];
  const [selectedOption, setSelectedOption] = React.useState(null);
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  const validationSchema = Yup.object({
    url: Yup.string().required("URL is required"),
    path: Yup.string().required("Path is required"),
    method: Yup.string().required("HTTP Method is required"),
    body: Yup.string(),
  });
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outputData;
      setEditOutputJson(false);
    }
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };

  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    props.data.dupicateNode();
  }
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(webhookData.url)
      .then(() => {
        //alert('Copied to clipboard!');
        toast("Url copied to clipboard", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        // props.data.log("Url copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
    // Update your form state here with the dropped item
  };
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
    setLabel(props.data.label);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
    props.data.label = label
  }
  const handleChangeLable = (e) => {
    let newName = e.target.value
    if (newName.length > 30) {
      // Limit the input length to 20 characters
      //console.log("Maximum character length is 20");
      return;
    }
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
    /* props.data.label = e.target.value; */
  };

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(URL.createObjectURL(selectedFile));

      // Reset input value so that choosing the same file triggers the event again
      fileInputRef.current.value = null;
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFile(null);
    // Reset the input field value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  return (
    <>
      <Popup
        open={showpopUp}
        className="webhook-node-popup"
        onClose={closeModal}
      >
        <div className="modal">
          <div className="node-popup webhook-node-popup fixed w-[calc(100%_-_80px)] h-[calc(100%_-_125px)] left-[0] right-[0] top-[60px] m-auto bg-[#FFF] z-[11] rounded-[8px]">
            <div className="node-popup-inn">
              <div className="node-popup-actions back node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[56px] bg-[#2C2D38] flex items-center justify-between rounded-tl-[8px] rounded-tr-[8px]">
                <div className="node-popup-actions-left">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <span className="primary_btn_hover flex h-[56px] items-center justify-center w-[56px] rounded-tl-[8px] bg-[#146DE0]">
                      <img
                        src="../../images/node_inn_back-square.svg"
                        alt="node_inn_back-square"
                      />
                    </span>
                    <span className="text-[#fff] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[24px] ml-[16px]">
                      Back
                    </span>
                  </button>
                </div>
                <div className="node-popup-actions-right flex items-center justify-end pr-[16px]">
                  <div className="node-popup-autosave-label text-[#fff] font-['inter-semibold'] text-[12px] not-italic font-normal leading-[15px] opacity-50 mr-[16px]">Autosaved</div>
                  <button
                    type="button"
                    onClick={handelWebHook}
                    className="primary_btn_hover flex items-center justify-center [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[32px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px] mr-[16px]"
                  >
                    <span className="mr-[8px]">Test Step</span>
                     
                      <img
                        src="../../images/rete-btn-loader.svg"
                        alt="rete-btn-loader"
                        className={`rete-btn-loader w-[16px] ${isMainFormSubmitted?"":"hidden"}`}
                        width={16}
                      />
                        <img
                        src="../../images/node_inn_flask.svg"
                        alt="node_inn_flask"
                        className={`${isMainFormSubmitted?"hidden":""}`}
                      /> 
                  </button>
                  <button type='button' onClick={handelDeleteNode}>
                    <img src="../../images/node_inn_delete.svg" alt="node_inn_delete" />
                  </button>
                </div>
              </div>
              <div className="node-popup-views flex h-[calc(100vh_-_120px)]">
                <div className="node-popup-view node-popup-input-view w-[calc(50%_-_246px)]">
                  <div
                    className={`listen-event-block flex flex-col items-center justify-center h-full px-[45px] py-[0] `}
                  >
                    <div
                      className={`comm-node-pulse webhook-node-pulse flex flex-col items-center justify-center w-full ${!isListening ? "hidden" : ""
                        }`}
                    >
                      <div className="comm-node-pulse-anime relative w-[175px] h-[175px] flex items-center justify-center">
                        <div className="comm-node-pulse-wave absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
                          <div className="comm-node-pulse-icon flex items-center justify-center w-[74px] h-[74px] rounded-[50%] text-[#FFF] text-[20px] text-center leading-[100px] font-[sans-serif] uppercase animate-[webhook-pulse_3s_linear_infinite] cursor-pointer">
                            <img
                              src="../../images/webhook-node-icon.svg"
                              alt="webhook-node-icon"
                              className="w-[40px]"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="comm-node-pulse-input w-full mb-[40px]">
                        <div className="comm-node-pulse-input-title font-[inter-medium] text-[18px] leading-[normal] mt-[20px] text-[#146DE0] text-center">
                          Listening for test event
                        </div>
                        <div className="comm-node-pulse-input-label text-center font-[inter-regular] text-[16px] text-[rgba(0,_0,_0,_0.5)] mt-[10px] mb-[10px]">
                          Make a {webhookData.method == 'GET-1' ? 'Head' : webhookData.method} request to:
                        </div>
                        <div className="comm-node-pulse-input-parent relative">
                          <input
                            value={webhookData.url}
                            type="text"
                            className=" w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[rgba(0,_0,_0,_0.5)] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal]"
                          />
                          <span
                            className="absolute right-px top-[0] h-[34px] bg-[#fff] text-[rgba(0,_0,_0,_0.5)] bottom-[0] m-auto rounded-[5px] leading-[34px] px-[10px] py-[0] text-[12px] cursor-pointer opacity-0 z-[1]"
                            onClick={handleCopyClick}
                          >
                            Click to copy
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={handelWebHook}
                      className={`primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 px-[12px] py-0 h-[34px] rounded-[4px] bg-[#146DE0] text-[#FFF] font-['inter-regular'] ${isListening ? "node-popup-button-listening" : ""
                        } text-[12px] not-italic font-normal leading-[normal] mb-[37px]`}
                    >
                      {runbuttonText}
                    </button>
                    {/* <p className="text-[#000] text-center font-['inter-regular'] text-[12px] not-italic font-normal leading-[normal]">
                      Once you’ve finished building your workflow, run it
                      without having to click the button by using the production
                      webhook URL.
                    </p> */}
                  </div>
                </div>
                <div className="node-popup-view node-popup-fields-view w-[492px] relative -top-[56px] has-fixed-express-actions">
                  <div
                    className={`node-popup-midform ${isListening ? "node-popup-midform-listening" : ""
                      }`}
                  >
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        url: props.data.controls.url.value,
                        path: props.data.controls.path.value,
                        method: props.data.controls.method.value,
                        body: {},
                      }}
                      validationSchema={validationSchema}
                      validateOnChange={true} // Validates form on each field's onChange event
                      validateOnBlur={true}
                      onSubmit={(values, { setSubmitting }) => {
                        setIsMainFormSubmitted(true)
                        /*  alert(JSON.stringify(values, null, 2)); */
                        setSubmitting(false);
                        setWebhookData(values);
                        console.log(values);
                        setIsMainFormSubmitted(false)
                        //   runNodes();
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        setFieldValue,
                      }) => (
                        <Form>
                          <div className="midform-head">
                            <div className="midform-head-wrap relative">
                              {isLableEdit && (
                                <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                  <>
                                    <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-normal mb-[10px]">Rename node</div>
                                    <input
                                      type="text"
                                      onChange={handleChangeLable}
                                      value={label}
                                      className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                    />
                                    <div className="flex items-center justify-end mt-[10px]">
                                      <button
                                        className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                        onClick={handelLableEdit}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                        onClick={handelSaveLableEdit}
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                  </>
                                </div>
                              )}
                              {/* <div className="midform-dots absolute -top-[24px] left-[0] right-[0] m-auto flex items-center justify-center z-[1]">
                              <img
                                  src="../../images/node_inn_dots.svg"
                                  alt="node_inn_dots"
                               />
                            </div> */}
                              <div className="midform-head-inn relative flex items-center justify-between h-[70px] px-[20px]">
                                <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                  <img
                                    src="../../images/webhook_node_inn_icon.svg"
                                    alt="webhook_node_inn_icon"
                                    className="w-[24px]"
                                  />
                                  <span
                                    onClick={handelLableEdit}
                                    className="ml-[8px] text-[#30313D] font-['inter-semibold'] text-[20px] not-italic font-normal leading-[28px]"
                                    title={props.data.label}
                                  >
                                    {props.data.label}
                                  </span>
                                </div>
                                {/*  <button
                                ref={submitButtonRef}
                                type="button"
                                onClick={() => {
                                  let updateData = {
                                    ...values,
                                    url: values.url + "/" + values.path,
                                  };
                                  setWebhookData(updateData);
                                  handelWebHook();
                                }}
                                className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button> */}
                                <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[#E8F0FC] rounded-tl-[8px] rounded-tr-[8px]"></div>
                              </div>
                            </div>
                          </div>
                          <div className="midform-body rounded-bl-[8px] rounded-br-[8px] bg-[#fff]">
                            <Tabs>
                              <TabList className="flex items-center justify-end">
                                <div className="node-inn-main-acts-tabs flex items-center justify-center w-full bg-[#F2F7FD]">
                                  <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                    Parameters
                                  </Tab>
                                  <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                    Settings
                                  </Tab>
                                </div>
                              </TabList>
                              <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                                <div className="node-table-view-wrap">
                                  <div className="node-table-main relative overflow-x-auto w-full">
                                    <div className="midform-fields">
                                      <div className="midform-field mb-[30px]">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          URL
                                        </label>
                                        <input
                                          type="text"
                                          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[rgba(209,212,217,0.5)] bg-[rgba(234,234,236,0.5)] text-[rgba(48,49,61,0.5)] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                          readOnly
                                          name="url"
                                          value={`${values.url}/${values.path}`}
                                        />
                                        <ErrorMessage className="error" name="url" component="div" />
                                      </div>
                                      <div className="midform-field midform-field-select relative mb-[30px]">
                                        {/* <div className="midform-field-actions absolute -top-[6px] flex items-center justify-start z-[1]">
                                <button className="midform-field-action midform-field-action-dots mr-[5px] w-[20px] h-[20px] flex items-center justify-center cursor-pointer rounded-[100%] hover:bg-[#EEEEEE]">
                                  <img
                                    src="../../images/midform-3dots.svg"
                                    alt="midform-3dots"
                                  />
                                </button>
                                <div className="midform-field-action-wrap bg-[#EEEEEE] rounded-[4px] flex items-center justify-center">
                                  <button className="midform-field-action midform-field-action-fixed px-[6px] py-[4px] rounded-[4px] text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal]">
                                    Fixed
                                  </button>
                                  <button className="midform-field-action midform-field-action-express px-[6px] py-[4px] rounded-[4px] text-[rgba(53,53,53,0.7)] font-['inter-regular'] text-[10px] not-italic font-normal leading-[normal]">
                                    Expression
                                  </button>
                                </div>
                              </div> */}
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          HTTP Method
                                        </label>
                                        <Select
                                          name="method"
                                          className="midform-select-main"
                                          classNamePrefix="midform-select"
                                          /*  defaultValue={{ value: values.method, label: values.method }} */
                                          defaultValue={options.find(
                                            (option) => option.value === values.method
                                          )}
                                          onChange={(option) => {
                                            setFieldValue("method", option.value)
                                            let data = { ...webhookData, method: option.value }
                                            setWebhookData(data)
                                            props.data.controls.method.value = option.value
                                          }}
                                          options={options}
                                        />
                                        <ErrorMessage name="method" className="error" component="div" />
                                      </div>
                                      <div className="midform-field mb-[30px]">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          Path
                                        </label>
                                        <input
                                          value={`${values.path}`}
                                          onChange={(e) => {
                                            handleChange(e)
                                            props.data.controls.path.value = e.target.value
                                          }}
                                          name="path"
                                          type="text"
                                          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                                        />
                                        <ErrorMessage name="path" className="error" component="div" />
                                      </div>
                                      {/* {values.method !== "GET" && (
                              <div className="midform-field mb-[30px]">
                                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                  Body
                                </label>
                                <JSONInputWrapper
                                  fieldName={"body"}
                                  setFieldValue={setFieldValue}
                                  jsonData={values.body}
                                />
                                <ErrorMessage name="path" className="error" component="div" />
                              </div>
                            )} */}
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                                <div className="node-table-view-wrap">
                                  <div className="node-table-main relative overflow-x-auto w-full min-h-[calc(100vh_-_233px)]">

                                    <div className="midform-fields">
                                      <div className="midform-field mb-[30px] relative">
                                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                          Node description
                                        </label>
                                        <input
                                          type="number"
                                          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                        />
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </TabPanel>
                            </Tabs>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="node-popup-view node-popup-output-view w-[calc(50%_-_246px)]">
                  <div className="node-popup-output-view-inn">
                    <div className="node-popup-output-tabs">
                      <Tabs
                        selectedIndex={tabIndexOutput}
                        onSelect={(index) => HandelOnselectTab(index)}
                      >
                        <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                          <div className="node-popup-output-title text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">
                            Output
                          </div>
                          <TabList className="flex items-center justify-end">
                            {!editOutputJson ? (
                              <>
                                <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                  <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    Table
                                  </Tab>
                                  <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    JSON
                                  </Tab>
                                  <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                    Schema
                                  </Tab>
                                </div>
                                <button
                                  className="[transition:0.3s_all_ease] flex items-center justify-center ml-[8px] rounded-[8px] bg-[#fff] w-[36px] h-[36px] border-[1px] border-[solid] border-[#D1D4D9] hover:border-[#74757D]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node_inn_edit.svg"
                                    alt="node_inn_edit"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                  onClick={handelJsonedit}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                  onClick={handelSaveOutputJson}
                                  disabled={!isValidJson}
                                >
                                  Save
                                </button>
                              </>
                            )}
                          </TabList>
                        </div>
                        <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                          <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                            <div className="node-table-view-wrap">
                              <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                <DynamicTable data={outputData} />
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel
                            className={`node-popup-output-tab-panel node-json-view  webhook-node-json-view h-[calc(100vh_-_298px)] ${editOutputJson ? "" : "node-json-view-readonly"
                              }`}
                          >
                            <JSONInput
                              id="webhook-json"
                              placeholder={outputData}
                              locale={locale}
                              theme="light_mitsuketa_tribute"
                              colors={{
                                default: "#39ADB5",
                                keys: "#E53935",
                                string: "#91B859",
                                number: "#F76D47",
                                background: "#F7F7F7",
                              }}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                outerBox: {
                                  borderRadius: "6px",
                                  border: "1px solid #CCC",
                                  overflow: "hidden",
                                  background: "#F7F7F7",
                                },
                              }}
                              onChange={handleJsonChange}
                            />
                          </TabPanel>
                          <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                            <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                              <DndProvider backend={HTML5Backend}>
                                {outputData.map((item, index) => (
                                  <RecursiveComponent
                                    key={index}
                                    item={item}
                                    onFieldDrop={handleDrop}
                                    draggable={false}
                                  />
                                ))}
                              </DndProvider>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>

      <NodeStyles
        selected={selected}
        width={280}
        height={61}
        className={`nodeWrap node-comm node-webhook ${isNodeEnable ? "" : "disabled-node"
          } `}
        data-testid="node"
      >
        <div
          className={`title ${isNodeEnable ? "" : "disabled-node"}`}
          data-testid="title"
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
        >
          <img src="../../images/trigger_indicator.svg" alt="trigger_indicator" className="absolute -top-[12px] right-[8px] z-0" />
          <div className="node-block-parent">
            <div className="node-block flex items-center justify-center">
              <div className="node-block-actions absolute top-[100%] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pt-[17px] [cursor:initial]">
              <div className="node-block-action relative">
                <button
                  onClick={handelEnableDisableNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_run.svg"
                    alt="node_main_action_run"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Run</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelCopyNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_copy.svg"
                    alt="node_main_action_copy"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Copy</span>
                </div>
                <div className="node-block-action relative">
                <button
                  onClick={handelDeleteNode}
                  className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_delete.svg"
                    alt="node_main_action_delete"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Delete</span>
                </div>
                <div className="node-block-action relative">
                <button
                  className="cursor-pointer bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_dots.svg"
                    alt="node_main_action_dots"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">More</span>
                </div>
              </div>
              <div className="node-block-icon-parent w-[36px] h-[36px] flex items-center justify-start mr-[8px]">
                <img
                  className="node-block-icon"
                  src="../../images/webhook_node_main_icon.svg"
                  alt="webhook_node_main_icon"
                />
              </div>
              <div className="node-block_text">
                <span className="node-block-title block text-[#30313D] font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]" title={props.data.label}>
                  {props.data.label}
                </span>
                <span className="node-block-desc block text-[#74757D] font-[inter-medium] text-[12px] not-italic font-normal leading-[14.52px] mt-[3px]">
                  Webhook node desc text
                </span>
              </div>
            </div>
          </div>
        </div>

        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
                {/* <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
                  <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
                    <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
                    <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] absolute left-[0] right-[0] m-auto block">
                      <span className="bg-[#F2F5F7] px-[3px] py-[0] uppercase">{webhookData.method=='GET-1'?'Head':webhookData.method}</span>
                    </div>
                  </div>
                  <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
                    <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
                  </button>
                </div> */}
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
