import * as React from "react";
import { css } from "styled-components";
import { ClassicScheme, Presets } from "rete-react-plugin";

const { Connection } = Presets.classic;

const styles = css`
  strokeWidth: 2px;
  stroke: #919394;  
  // animation: dash 1s linear infinite;
  // stroke-dashoffset: 90;  
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export function ActionConnectionComponent(props: {data: ClassicScheme["Connection"] & { isLoop?: boolean };}) {
  return <Connection {...props} styles={() => styles} />;
}
