import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Space, Tabs, message } from "antd";
import {
  copyNodeWithConnections,
  copyNodeWithConnectionsCopy,
  createEditor,
  hasInputs,
  IsEntryNode,
} from "../editor";
import Select from "react-select";
import { useRete } from "rete-react-plugin";
import Popup from "reactjs-popup";
import DotsDropdown from "../components/DotsDropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonLayout from "../components/view/common-layout";
import createAPI from "../api";
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import { importGraph } from "../services/import-export";
import { hasWebhookNode, URLS } from "../utils";  
import * as React from "react";
import HttpNodeCustomNode from "../customization/HttpnodecustomNode";
 
export interface VisibilityState {
  isVisible: boolean;
}

export interface RootState {
  visibility: VisibilityState;
}
export default function Canvas() {
  const API = createAPI();
  const params = useParams(); // Get the :id parameter from the URL
  const [workFlowId, setWorkFlowId] = useState(params.id);
  const workFlowIdRef = useRef(workFlowId);
  const workFlowNameRef = useRef("WorkFlow");

  const [showpopUp, setShowpopUp] = useState(false);
  const [showEntryNodeError, setShowEntryNodeError] = useState(true);
  const [showNodes, setShowNodes] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [modules, setModules] = useState<string[]>([]);
  const [isPopupVisable, setIsPopupVisable] = useState(false);
  const [editWorkFlowName, setEditWorkFlowName] = useState(false);
  const [workFlowName, setWorkFlowName] = useState("WorkFlow");
  const [isChanged, setIsChanged] = useState(false);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  function toggleNodes() {
    setShowNodes((prev) => !prev);
  }

  const closeModal = () => {
    setShowpopUp(false);
  };
  const updateWorkFlowId = (newId) => {
    setWorkFlowId(newId);
    workFlowIdRef.current = newId;
  };
  const updateWorkFlowName = (name) => {
    setWorkFlowName(name);
    workFlowNameRef.current = name;
  };
  // Ensure that createEditor is not calling any hooks internally
  const create = useCallback(
    (el: HTMLElement) => {
      return createEditor(el, messageApi.error);
    },
    [messageApi]
  );

  // Ensure useRete is called at the top level
  const [ref, editor] = useRete(create);

  function checkInputs(arrayOfObjects) {
    // Helper function to determine if an object is empty
    const isEmpty = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Iterate over each object in the array
    for (let obj of arrayOfObjects) {
      // Check if the 'inputs' property exists and is not empty
      if (obj.inputs && !isEmpty(obj.inputs)) {
        return true; // Found a non-empty 'inputs', return true
      }
    }

    // If no non-empty 'inputs' are found, show an error or return false
    console.error("No data with non-empty inputs found.");
    return false;
  } 
  useEffect(() => {
    if (editor) {
      console.log(editor);

      // Adding pipes to handle node creation and checking for entry nodes
      editor.editor.addPipe(async (context) => {
        console.log("context.type", context);
        setIsChanged(true);
        return context;
      });

      editor.editor.addPipe(async (context) => {
        if (context.type === "nodecreate") {
          let hasEntryPoint = true;
          editor.editor.getNodes().forEach((val) => {
            if (val?.isEntryNode) {
              hasEntryPoint = false;
            }
          });

          if (!editor.editor.getNodes().length && IsEntryNode(context.data)) {
            toast("To Enable Workflow, please add an entry Node", {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
            setShowEntryNodeError(false);
          } else if (IsEntryNode(context.data) && hasEntryPoint) {
            toast("To Enable Workflow, please add an entry Node", {
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
            });
            setShowEntryNodeError(false);
          }

          return context;
        }

        return context;
      });
      if (workFlowId != null) {
        API.get(`rete/get-workflow/${workFlowId}`, {
          headers: {
            "Requires-Auth": true, // This tells the interceptor to add the auth token
          },
        }).then(async (data) => {
          //setWorkFlowName(data.data.title)
          console.log("here is the title");
          console.log(data.data);
          setWorkFlowName((prevId) => {
            return data.data.workFlows.title;
          });
          updateWorkFlowName(data.data.workFlows.title);
          editor.importNodeByid(data.data.workFlows.rete);
          console.log(data.data.workFlows.rete.nodes);
        });
      } else {
        let schudleNode = editor.nodeContext[0];
        Addnodes(schudleNode, 0);
      }
    }
  }, [editor]);

  const options2 = [
    { value: "t1", label: "option1", no: 1 },
    { value: "t2", label: "option2", no: 1 },
    { value: "t3", label: "option3", no: 1 },
  ];

  async function Addnodes(node, index) {
    node.position = [100, index * 100];
    await editor.editor.addNode(node);
  }
  const [isActive, setIsActive] = useState(false);
  const [isSideClosed, setIsSideClosed] = useState(true);

  const sideHandleHover = () => {
    // Remove the side_close class immediately when the mouse enters
    setIsSideClosed(false);

    // Set the active class after a delay if needed
    setTimeout(() => {
      setIsActive(true);
    }, 100);
  };

  const sideHandleMouseLeave = () => {
    // Remove the active class immediately
    setIsActive(false);

    // Add the side_close class after 5 seconds
    setTimeout(() => {
      setIsSideClosed(true);
    }, 100); // 5 seconds
  };

  const sideHandleCloseClick = () => {
    // Reset both isActive and isSideClosed when the close button is clicked
    setIsActive(false);
    setIsSideClosed(false);
  };

  const handelSaveWorkFlow = async () => {
    let exportData = await editor?.saveModule(workFlowName);
    console.log(JSON.stringify(exportData));
    let webHookNode = hasWebhookNode(exportData);
    let workflowIdparam;
    if (workFlowId == undefined) {
      workflowIdparam = workFlowIdRef.current;
    } else {
      workflowIdparam = workFlowId;
    }
    setIsFormSubmited(true);
    let apiData = API.post(
      `rete/save/${workflowIdparam}`,
      { exportData: exportData, webHookNode },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    )
      .then((data) => {
        console.log(data.data.data.id);
        /* setWorkFlowId(data.data.data.id) */
        updateWorkFlowName(data.data.data.title);
        console.log(workFlowIdRef);
        setWorkFlowId((prevId) => {
          const newId = data.data.data.id;

          return newId;
        });
        setIsFormSubmited(false);
        //setId()
        toast("WorkFlow save successfully", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      })
      .catch((err) => {
        setIsFormSubmited(false);
        toast(err.message, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
      });
  };

  const topBarOptions = [
    {
      imageSrc: "../../images/drop_edit.svg",
      label: "Edit",
      onClick: () => {
        console.log("Edit clicked");
      },
    },
    {
      imageSrc: "../../images/drop_rename.svg",
      label: "Rename",
      onClick: () => {
        console.log("Rename clicked");
      },
    },
    {
      imageSrc: "../../images/drop_duplicate.svg",
      label: "Copy",
      onClick: () => {
        console.log("Copy");
      },
    },
    {
      imageSrc: "../../images/drop_delete.svg",
      label: "Delete",
      onClick: () => {
        console.log("Delete clicked");
      },
    },
  ];
  const topBarSrc = "../../images/topbar_more.svg";
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isChanged) {
        console.log("Change ");
        event.preventDefault();
        //    event.returnValue = ""; // This is required for most browsers to show the confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChanged]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [confirmNavigate, setConfirmNavigate] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // Handle browser refresh or close

  useEffect(() => {
    if (confirmNavigate) {
      setIsModalVisible(false);
      setIsChanged(false);
      navigate(nextLocation); // Proceed with navigation
    }
  }, [confirmNavigate, nextLocation, navigate]);
  useEffect(() => {
    const unblock = () => {
      if (isChanged) {
        setIsModalVisible(true);
        setNextLocation(location.pathname);
      }
    };

   
    return () =>{
      unblock();
    }
  }, [location, isChanged]);

  const handleConfirmNavigation =async () => {
    
    let exportData = await editor?.saveModule(workFlowName);
    console.log(JSON.stringify(exportData));
    let webHookNode = hasWebhookNode(exportData);
    let workflowIdparam;
    if (workFlowId == undefined) {
      workflowIdparam = workFlowIdRef.current;
    } else {
      workflowIdparam = workFlowId;
    }
    setIsFormSubmited(true);
    let apiData = API.post(
      `rete/save/${workflowIdparam}`,
      { exportData: exportData, webHookNode },
      {
        headers: {
          "Requires-Auth": true, // This tells the interceptor to add the auth token
        },
      }
    )
      .then((data) => {
        console.log(data.data.data.id);
        /* setWorkFlowId(data.data.data.id) */
        updateWorkFlowName(data.data.data.title);
        console.log(workFlowIdRef);
        setWorkFlowId((prevId) => {
          const newId = data.data.data.id;

          return newId;
        });
        setIsFormSubmited(false);
        //setId()
        toast("WorkFlow save successfully", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        blocker.proceed()
    setConfirmNavigate(true);
      })
      .catch((err) => {
        setIsFormSubmited(false);
        toast(err.message, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        blocker.reset()
      });
  };

  const handleCancelNavigation = () => {
    blocker.proceed()
    setIsModalVisible(false);
    setNextLocation(null); // Reset the next location
  };
  
  //usePreventLinkClick(true, 'You have unsaved changes. Are you sure you want to leave?');
  let shouldBlock = React.useCallback(
    ({ currentLocation, nextLocation }) =>  isChanged && currentLocation.pathname !== nextLocation.pathname,
 

    [isChanged]
  );
  let blocker = useBlocker(shouldBlock);
  React.useEffect(() => {
    setIsModalVisible(true)
    if (blocker.state === "blocked" && !isChanged) {
      
      blocker.reset();
    }
  }, [blocker, isChanged]);

  const [customNodes, setCustomNodes] = useState([]);
  useEffect(() => {
    API.get('custom-node/get', {
      headers: {
        "Requires-Auth": true, // This tells the interceptor to add the auth token
      },
    }).then(responce => {
      console.log(responce.data.getNodes      );
     setCustomNodes(responce.data.getNodes);
    }).catch(err => {
      console.error("Error fetching workflows:", err);
    });
  }, []);
 async function handelCustomNode(data){
  let node= new HttpNodeCustomNode(editor.dataflow, (data) => {}, editor.log, editor.editor,data)
  await editor.editor.addNode(node);
  }
  return (
    <CommonLayout>
      <div>
        {editor && (
          <div>
             
            <div className="execution-sidebar border-r [border-right-style:solid] [border-right-color:#BECBD8] bg-[#FFF] [box-shadow:-12px_15px_50px_0px_rgba(0,_0,_0,_0.13)] w-[356px] fixed bottom-[0] left-[0] h-[calc(100%_-_70px)] z-[1] py-[21px] pl-[37px] pr-[19px] hidden">
              <div className="execution-sidebar-head mb-[29px]">
                <div className="execution-sidebar-head-title text-[#60809F] font-[inter-medium] text-[18px] not-italic font-normal leading-[normal]">
                  Executions
                </div>
                <div className="execution-sidebar-head-actions flex items-end justify-between">
                  <div className="execution-sidebar-head-action pb-[7px]">
                    <div className="execution-sidebar-head-checkbox flex items-center justify-start comm-custom-checkbox-parent">
                      <div className="execution-sidebar-head-checkbox-inn mr-[10px] relative leading-[0] comm-custom-checkbox">
                        <input
                          type="checkbox"
                          className="comm-custom-checkbox-input w-[20px] h-[20px] absolute top-[0] left-[0] opacity-0 cursor-pointer z-[1]"
                        />
                        <span className="comm-custom-checkbox-span border border-solid border-[#A5ADBA] rounded-[3px] w-[20px] h-[20px] block"></span>
                      </div>
                      <span className="comm-custom-checkbox-text text-[#60809F] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal]">
                        Auto Refresh
                      </span>
                    </div>
                  </div>
                  <div className="execution-sidebar-head-action w-[116px]">
                    <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                      Status
                    </label>
                    <Select
                      classNamePrefix="midform-select"
                      options={options2}
                    />
                  </div>
                </div>
              </div>
              <div className="execution-sidebar-body h-[calc(100vh_-_230px)] overflow-auto">
                <div className="execution-sidebar-items">
                  <div className="execution-sidebar-item execution-sidebar-item-pass relative py-[10px] px-[28px] cursor-pointer hover:bg-[#ecf0f4]">
                    <div className="execution-sidebar-rod execution-sidebar-rod-pass w-[5px] h-full bg-[#3EE092] absolute top-[0] left-[0]"></div>
                    <div className="execution-sidebar-item-inn">
                      <span className="block text-[#60809F] font-[inter-regular] text-[16px] not-italic font-normal leading-[normal] opacity-80 mb-[3px]">
                        14 May at 12:41:03
                      </span>
                      <span className="block text-[#60809F] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-80">
                        Succeeded in 0.019s
                      </span>
                    </div>
                  </div>
                  <div className="execution-sidebar-item execution-sidebar-item-fail relative py-[10px] px-[28px] cursor-pointer hover:bg-[#ecf0f4]">
                    <div className="execution-sidebar-rod execution-sidebar-rod-fail w-[5px] h-full bg-[#F04E61] absolute top-[0] left-[0] flex items-center justify-between"></div>
                    <div className="execution-sidebar-item-inn">
                      <span className="block text-[#60809F] font-[inter-regular] text-[16px] not-italic font-normal leading-[normal] opacity-80 mb-[3px]">
                        14 May at 12:41:03
                      </span>
                      <span className="block text-[#60809F] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-80">
                        Failed in 0.019s
                      </span>
                    </div>
                    <div className="re-execute-node w-[30px] h-[30px] cursor-pointer flex items-center justify-center">
                      <img
                        src="../../images/re-execute-node.svg"
                        alt="re-execute-node"
                        className="w-[14px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="header fixed top-[0] left-[80px] w-[calc(100%_-_80px)] h-[52px] z-10 bg-[#fff] border-b [border-bottom-style:solid] [border-bottom-color:#DCE4E8] flex items-center justify-between pl-[40px] pr-[40px]">
              <div className="header-left flex items-center justify-start">
                <div className="cursor-pointer text-[#1A1C1E] font-[inter-bold] text-[18px] not-italic leading-[25.2px]">
                  {!editWorkFlowName && (
                    <div
                      onClick={() => setEditWorkFlowName((prv) => !prv)}
                      className="flex items-center justify-center"
                    >
                      {/* Display updated workFlowName if it exists, otherwise fall back to workFlowNameRef.current */}
                      {workFlowName || workFlowNameRef.current}
                      <img
                        src="../../images/node_inn_edit.svg"
                        alt="node_inn_edit"
                        className="ml-[5px] w-[18px] opacity-50"
                      />
                    </div>
                  )}
                  {editWorkFlowName && (
                    <div className="relative right-[11px]">
                      <input
                        className="relative border border-solid border-[#DCE4E8] rounded-[6px] h-[35px] px-[10px] py-[0] hover:border-[#146DE0] pr-[60px]"
                        type="text"
                        onChange={(e) => {
                          const newName = e.target.value;
                          if (newName.length > 60) {
                            // Limit the input length to 20 characters
                            //console.log("Maximum character length is 20");
                            return;
                          }
                          updateWorkFlowName(newName); // Update both the state and the ref
                        }}
                        value={workFlowName}
                      />
                      <button
                        disabled={isFormSubmited}
                        className="bg-[#146DE0] hover:bg-[#6093d5] focus:bg-[#0153bc] focus:border-[1px] border-[solid] border-[#0E4D9F] [transition:0.3s_all_ease] text-[#fff] [box-shadow:none] absolute top-[0] right-[5px] bottom-[0] m-auto h-[25px] w-[50px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]"
                        onClick={() => setEditWorkFlowName(false)}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="header-right flex items-center justify-end">
                <div className="workflow-switch node-comm-switch flex items-center justify-center">
                  <input
                    type="checkbox"
                    id="workflow-switch"
                    className="h-[0] w-[0] invisible"
                  />
                  <label
                    className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                    htmlFor="workflow-switch"
                  >
                    Toggle
                  </label>
                  <div className="switch-spans flex items-center justify-start w-[54px] ml-[7px]">
                    <span className="switch-span switch-span-1 text-[#30313D] text-right font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px]">
                      Inactive
                    </span>
                    <span className="switch-span switch-span-2 text-[#30313D] text-right font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px]">
                      Active
                    </span>
                  </div>
                </div>
                <button className="[transition:0.3s_all_ease] bg-[#E8F0FC] [box-shadow:none] ml-[24px] w-[36px] h-[36px] rounded-[6.75px] flex items-center justify-center">
                  <img src="../../images/header_share.svg" alt="header_share" />
                </button>
                <button
                  className="primary_btn_hover bg-[#146DE0] hover:bg-[#6093d5] focus:bg-[#0153bc] focus:border-[1px] border-[solid] border-[#0E4D9F] [transition:0.3s_all_ease] text-[#fff] [box-shadow:none] ml-[12px] w-[100px] h-[36px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
                  onClick={async () => {
                    handelSaveWorkFlow();
                  }}
                >
                  Save
                </button>
                <button className="relative z-[11] text-[#1A1C1E] border-[#DCE4E8] bg-[#fff] fill-[#292D32] hover:text-[#fff] hover:bg-[#eeeeee] hover:fill-[#fff] [box-shadow:none] ml-[12px] w-[36px] h-[36px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[16px] not-italic font-normal leading-[normal] border-[1px] border-solid flex items-center justify-center">
                  <DotsDropdown options={topBarOptions} iconSrc={topBarSrc} />
                </button>
                {/*  <button
                  className="primary_btn_hover bg-[#146DE0] hover:bg-[#6093d5] focus:bg-[#0153bc] focus:border-[1px] border-[solid] border-[#0E4D9F] [transition:0.3s_all_ease] text-[#FFF] h-[40px] rounded-[6px] !py-[0] fixed bottom-[25px] left-[0] right-[0] flex items-center justify-center max-w-[147px] mx-[auto] my-[0] stroke-[#fff]"
                  onClick={() => editor?.runNodes()}
                >
                  <span className="font-[inter-medium] text-[14px] not-italic font-normal leading-[16px]">
                    Test Workflow
                  </span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-[8px]"
                  >
                    <path
                      d="M6.0901 13.2809H9.1801V20.4809C9.1801 22.1609 10.0901 22.5009 11.2001 21.2409L18.7701 12.6409C19.7001 11.5909 19.3101 10.7209 17.9001 10.7209H14.8101V3.52087C14.8101 1.84087 13.9001 1.50087 12.7901 2.76087L5.2201 11.3609C4.3001 12.4209 4.6901 13.2809 6.0901 13.2809Z"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button> */}
              </div>
              <div className="workflow-view-actions w-[177px] absolute left-[0] right-[0] m-auto -bottom-[18px] flex items-center justify-center">
                <div className="workflow-view-actions-inn p-[3px] bg-[#FFF] border-[1px] border-solid border-[#DCE4E8] rounded-[8px] flex items-center justify-center">
                  <button className="active border-0 !py-0 h-[28px] min-w-[79px] [box-shadow:none] rounded-[6px] text-[#1A1C1E] text-center font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]">
                    Editor
                  </button>
                  <button className="ml-[3px] border-0 !py-0 h-[28px] min-w-[87px] [box-shadow:none] rounded-[6px] text-[#1A1C1E] text-center font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]">
                    Executions
                  </button>
                </div>
              </div>

              <div
                className={`workflow-sidebar absolute right-[40px] top-[82px] z-10 flex flex-col items-end ${
                  isActive ? "active" : ""
                } ${isSideClosed ? "side_close" : ""}`}
                onMouseLeave={sideHandleMouseLeave}
                onMouseEnter={sideHandleHover}
              >
                <button
                  onClick={toggleNodes}
                  onMouseEnter={sideHandleHover}
                  className="workflow-add-nodes-action z-[1] [transition:0.3s_all_ease] text-[#1A1C1E] border-[#DCE4E8] bg-[#fff] fill-[#146DE0] hover:fill-[#fff] hover:text-[#fff] hover:border-[#146DE0] hover:bg-[#146DE0] hover:fill-[#fff] flex items-center justify-center w-[44px] h-[44px] [box-shadow:none] rounded-[6px] border-[1px] border-solid"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.92411 2.92411C-0.974704 6.82293 -0.974704 13.1771 2.92411 17.0759C6.82293 20.9747 13.1771 20.9747 17.0759 17.0759C20.9747 13.1771 20.9747 6.82293 17.0759 2.92411C13.1771 -0.974704 6.82293 -0.974704 2.92411 2.92411ZM14.005 9.24996C14.4154 9.24996 14.755 9.5896 14.755 10C14.755 10.2123 14.6701 10.3962 14.5356 10.5307C14.4012 10.6651 14.2172 10.75 14.005 10.75H10.75V14.005C10.75 14.2172 10.6651 14.4012 10.5307 14.5356C10.3962 14.6701 10.2123 14.755 10 14.755C9.5896 14.755 9.24996 14.4154 9.24996 14.005V10.75H5.99505C5.58465 10.75 5.245 10.4104 5.245 10C5.245 9.5896 5.58465 9.24996 5.99505 9.24996H9.24996V5.99505C9.24996 5.58465 9.5896 5.245 10 5.245C10.4104 5.245 10.75 5.58465 10.75 5.99505V9.24996H14.005Z" />
                  </svg>
                </button>

                <div className="workflow-sidebar-main flex flex-col items-end justify-start">
                  <div
                    className={`workflow-sidebar-inn z-[0] h-[calc(100vh_-_102px)] w-[500px] bg-[#FFF] fixed right-[25px] top-[77px] rounded-[6px] overflow-hidden border-[1px] border-[solid] border-[#D1D4D9] [box-shadow:-3px_5px_4px_0_rgba(0,_0,_0,_0.03)]`}
                  >
                    <div className="workflow-sidebar-header bg-[#E8F0FC] px-[20px] flex items-center justify-between h-[65px] border-b [border-bottom-style:solid] [border-bottom-color:#D1D4D9]">
                      <div className="global-popup-head-title text-[#30313D] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[25.2px]">
                        What happen next?
                      </div>
                      <button
                        className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                        onClick={sideHandleCloseClick}
                      >
                        <img
                          src="../../images/close-popup.svg"
                          alt="popup-close"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="workflow-sidebar-wrap w-[500px] relative -right-[15px] top-[17px]">
                    <div className="workflow-sidebar-search px-[20px] pt-[24px] pb-[20px]">
                      <div className="workflow-sidebar-search-inn flex align-center justify-start border [border-style:solid] [border-color:#D1D4D9] rounded-[6px] overflow-hidden relative">
                        <img
                          src="../../images/search-workflow-sidebar.svg"
                          alt="search-workflow-sidebar"
                          width={16}
                          className="absolute top-2/4 -translate-y-1/2 left-[14px]"
                        />
                        <input
                          type="text"
                          placeholder="Search..."
                          className="h-[40px] text-[14px] w-full px-[40px] font-[inter-medium]"
                        />
                      </div>
                    </div>
                    <div className="workflow-sidebar-nodes-parent">
                      <div className="workflow-sidebar-nodes-wrap px-[20px] pt-[0] pb-[20px] overflow-auto h-[calc(100vh_-_255px)]">
                        <div className="workflow-sidebar-nodes-container">
                          <div className="workflow-sidebar-nodes-container-title text-[#30313D] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px] mb-[14px]">
                            Core Nodes
                          </div>
                          {editor.nodeContext.map((val, index) => {
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  Addnodes(val, index);
                                }}
                                className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[15px] border border-solid border-[#D1D4D9] hover:border-[#146DE0] rounded-[12px] mb-[12px]"
                              >
                                <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[68px]">
                                  <div className="workflow-sidebar-icn-wrap w-[36px] h-[40px] flex items-center justify-center mr-[14px] rounded-[100%] relative">
                                    <img
                                      src={val.nodeData.imgSrc}
                                      alt="sidebar-icn"
                                      className="relative z-[1]"
                                      width={32}
                                    />
                                    {/* <div className="workflow-sidebar-icn-hover-bg bg-[#146DE0] absolute w-full h-full opacity-0 rounded-[100%] z-0 top-[0] left-[0] [transition:0.3s_opacity_ease]"></div> */}
                                  </div>
                                  <div className="workflow-sidebar-item-text">
                                    <span className="block text-left text-[#30313D] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">
                                      {val.nodeData.name}
                                    </span>
                                    <span className="block text-left text-[#74757D] font-['inter-medium'] text-[14px] not-italic font-normal leading-[19.6px] mt-[4px]">
                                      {val.nodeData.description}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            );
                          })}
                        </div>
                        <div className="workflow-sidebar-nodes-container">
                          <div className="workflow-sidebar-nodes-container-title text-[#30313D] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px] mt-[20px] mb-[14px]">
                            Others
                          </div>

                          {customNodes.map((val)=>(
                            <button type="button" onClick={()=>{handelCustomNode(val);} } className="workflow-sidebar-item flex [transition:0.3s_all_ease] items-center justify-start w-full px-[15px] border border-solid border-[#D1D4D9] hover:border-[#146DE0] rounded-[12px] mb-[12px]">
                            <div className="workflow-sidebar-item-inn flex items-center justify-start w-full h-[68px]">
                              <div className="workflow-sidebar-icn-wrap w-[36px] h-[40px] flex items-center justify-center mr-[14px] rounded-[100%] relative">
                                <img
                                  src={URLS.CLOUD_FRONT+''+val.nodeIcon}
                                  alt={val.name}
                                  className="relative z-[1]"
                                  width={32}
                                />
                              </div>
                              <div className="workflow-sidebar-item-text">
                                <span className="block text-left text-[#30313D] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[22.4px]">
                                  {val.name}
                                </span>
                              </div>
                            </div>
                          </button>))}
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="canvas_zoom_actions fixed bottom-[30px] left-[110px] bg-[#fff] flex flex-col justify-center items-center z-[1] rounded-[6px] overflow-hidden border [border-style: solid] [border-color: #D1D4D9]">
                <button className="canvas_zoom_action canvas_zoom_in w-[36px] h-[40px] flex justify-center items-center border-b [border-bottom-style: solid] [border-bottom-color: #D1D4D9]">
                  <img src="../../images/canvas_plus.svg" alt="canvas_plus" />
                </button>
                <button className="canvas_zoom_action canvas_zoom_out w-[36px] h-[40px] flex justify-center items-center">
                  <img src="../../images/canvas_minus.svg" alt="canvas_minus" />
                </button>
              </div>
            </div>
          </div>
        )}
        {contextHolder}
        <div ref={ref} style={{ height: "100vh", width: "100vw" }}></div>
        <Popup
          open={blocker.state=='blocked'?true:false}
          className="custom-modal"
          onClose={(val)=>{setIsModalVisible(false)}}
        >
         <div className="confirmation-popup  z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
         <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
          <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">Confirm Action</div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={(val)=>{setIsModalVisible(false)}}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
          
          </div>
          <div className="p-[30px]">
            <p className="text-[#000] font-['inter-regular'] text-[14px] not-italic font-normal leading-[normal] mb-[40px] mt-[10px] text-[16px]"> Changes you made may not be saved. Are you sure you want to leave?</p>
            <div className="form_comm_actions flex items-center justify-center">
            <button className="w-[150px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]" onClick={handleCancelNavigation}>Leave</button> 
            <button className="min-w-[150px] py-2 px-4 rounded text-white [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] flex items-center justify-center" onClick={handleConfirmNavigation}>Save & Leave</button>            
            </div>
            </div>
          </div>
        </div>
        
      </Popup> 
      </div>
    </CommonLayout>
  );
}
