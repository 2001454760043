import React from 'react';
import Select from 'react-select'; 
import CustomSelectIconOption from '../CustomSelectIconOption';
import CustomSelectIconValue from '../CustomSelectIconValue';

const AppDropdown = ({ items, selectedOption, onChange,placeholder="Search for app..." }) => (
  <Select
    className="midform-select-main add_credentials_dropdown"
    classNamePrefix="midform-select"
    value={selectedOption}
    onChange={onChange}
    options={items}
    placeholder={placeholder}
    components={{
      Option: CustomSelectIconOption,
      SingleValue: CustomSelectIconValue,
    }}
  />
);

export default AppDropdown;
