import { FieldArray, ErrorMessage } from 'formik';
import React, { useEffect } from 'react';

const DynamicKeyValueFields = ({ formikInstance, fieldName, data = [], userMode = false }) => {
  useEffect(() => {
    // If data is empty, skip initialization
    if (!data || data.length === 0) return;

    // Initialize values for each item in data array
    data.forEach((param, index) => {
      const fieldPath = `${fieldName}.${index}`;
      const currentField = formikInstance.values[fieldName] && formikInstance.values[fieldName][index];

      // Set default values if not present in data or formikInstance
      if (!currentField) {
        formikInstance.setFieldValue(fieldPath, {
          key: param.key || '',
          value: param.value || '',
          required: param.required !== undefined ? param.required : false,
          behavior: param.behavior || 'default',
        });
      } else {
        if (currentField.required === undefined && param.required === undefined) {
          formikInstance.setFieldValue(`${fieldPath}.required`, false);
        }
        if (!currentField.behavior && param.behavior === undefined) {
          formikInstance.setFieldValue(`${fieldPath}.behavior`, 'default');
        }
      }
    });
  }, [data, fieldName, formikInstance]);

  // Return empty state if data is undefined or empty
  if (!data || data.length === 0) {
    return <p className="mt-[5px] text-[#74757D] font-[inter-regular] text-[12px] leading-[16.8px]">No data available to display fields.</p>;
  }

  return (
    <>
      {data.map((param, index) => {
        const valueFieldName = `${fieldName}.${index}.value`;
        const behaviorFieldName = `${fieldName}.${index}.behavior`;
        const requiredFieldName = `${fieldName}.${index}.required`;

        const handleBehaviorChange = (e) => {
          const { value } = e.target;
          formikInstance.setFieldValue(behaviorFieldName, value);

          if (value === 'blank') {
            formikInstance.setFieldValue(valueFieldName, '');
          } else if (value === 'fixed') {
            formikInstance.setFieldValue(valueFieldName, param.value);
          }
        };

        return (
          <div key={index} className="accord_field pl-[40px] py-[15px]">
            
              <div className="flex items-center justify-start w-full h-[36px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#D1D4D9] bg-[#F0F1F3] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]">
                <span className="font-[inter-bold]">{formikInstance.values[fieldName][index]?.key || ''}&nbsp;&bull;&nbsp;</span><span className="capitalize">{formikInstance.values[fieldName][index]?.behavior}</span>
              </div>
              <input
                type="text"
                name={`${fieldName}.${index}.key`}
                value={formikInstance.values[fieldName][index]?.key || ''}
                readOnly
                className=" hidden w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#D1D4D9] bg-[#F0F1F3] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
              />
            
            <label className="midform-field-label block text-[#74757D] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px] mt-[10px]">
              Value
            </label>
            <div className="relative">
              <input
                type="text"
                name={valueFieldName}
                placeholder="Enter value"
                value={formikInstance.values[fieldName][index]?.value || ''}
                readOnly={formikInstance.values[fieldName][index]?.behavior === 'fixed'}
                onChange={(e) => formikInstance.setFieldValue(valueFieldName, e.target.value)}
                className="w-full h-[40px] pl-[14px] pr-[45px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
              />
             {formikInstance.values[fieldName][index]?.behavior === 'fixed'&& ( <img src='../../../images/radio-lock.svg' className='absolute top-[0] bottom-[0] right-[15px] m-auto'  />)}
              {/* Display custom error message */}
              {formikInstance.errors[fieldName] &&
                formikInstance.errors[fieldName][index]?.value && (
                  <div className="text-red-500 text-xs mt-1">
                    {formikInstance.errors[fieldName][index].value}
                  </div>
                )}
            </div>
            <div className="save_as_check_radio flex items-center justify-between mt-[15px]">
            {/* Conditionally render "Required" checkbox based on userMode */}
            {!userMode && (
              
                  
                  <div className="save_as_check_action flex items-center justify-center">
                  <div className="save_as_check_action-input flex items-center justify-center relative">
                  <input
                    type="checkbox"
                    name={requiredFieldName}
                    checked={formikInstance.values[fieldName][index]?.required || false}
                    onChange={(e) => formikInstance.setFieldValue(requiredFieldName, e.target.checked)}
                    className="absolute w-full h-full top-[0] left-[0] z-[1] cursor-pointer opacity-0"
                  />
                    <div className="save_as_check_action-input-inn flex items-center justify-center w-[16px] h-[16px] [transition:0.3s_all_ease] border-[1px] border-[solid] border-[#A0A0A6] rounded-[4px]">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.85063 0.214824C8.91267 0.149281 8.98672 0.0972131 9.06844 0.0616672C9.15016 0.0261214 9.23791 0.0078125 9.32655 0.0078125C9.41519 0.0078125 9.50293 0.0261214 9.58465 0.0616672C9.66637 0.0972131 9.74042 0.149281 9.80247 0.214824C10.0625 0.486843 10.0661 0.926403 9.81156 1.20313L4.43599 7.78241C4.37496 7.8518 4.3009 7.90757 4.21833 7.94631C4.13577 7.98504 4.04644 8.00593 3.95581 8.00769C3.86518 8.00945 3.77515 7.99205 3.69124 7.95655C3.60733 7.92106 3.5313 7.86821 3.46779 7.80124L0.196815 4.36947C0.0706642 4.23627 0 4.05716 0 3.87061C0 3.68406 0.0706642 3.50495 0.196815 3.37175C0.258858 3.30621 0.332907 3.25414 0.414627 3.21859C0.496347 3.18305 0.584095 3.16474 0.672734 3.16474C0.761373 3.16474 0.849121 3.18305 0.930841 3.21859C1.01256 3.25414 1.08661 3.30621 1.14865 3.37175L3.92325 6.28301L8.83245 0.235531C8.8381 0.228257 8.84417 0.221342 8.85063 0.214824Z" fill="white"/>
                      </svg>
                    </div>
                  </div>
                  <div className="save_as_check_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                    Required
                  </div>
                </div>

            )}

            {/* Conditionally render "Behavior" radio buttons based on userMode */}
            {!userMode && (
                  <div className="save_as_radio_actions flex items-center justify-end">
                  <div className="save_as_radio_action flex items-center justify-center mr-[10px]">
                  <div className="save_as_radio_action-input flex items-center justify-center relative">
                  <input
                    type="radio"
                    name={behaviorFieldName}
                    value="default"
                    checked={formikInstance.values[fieldName][index]?.behavior === 'default'}
                    onChange={handleBehaviorChange}
                    className="absolute w-full h-full top-[0] left-[0] z-[1] cursor-pointer opacity-0"
                  />
                      <div className="save_as_radio_action-input-inn flex items-center justify-center w-[16px] h-[16px] [transition:0.3s_all_ease] border-[1px] border-[solid] border-[#A0A0A6] rounded-[100%]">
                        <div className="save_as_radio_action-input-fill [transition:0.3s_all_ease] bg-[#146DE0] w-[10px] h-[10px] rounded-[100%] opacity-0"></div>
                      </div>
                    </div>
                    <div className="save_as_radio_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                    Default
                    </div>
                  </div>
                  <div className="save_as_radio_action flex items-center justify-center mr-[10px]">
                  <div className="save_as_radio_action-input flex items-center justify-center relative">
                  <input
                    type="radio"
                    name={behaviorFieldName}
                    value="blank"
                    checked={formikInstance.values[fieldName][index]?.behavior === 'blank'}
                    onChange={handleBehaviorChange}
                    className="absolute w-full h-full top-[0] left-[0] z-[1] cursor-pointer opacity-0"
                  />
                      <div className="save_as_radio_action-input-inn flex items-center justify-center w-[16px] h-[16px] [transition:0.3s_all_ease] border-[1px] border-[solid] border-[#A0A0A6] rounded-[100%]">
                        <div className="save_as_radio_action-input-fill [transition:0.3s_all_ease] bg-[#146DE0] w-[10px] h-[10px] rounded-[100%] opacity-0"></div>
                      </div>
                    </div>
                    <div className="save_as_radio_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                    Blank
                    </div>
                  </div>
                  <div className="save_as_radio_action flex items-center justify-center">
                  <div className="save_as_radio_action-input flex items-center justify-center relative">
                  <input
                    type="radio"
                    name={behaviorFieldName}
                    value="fixed"
                    checked={formikInstance.values[fieldName][index]?.behavior === 'fixed'}
                    onChange={handleBehaviorChange}
                    className="absolute w-full h-full top-[0] left-[0] z-[1] cursor-pointer opacity-0"
                  />
                      <div className="save_as_radio_action-input-inn flex items-center justify-center w-[16px] h-[16px] [transition:0.3s_all_ease] border-[1px] border-[solid] border-[#A0A0A6] rounded-[100%]">
                        <div className="save_as_radio_action-input-fill [transition:0.3s_all_ease] bg-[#146DE0] w-[10px] h-[10px] rounded-[100%] opacity-0"></div>
                      </div>
                    </div>
                    <div className="save_as_radio_action-label text-[#30313D] font-[inter-medium] text-[14px] leading-[19.6px] ml-[9px]">
                    Fixed
                    </div>
                  </div>
                </div>
                  
                 
                  
                 
                  
                 
                  
            )}
          </div>
          </div>
        );
      })}
    </>
  );
};

export default DynamicKeyValueFields;
