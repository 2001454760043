import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'; 
import DraggableFieldDynamicInput from '../components/DraggableFieldDynamicInput';

const DynamicKeyValueInput = ({ onDataChanged, formik, fieldName, inputData }) => {
  const [inputFields, setInputFields] = useState([{ key: '', value: '' }]);
  const [isFirstTime, setFirstTime] = useState(true);
  const [tab, setTab] = useState("fixed");

  useEffect(() => {
    if (onDataChanged) {
    console.log('first',formik.values[fieldName])
      //setInputFields(formik.values[fieldName])
      validateArrayDetailed(inputFields);
      onDataChanged(inputFields);
      formik.setFieldValue(fieldName, inputFields);
    }
  }, [inputFields]);
  useEffect(() => {
    if (isFirstTime) {
    console.log('first',formik.values[fieldName])
      setInputFields(formik.values[fieldName])
      setFirstTime(false)
     
    }
  }, [isFirstTime]);

  const handleAddField = (event) => {
    event.stopPropagation();
    const newFields = [...inputFields, { key: '', value: '' }];
    setInputFields(newFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...inputFields];
    newFields.splice(index, 1);
    setInputFields(newFields);
  };

  const handleChangeInput = (index, event,isEditor=false,name='') => {
    console.log(event)
    if(isEditor){

      const newFields = [...inputFields];
      newFields[index][name] = event;
      setInputFields(newFields);
    }else{
      const newFields = [...inputFields];
      newFields[index][event.target.name] = event.target.value;
      setInputFields(newFields);
    }
  };
  /* const handleDrop = (item) => {
    setTab('expression');
    const fieldKey = item.fieldKey;
    const formattedFieldKey =
      fieldKey.includes(" ") || fieldKey.includes("-")
        ? `["${fieldKey}"]`
        : `.${fieldKey}`;
    const newValue = `{{ $json${formattedFieldKey} }}`;
    setDroppedValue(newValue);
  }; */
  const handleDrop = (item, index, targetType) => {
    const newFields = [...inputFields];
    const existingValue = newFields[index][targetType] || '';
    const formattedFieldKey = item.fieldKey
    .split('.')
      .map((key, index) => {
        if (!isNaN(key)) {
          // If the key is numeric, use bracket notation
          return `[${key}]`;
        }
        // For first key, just append with dot notation unless it's the root ($json)
        if (index === 0) {
          return `.${key}`;
        }
        // For other keys, use bracket notation if special characters exist
        return key.includes(" ") || key.includes("-") ? `["${key}"]` : `.${key}`;
      })
      .join('');
    const newValue = ` {{ $json${formattedFieldKey} }}`;
    newFields[index][targetType] = existingValue ? `${existingValue} ${newValue}` : newValue;
    setInputFields(newFields);
    //updateInputValues(newFields,newFields[index][targetType],index, targetType)
    setTab("expression");
  };

  const updateInputValues = (newFields,expression,index,targetType) => {
    const regex = /\{\{(.*?)\}\}/g; 
    const result = expression.replace(regex, (match, expression) => {
      const modifiedExpression = expression.replace(/\$json/g, "inputData[0]");

      try {
        const result = eval(modifiedExpression);
        console.log("jsdbvjhvbe", result !== expression);
        return result !== undefined && result !== expression ? String(result) : "Invalid syntax";
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`, error);
        return "Invalid syntax";
      }
    });
    console.log("result>>>>", result);
    newFields[index][targetType] = result;
  };
  function validateArrayDetailed(arr) {
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      if (obj.key === '' || obj.value === '') {
        console.log(`Validation failed for object at index ${i}:`, obj);
        return false;
      }
    }
    return true;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {inputFields.map((inputField, index) => (
          <DraggableFieldDynamicInput
            key={index}
            index={index}
            inputField={inputField}
            handleChangeInput={handleChangeInput}
            handleRemoveField={handleRemoveField}
            tab={tab}
            setTab={setTab}
            handleDrop={handleDrop}
            inputFields={inputFields}
            inputData={inputData}
          />
        ))}
        <div className="flex items-center justify-start mt-[20px]">
          <button type='button' className="midform-draggable-fields_initial-drag-input-btn text-center text-[#30313D] font-['inter-medium'] text-[14px] not-italic font-normal leading-[19.6px] border-[1px] border-[solid] border-[#D1D4D9] bg-[#F0F1F3] rounded-[6px] w-full h-[40px]" onPointerDown={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()} onClick={(e) => { handleAddField(e) }}>
            Add Parameter +
          </button>
        </div>
      </div>
    </DndProvider>
  );
};

export default DynamicKeyValueInput;
