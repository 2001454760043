import * as Yup from 'yup';

export const formConfigurations = {
    whatsAppBusiness: {
        fields: [
            {
                name: 'accessToken',
                label: 'Access Token',
                type: 'text',
                validation: Yup.string().required('Access Token is required'),
            },
            {
                name: 'bussinessAccountID',
                label: 'Bussiness Account ID',
                type: 'text',
                validation: Yup.string().required('Bussiness Account ID is required'),
            },
        ],
    },
    telegram: {
        fields: [
            {
                name: 'accessToken',
                label: 'Access Token',
                type: 'text',
                validation: Yup.string().required('Access Token is required'),
            },
            {
                name: 'baseurl',
                label: 'Base URL',
                type: 'text',
                validation: Yup.string().required('Base URL is required'),
                defaultValue: 'https://api.telegram.org'
            },
        ],
    },
    slack: {
        fields: [
            {
                name: 'accessToken',
                label: 'Access Token',
                type: 'text',
                validation: Yup.string().required('Access Token is required'),
            },
        ],
    },
    msTeams: {
        fields: [
            {
                name: 'oAuthRedirectURL',
                label: 'OAuth Redirect URL',
                type: 'text',
                validation: Yup.string().required('OAuth Redirect URL is required'),
                defaultValue: 'https://retenewra.zusedigital.com/rest/oauth2-credential/callback',
                readOnly: true,
                copyable: true,
            },
            {
                name: 'authorizationurl',
                label: 'Authorization URL',
                type: 'text',
                validation: Yup.string().required('Authorization URL is required'),
                defaultValue: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
            },
            {
                name: 'clientid',
                label: 'Client ID',
                type: 'text',
                validation: Yup.string().required('Client ID is required'),
            },
            {
                name: 'clientSecret',
                label: 'Client Secret',
                type: 'text',
                validation: Yup.string().required('Client Secret is required'),
            },
          /*    {
                 name: 'role',
                 label: 'Role',
                 type: 'select', // Type set to 'select'
                 options: [ // Options for the select input
                   { label: 'Admin', value: 'admin' },
                   { label: 'User', value: 'user' },
                   { label: 'Guest', value: 'guest' }
                 ],
                 validation: Yup.string().required('Role is required'),
                 defaultValue: 'user',
               }, */
        ],
    },
    intercom: {
        fields: [
            {
                name: 'apiKey',
                label: 'API Key',
                type: 'text',
                validation: Yup.string().required('API Key is required'),
            },
        ],
    },
    freshworkscrmaccount: {
        fields: [
            {
                name: 'apiKey',
                label: 'API Key',
                type: 'text',
                validation: Yup.string().required('API Key is required'),
                placeholder: 'BDsTn15vHezBlt_XGp3Tig',
            },
            {
                name: 'Domain',
                label: 'domain',
                type: 'text',
                validation: Yup.string().required('Domain is required'),
                placeholder: 'n8n-org',
            },
        ],
    },
    freshdesk: {
        fields: [
            {
                name: 'apiKey',
                label: 'API Key',
                type: 'text',
                validation: Yup.string().required('API Key is required'),
            },
            {
                name: 'Domain',
                label: 'domain',
                type: 'text',
                validation: Yup.string().required('Domain is required'),
                placeholder: 'company',
            },
        ],
    },
    wordpress: {
        fields: [
            {
                name: 'username',
                label: 'Username',
                type: 'text',
                validation: Yup.string().required('Username is required'),
            },
            {
                name: 'password',
                label: 'Password',
                type: 'Password',
                validation: Yup.string().required('Password is required'),
            },
            {
                name: 'wordpressurl',
                label: 'Wordpress URL',
                type: 'text',
                validation: Yup.string().required('Wordpress URL is required'),
                placeholder: 'https://example.com',
            },
        ],
    },
    googleDoc: {
        fields: [
            {
                name: 'oAuthRedirectURL',
                label: 'OAuth Redirect URL',
                type: 'text',
                validation: Yup.string().required('OAuth Redirect URL is required'),
                defaultValue: 'https://retenewra.zusedigital.com/rest/oauth2-credential/callback',
                readOnly: true,
                copyable: true,
            },
            {
                name: 'clientid',
                label: 'Client ID',
                type: 'text',
                validation: Yup.string().required('Client ID is required'),
            },
            {
                name: 'clientSecret',
                label: 'Client Secret',
                type: 'text',
                validation: Yup.string().required('Client Secret is required'),
            },
        ],
    },
    googleSheets: {
        fields: [
            {
                name: 'oAuthRedirectURL',
                label: 'OAuth Redirect URL',
                type: 'text',
                validation: Yup.string().required('OAuth Redirect URL is required'),
                defaultValue: 'https://retenewra.zusedigital.com/rest/oauth2-credential/callback',
                readOnly: true,
                copyable: true,
            },
            {
                name: 'clientid',
                label: 'Client ID',
                type: 'text',
                validation: Yup.string().required('Client ID is required'),
            },
            {
                name: 'clientSecret',
                label: 'Client Secret',
                type: 'text',
                validation: Yup.string().required('Client Secret is required'),
            },
        ],
    },
    youTube: {
        fields: [
            {
                name: 'oAuthRedirectURL',
                label: 'OAuth Redirect URL',
                type: 'text',
                validation: Yup.string().required('OAuth Redirect URL is required'),
                defaultValue: 'https://retenewra.zusedigital.com/rest/oauth2-credential/callback',
                readOnly: true,
                copyable: true,
            },
            {
                name: 'clientid',
                label: 'Client ID',
                type: 'text',
                validation: Yup.string().required('Client ID is required'),
            },
            {
                name: 'clientSecret',
                label: 'Client Secret',
                type: 'text',
                validation: Yup.string().required('Client Secret is required'),
            },
        ],
    },
    notion: {
        fields: [
            {
                name: 'internalIntegrationSecret',
                label: 'Internal Integration Secret',
                type: 'text',
                validation: Yup.string().required('Internal Integration Secret URL is required'), 
                 
            }, 
        ],
    },
    mysql: {
        fields: [
            {
                name: 'name',
                label: 'Name',
                type: 'text',
                validation: Yup.string().required('Name is required'), 
                 
            }, 
            {
                name: 'host',
                label: 'Host',
                type: 'text',
                validation: Yup.string().required('Host is required'), 
                 
            }, 
            {
                name: 'database',
                label: 'database',
                type: 'text',
                validation: Yup.string().required('Database is required'), 
                 
            }, 
            {
                name: 'user',
                label: 'User',
                type: 'text',
                validation: Yup.string().required('User is required'), 
                 
            }, 
            {
                name: 'Password',
                label: 'password',
                type: 'Password',
                validation: Yup.string().required('Password is required'), 
                 
            }, 
            {
                name: 'port',
                label: 'Port',
                type: 'text',
                validation: Yup.string().required('Port is required'), 
                defaultValue:3306
                 
            }, 
            {
                name: 'connectTimeout',
                label: 'Connect Timeout',
                type: 'text',
                validation: Yup.string().required('Port is required'), 
                defaultValue:3306
                 
            }, 
           /*  {
                name: 'ssl',
                label: 'SSL',
                type: 'checkbox',
                validation: Yup.boolean(),
                toggleFields: [
                    {
                        name: 'caCertificate',
                        label: 'CA Certificate',
                        type: 'text',
                        validation: Yup.string().required('CA Certificate is required'),
                    },
                    {
                        name: 'clientPrivateKey',
                        label: 'Client Private Key',
                        type: 'text',
                        validation: Yup.string().required('Client Private Key is required'),
                    },
                    {
                        name: 'clientCertificate',
                        label: 'Client Certificate',
                        type: 'text',
                        validation: Yup.string().required('Client Certificate Key is required'),
                    },
                ],
                isConditional:true 
            },
            {
                name: 'sshTunnel',
                label: 'SSH Tunnel',
                type: 'checkbox',
                validation: Yup.boolean(),
                isConditional:true ,
                toggleFields: [
                    {
                        name: 'caCertificate',
                        label: 'SSH Authenticate with',
                        type: 'text',
                        validation: Yup.string().required('SSH Authenticate with is required'),
                    },
                    {
                        name: 'sshHost',
                        label: 'SSH Host',
                        type: 'text',
                        validation: Yup.string().required('SSH Host is required'),
                    },
                    {
                        name: 'sshPort',
                        label: 'SSH Port',
                        type: 'text',
                        validation: Yup.string().required('SSH Port Key is required'),
                        defaultValue:22
                    },
                    {
                        name: 'sshUser',
                        label: 'SSH User',
                        type: 'text',
                        validation: Yup.string().required('SSH User Key is required'), 
                    },
                    {
                        name: 'sshPassword',
                        label: 'SSH Password',
                        type: 'password',
                        validation: Yup.string().required('SSH Password Key is required'),
                        defaultValue:3306
                    },
                ],
            } */
        ],
    },
};