import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Select from "react-select";
import Editor from '@monaco-editor/react';
import FixedExpressionTabs from "./FixedExpressionTabs";
import CustomSelectIconOption from "./CustomSelectIconOption";
import CustomSelectIconValue from "./CustomSelectIconValue"; 
import CustomSelectSubtitle from "./CustomSelectSubtitle";
import createAPI from "../api";

const ItemType = {
  FIELD: "field",
};

const options2 = [
  {
    value: "string",
    label: "String",
    image: "../../images/edit-field-icon-string.svg",
  },
  {
    value: "number",
    label: "Number",
    image: "../../images/edit-field-icon-number.svg",
  },
  {
    value: "boolean",
    label: "Boolean",
    image: "../../images/edit-field-icon-boolean.svg",
  },
  {
    value: "array",
    label: "Array",
    image: "../../images/edit-field-icon-array.svg",
  },
  {
    value: "object",
    label: "Object",
    image: "../../images/edit-field-icon-object.svg",
  },
];

const DynamicFieldForCommandDelete = ({
  index,
  inputField,
  handleChangeInput,
  handleRemoveField,
  tab,
  setTab,
  handleDrop,
  inputFields,
  inputData,
  formik
}) => {
  const API = createAPI();
  const [inputValue, setInputValue] = useState('');
  const [colunms, setColunms] = useState([]);
  const [operator, setOperator] = useState([
    {
      "value": "=",
      "label": "Equal"
    },
    {
      "value": "!=",
      "label": "Not Equal"
    },
    {
      "value": "LIKE",
      "label": "Like"
    },
    {
      "value": ">",
      "label": "Greater Than"
    },
    {
      "value": "<",
      "label": "Less Than"
    },
    {
      "value": ">=",
      "label": "Greater Than Or Equal"
    },
    {
      "value": "<=",
      "label": "Less Than Or Equal"
    },
    {
      "value": "IS NULL",
      "label": "Is Null"
    },
    {
      "value": "IS NOT NULL",
      "label": "Is Not Null"
    }
  ]
  );
  const editorRef = useRef(null); 
  const [editorReady, setEditorReady] = useState(false);
  const [tab1, setTab1] = useState("fixed");
  const [{ canDropKey, isOverKey }, dropColumn] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "column"),
    collect: (monitor) => ({
      isOverKey: monitor.isOver(),
      canDropKey: monitor.canDrop(),
    }),
  });

  const [{ canDropType, isOverType }, dropOperator] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "operator"),
    collect: (monitor) => ({
      isOverType: monitor.isOver(),
      canDropType: monitor.canDrop(),
    }),
  });

  const [{ canDropValue, isOverValue }, dropValue] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "value"),
    collect: (monitor) => ({
      isOverValue: monitor.isOver(),
      canDropValue: monitor.canDrop(),
    }),
  });

  const extractKeyFromExpression = (expression) => {
    const regex = /{{\s*inputData\["(.*?)"\]\s*}}/;
    const match = expression.match(regex);
    return match ? match[1].trim() : null;
  };

  const getValueFromKey = (key, data) => {
    if (!key) return '';

    const keys = key.split(/\.|\[|\]/).filter(k => k); // Handle dot notation and array indices
    let value = data;

    for (const k of keys) {
      if (value[k] !== undefined) {
        value = value[k];
      } else {
        return '';
      }
    }

    return value;
  };

  const updateInputValue = (expression, data) => {
    const key = extractKeyFromExpression(expression);
    const value = getValueFromKey(key, data);
    setInputValue(value);
  };

  useEffect(() => {
    updateInputValue(inputField.value, inputData[0]);
  }, [inputField.value, inputData]);
  function getDropValue(keyData){
    const key = extractKeyFromExpression(keyData);
    const value = getValueFromKey(key, inputData[0]); 
    return value
  }


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if(formik.values['credential'] !='' && formik.values['table'] !=''){
            const response = await API.post("mysql/table-column", {
                credential: formik.values['credential'],
                tableName: formik.values['table'],
            },{
              headers: {
                "Requires-Auth": true, // This tells the interceptor to add the auth token
              },
            });
    
            console.log(response.data);
            setColunms(response.data.results)
        }
       

         
      } catch (error) {
        //setError(error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
  const updateInputValues = (expression) => {
    const regex = /\{\{(.*?)\}\}/g;

    console.log(expression);

    const result = expression.replace(regex, (match, expression) => {
      const modifiedExpression = expression.replace(/\$json/g, "inputData[0]");

      try {
        const result = eval(modifiedExpression);
        console.log("jsdbvjhvbe", result !== expression);
        return result !== undefined && result !== expression ? String(result) : "Invalid syntax";
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`, error);
        return "Invalid syntax";
      }
    });
    console.log("result>>>>", result);
    return  result
  };
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    setEditorReady(true);
  }
  return (
    <div className="edit-fields-inn-form-parent mysql-tripple-form-fields">
    <div className="midform-draggable-fields_stack-item-inn relative border border-solid border-[#ccc] rounded-[6px]">
      {inputFields.length > 1 && (
        <div className="midform-draggable-fields_stack-item-delete absolute -left-[30px] top-[0] opacity-0 cursor-pointer [transition:0.3s_opacity_ease]"
          onPointerDown={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()} onClick={() => handleRemoveField(index)}
        >
          <img
            src="../../images/node-action-delete.svg"
            alt="node-action-delete"
            className="w-[20px]"
          />
        </div>
      )}
      <div className="midform-field midform-field-select midform-select-subtitle relative">
        <div
          ref={dropOperator}
          className={`input-container ${isOverType ? "highlight" : ""}`}
        >
          <Select
            className="midform-select-main midform-select-mysql midform-select-mysql-1"
            classNamePrefix="midform-select"
            options={colunms}
            name="column"
            onChange={(e) =>
              handleChangeInput(index, {
                target: { name: "column", value: e.value },
              })
            }
            value={colunms.find((option) => option.value === inputField.column)}
            components={{ Option: CustomSelectSubtitle }}
          />
        </div>
      </div>
      <div className="">
        <div
          ref={dropOperator}
          className={`input-container ${isOverType ? "highlight" : ""}`}
        >
          <Select
            className="midform-select-main midform-select-mysql midform-select-mysql-2"
            classNamePrefix="midform-select"
            options={operator}
            name="operator"
            onChange={(e) =>
              handleChangeInput(index, {
                target: { name: "operator", value: e.value },
              })
            }
            value={operator.find((option) => option.value === inputField.operator)}
            
          />
        </div>
      </div>
      <div className="diff-dynamic-fields-with-dots-drop midform-field-has-dots-drop">
      <FixedExpressionTabs
          onExpressionChange={(value) =>{setTab1(value)}
            //handleChangeInput(index, { target: { name: "value", value } })
          }
          tab={tab}
          setTab={setTab}
        >
          <div
            ref={dropValue}
            className={`input-container ${isOverValue ? "highlight" : ""}`}
          >{tab1 !== "fixed" ? (
            <div className="monaco_editor_parent">
            <Editor
              name="value"
              height="150px"
              defaultLanguage="javascript"
              value={inputField.value}
              theme="vs-light"
              onChange={(event) => handleChangeInput(index, event,true,'value')}
              onMount={handleEditorDidMount}
              options={{
                automaticLayout: true,
                suggestOnTriggerCharacters: true,
                acceptSuggestionOnEnter: "on",
                minimap: { enabled: false },
              }}
            />
            </div>
          ) : (
            <input
              name="value"
              placeholder="Value"
              value={inputField.value}
              onChange={(event) => handleChangeInput(index, event)}
              className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
            />)}
            {inputField.value && tab1 !== "fixed" && (<div className="post-drag-value pb-[5px] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-50 mt-[3px] mb-[3px]" title={updateInputValues(inputField.value)}>{updateInputValues(inputField.value)}</div>)} 
          </div>
          </FixedExpressionTabs>
      </div> 
    </div>
    </div>
  );
};

export default DynamicFieldForCommandDelete;
