import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import "ace-builds/src-noconflict/theme-github";
import 'ace-builds/src-noconflict/theme-monokai';
const AceEditorFormik = ({ formik, fieldName }) => {
    return (
        <div className="formik-ace-editor">
            <AceEditor
                mode="sql"
                theme="github"
                name={fieldName}
                value={formik.values[fieldName]}
                onChange={(value) => formik.setFieldValue(fieldName, value)}
                editorProps={{ $blockScrolling: true }}
                style={{ width: '100%', height: '200px' }}
            />
            {formik.touched[fieldName] && formik.errors[fieldName] ? (
                <div className="error text-[11px] text-[red]">{formik.errors[fieldName]}</div>
            ) : null}
        </div>
    );
};
export default AceEditorFormik;