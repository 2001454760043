import Select from "react-select";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup'; 
const options = [
  { value: "t1", label: "(GMT-05:00) Eastern Time (US and Canada)", no: 1 },
  { value: "t2", label: "(GMT-05:00) Indiana (East)", no: 1 },
  { value: "t3", label: "(GMT-06:00) Central America", no: 1 },
  { value: "t4", label: "(GMT-06:00) Central Time (US and Canada)", no: 1 },
  { value: "t5", label: "(GMT-06:00) Guadalajara, Mexico City", no: 1 },
];
const CommonSidebar = () => {
    const [showpopUp, setShowpopUp] = useState(false);
    const closeModal = () => {
        setShowpopUp(false);
        // toggleVisibility(false);
      };
     
    return (
      <>
      <Popup open={showpopUp} className="global-popup" onClose={closeModal}>
          <div className="global-popup-main w-[624px] bg-[#fff] rounded-[6px] relative">
            <div className="global-popup-inn">
              <div className="global-popup-head bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px] rounded-tl-[6px] rounded-tr-[6px]">
                <div className="global-popup-head-title text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">
                  Settings
                </div>
                <button
                  className="global-popup-head-close [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
                  onClick={closeModal}
                >
                  <img src="../../images/close-popup.svg" className="popup-close" />
                </button>
              </div>
              <div className="global-popup-body p-[30px]">
                <div className="midform-field midform-field-select relative mb-[30px]">
                  <label className="text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                    Timezone:
                  </label>
                  <div className="global-popup-select">
                    <Select
                      classNamePrefix="midform-select"
                      options={options}
                    />
                
                </div>
                </div>
                <div className="form_comm_actions flex items-center justify-center">
                  <button className={`w-[150px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]`}>
                    Cancel
                  </button>
                  <button className="primary_btn_hover w-[150px] py-2 px-4 rounded text-white [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]">
                    Save
                  </button>
              </div>
              </div>
            </div>
          </div>
        </Popup>
        <div className="sidebar fixed top-[0] left-[0] h-full w-[80px] z-10 bg-[#fff] border-r [border-right-style:solid] [border-right-color:#DCE4E8] flex items-center justify-between flex-col pt-[40px] pb-[30px]">
              <div className="sidebar-top w-full flex flex-col items-center justify-center">
                <div className="sidebar-logo flex items-center justify-center mb-[45px]">
                  <img src="/images/logo.svg" alt="logo" width={23} />
                </div>
                <div className="sidebar-icon-parent sidebar-icon-parent-top">
                  <Link to="/" className="sidebar-com-icon sidebar-home-icon [transition:0.3s_background_ease] bg-transparent hover:bg-[#f0f1f2] flex items-center justify-center w-[58px] h-[44px] rounded-[8px]">
                    <img src="/images/sidebar-home-icon.png" alt="sidebar-home-icon" width={18} />
                  </Link>
                </div>
              </div>
              <div className="sidebar-bottom w-full flex flex-col items-center justify-center">
                <div className="sidebar-icon-parent sidebar-icon-parent-bottom pb-[10px]">
                  <Link to="/" className="sidebar-com-icon sidebar-document-icon [transition:0.3s_background_ease] bg-transparent hover:bg-[#f0f1f2] flex items-center justify-center w-[58px] h-[48px] rounded-[8px]">
                    <img src="/images/sidebar-document-icon.png" alt="sidebar-document-icon" width={18} />
                  </Link>
                  <button onClick={(e) => {
                    setShowpopUp((pop) => !pop);
                    // toggleVisibility(true);
                    e.stopPropagation();
                  }} className="sidebar-com-icon sidebar-settings-icon [transition:0.3s_background_ease] bg-transparent hover:bg-[#f0f1f2] flex items-center justify-center w-[58px] h-[48px] rounded-[8px]">
                    <img src="/images/sidebar-settings-icon.png" alt="sidebar-settings-icon" width={18} />
                  </button>
                  <Link to="/" className="sidebar-com-icon sidebar-elements-icon [transition:0.3s_background_ease] bg-transparent hover:bg-[#f0f1f2] flex items-center justify-center w-[58px] h-[48px] rounded-[8px]">
                    <img src="/images/sidebar-elements-icon.png" alt="sidebar-elements-icon" width={18} />
                  </Link>
                  <Link to="/" className="sidebar-com-icon sidebar-box-icon [transition:0.3s_background_ease] bg-transparent hover:bg-[#f0f1f2] flex items-center justify-center w-[58px] h-[48px] rounded-[8px]">
                    <img src="/images/sidebar-box-icon.png" alt="sidebar-box-icon" width={18} />
                  </Link>
                </div>
                <div className="sidebar-avatar border-t [border-top-style:solid] [border-top-color:#DCE4E8] w-full flex items-center justify-center pt-[10px]">
                  <img src="/images/sidebar-avatar.jpg" alt="sidebar-avatar" width={32} className="rounded-[100%]" />
                </div>
              </div>
            </div>
            </>
    );
};

export default CommonSidebar;
