import React, { useState, useRef } from 'react';
import styled, { css } from "styled-components";
import { ClassicScheme, RenderEmit, Presets } from "rete-react-plugin";
import { Drag } from "rete-react-plugin";
import Popup from "reactjs-popup";
import { Schemes } from "../rete/types";
import { copyNodeWithConnectionsCopy, deleteNodes, JsonView } from "../editor";
import Codenodes from "../customization/codeNodes";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CodeEditor from "./codeiditor";
import { getInputConnectedNodes, isValidJsonCustom } from "../utils";
import DynamicTable from "../components/Dynamictable";
import RecursiveComponent from "../components/RecursiveComponent";
import { useFormik } from "formik";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
const { RefSocket, RefControl } = Presets.classic;
export const $nodewidth = 200;
export const $socketmargin = 6;
export const $socketsize = 16;

type NodeExtraData = { width?: number; height?: number };
type Props<S extends ClassicScheme> = {
  data: Codenodes & NodeExtraData;
  emit: RenderEmit<S>;
};
export const NodeStyles = styled.div<
  NodeExtraData & { selected: boolean; styles?: (props: any) => any }
>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid #BABFC6;  
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) =>
    Number.isFinite(props.width) ? `${props.width}px` : `${$nodewidth}px`};
  height: ${(props) =>
    Number.isFinite(props.height) ? `${props.height}px` : "auto"};
  width: 280px;
  height: 61px;
  padding-left: 11px;
  position: relative;
  user-select: none;
  outline: 0px solid #146de0;  
  box-shadow: 0 0 0 0 #cad8f8;  
  &:hover {
    outline: 2px solid #146de0;
  }
  &:focus {
    outline: 2px solid #146de0; 
    box-shadow: 0 0 0 6px #cad8f8;       
  }    
  ${(props) =>
    props.selected &&
    css`
      outline: 2px solid #146de0; 
      box-shadow: 0 0 0 6px #cad8f8;        
    `}
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  .output-socket {
    display: block;
    position: absolute;
    right: -9px;
    height: 20px;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;    
    background-image: url(../../images/node_main_connector.svg);
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: right;
  }
  .input-socket {
    display: block;
    position: absolute;
    left: -12px;
    height: 24px;
    width: 11px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    background: #a5adba;
  }
  .input-title,
  .output-title {
    vertical-align: middle;
    color: red;
    display: none;
    font-family: sans-serif;
    font-size: 14px;
    margin: ${$socketmargin}px;
    line-height: ${$socketsize}px;
  }
  .ant-typography pre {
    color: #fff;
  }
  .input-control {
    z-index: 1;
    width: calc(100% - ${$socketsize + 2 * $socketmargin}px);
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    display: block;
    padding: ${$socketmargin}px ${$socketsize / 2 + $socketmargin}px;
  }
  ${(props) => props.styles && props.styles(props)}
`;

export function CodesNodeUI(props: Props<Schemes>) {
  const { id, width, height } = props.data;
  const [codesData, setCodesData] = React.useState({});
  const selected = props.data.selected || false;
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const [showpopUp, setShowpopUp] = React.useState(false);
  const [inputOption, setInputOption] = React.useState([]);
  const [inputData, setInputData] = React.useState([]);
  const [outPutData, setOutputData] = React.useState([]);
  const [editOutputJson, setEditOutputJson] = React.useState(false);
  const [isValidJson, setIsValidJson] = React.useState(true);
  const [tabIndexOutput, setTabIndexOutput] = React.useState(0);
  const [isNodeEnable, setIsNodeEnable] = React.useState(true);
  const [isMainFormSubmitted, setIsMainFormSubmitted] = React.useState(false);
  const [code, setCode] = React.useState("// Write your JavaScript code here\n console.log($json);"); // State for code content

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCodesData(props.data.controls.dataCode.value || {});
    }, 1000);
    return () => clearInterval(intervalId);
  }, [props.data.controls.dataCode.value]);
  const closeModal = () => {
    setShowpopUp(false);
    toggleVisibility(false);
  };
  const toggleVisibility = (show) => {
    const actions = document.querySelector(
      ".workflow-view-actions-inn"
    ) as HTMLElement | null;
    const sidebar = document.querySelector(
      ".workflow-sidebar"
    ) as HTMLElement | null;
    if (show) {
      if (actions) {
        actions.style.display = actions.style.display === "none" ? "" : "none";
      }
      if (sidebar) {
        sidebar.style.display = sidebar.style.display === "none" ? "" : "none";
      }
    } else if (!show) {
      if (actions) {
        actions.style.display = "";
      }
      if (sidebar) {
        sidebar.style.display = "";
      }
    }
  };
  const options = [{ value: "Javascript", label: "Javascript" }];
  React.useEffect(() => {
    if (props.data?.editor) {
      let inputOption = getInputConnectedNodes(props.data?.editor);
      console.log(inputOption);
      inputOption = inputOption.map((val) => ({
        value: val.id,
        label: val.label,
      }));
      setInputOption(inputOption);
      const inputs = props.data.dataflow
        .fetchInputs(props.data.id)
        .then((val) => {
          console.log(val.input[0]);
          if (isValidJsonCustom(val?.input[0])) {
            let setINput=Array.isArray(JSON.parse(val?.input[0]))?JSON.parse(val?.input[0]):[JSON.parse(val?.input[0])]
            setInputData(setINput); 
            console.log(inputData)
            setCodesData(inputData);
          }
        });
    }
  }, [showpopUp]);
  const handleDrop = (item) => {
    console.log("Dropped item:", item);
  };
  const formik = useFormik({
    initialValues:props.data.controls.dataCode.value!='' && isValidJsonCustom(props.data.controls.dataCode.value)?
    JSON.parse(props.data.controls.dataCode.value) :{
      languge: "Javascript",
      code: '// Write your JavaScript code here\n console.log($json);',
    },
    onSubmit: async (values) => {
      try {
        setIsMainFormSubmitted(true)
        console.log(values);
        let execute = executeCode(values.code);
        if (!Array.isArray(execute)) {
          setIsMainFormSubmitted(false)
          props.data.log("Returned data should be an array.");
          return;
        }
        setOutputData(execute);
        
        props.data.controls.dataCode.value = JSON.stringify(values);
        props.data.httpData = JSON.stringify(execute);
        setIsMainFormSubmitted(false)
        //props.data.execute("exec", () => {});
      } catch (error) {
        setIsMainFormSubmitted(false)
        console.error("Error submitting the form:", error);
      }
    },
  });
  React.useEffect(()=>{
    props.data.controls.dataCode.value=JSON.stringify(formik.values)

  },[formik])
  const executeCode = (formInputData) => {
    try {
      const globalVarStr = JSON.stringify(inputData);

      const fullCode = `
            const $json = ${globalVarStr};
            ${formInputData}
        `;
      const func = new Function(fullCode);

      return func();
    } catch (error) {
      console.log(error);
    }
  };
  function handelSaveOutputJson() {
    if (isValidJson) {
      props.data.httpData = outPutData;
      setEditOutputJson(false);
    }
  }
  function handelJsonedit() {
    setEditOutputJson((prev) => !prev);
    setTabIndexOutput(1);
  }
  const handleJsonChange = (data) => {
    if (data.jsObject) {
      setOutputData(data.jsObject);
      setIsValidJson(true);
    } else {
      // If data.jsObject is undefined, the JSON is invalid
      setIsValidJson(false);
    }
  };
  function HandelOnselectTab(index) {
    setTabIndexOutput(index);
    setEditOutputJson(false);
  }
  const [isLableEdit, setIsLableEdit] = React.useState(false);
  const [label, setLabel] = React.useState(props.data.label);
  function handelLableEdit() {
    setIsLableEdit((prv) => !prv);
  }

  function handelSaveLableEdit() {
    setIsLableEdit(false);
  }
  const handleChangeLable = (e) => {
    let newName=e.target.value
    if (newName.length > 30) {
      // Limit the input length to 20 characters
      //console.log("Maximum character length is 20");
      return;
    }
    setLabel(e.target.value);
    // If you need to update the parent component or props.data, you should call a function passed as a prop
    props.data.label = e.target.value;
  };

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(URL.createObjectURL(selectedFile));
      
      // Reset input value so that choosing the same file triggers the event again
      fileInputRef.current.value = null;
    }
  };

  const handleFileRemove = () => {
    setFileName('');
    setFile(null);
    // Reset the input field value
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const [leftWidth, setLeftWidth] = useState("calc(50% - 246px)");
  const [rightWidth, setRightWidth] = useState("calc(50% - 246px)");
  const containerRef = useRef(null);
  const dragging = useRef(false);

  const handleMouseDown = () => {
    dragging.current = true;
    document.body.classList.add('no-select');
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (dragging.current && containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width; // Get container width
      const midOffset = 492; // Static size for the middle div
      const minSize = 400; // Minimum size for left and right divs

      // Calculate new left width based on the mouse position relative to the container
      const newLeftWidth = e.clientX - midOffset / 2;
      const newRightWidth = containerWidth - newLeftWidth - midOffset;

      // Apply minimum width constraint and update state
      if (newLeftWidth >= minSize && newRightWidth >= minSize) {
        setLeftWidth(`${newLeftWidth}px`);
        setRightWidth(`${newRightWidth}px`);
      }
    }
  };

  const handleMouseUp = () => {
    dragging.current = false;
    document.body.classList.remove('no-select');
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const MemoizedCodeEditor = React.useMemo(
    () => <CodeEditor fieldName="code" formik={formik} codeval={inputData} code={code} setCode={setCode} />,
    [inputData, formik, code, setCode]
  );
  React.useEffect(() => {
    //setAllFormValues(formik.values); // Assign all form values to a variable 
    if(props.data.httpData != '' && isValidJsonCustom(props.data.httpData)){
      setOutputData(JSON.parse(props.data.httpData))
    }
  }, [props.data.httpData]);
  function handelEnableDisableNode() {
    console.log("enable disable");
    setIsNodeEnable((prv) => !prv);
    props.data.isNodeEnable = isNodeEnable;
  }
  function handelCopyNode() {
    copyNodeWithConnectionsCopy(props.data.id, props.data.editor);
  }
  function handelDeleteNode() {
    deleteNodes(props.data.id);
  }
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Popup
          open={showpopUp}
          className="code-node-popup"
          onClose={closeModal}
        >
          <div className="modal">
            <div className="node-popup webhook-node-popup fixed w-[calc(100%_-_80px)] h-[calc(100%_-_125px)] left-[0] right-[0] top-[60px] m-auto bg-[#FFF] z-[11] rounded-[8px]">
              <div className="node-popup-inn">
              <div className="node-popup-actions back node-popup-back border-b [border-bottom-style:solid] [border-bottom-color:#BECBD8] h-[56px] bg-[#2C2D38] flex items-center justify-between rounded-tl-[8px] rounded-tr-[8px]">
                <div className="node-popup-actions-left">
                  <button
                    onClick={closeModal}
                    className="flex items-center justify-center border-[0] p-0 h-auto rounded-none bg-transparent [box-shadow:none]"
                  >
                    <span className="primary_btn_hover flex h-[56px] items-center justify-center w-[56px] rounded-tl-[8px] bg-[#146DE0]">
                      <img
                        src="../../images/node_inn_back-square.svg"
                        alt="node_inn_back-square"
                      />
                    </span>
                    <span className="text-[#fff] font-['inter-semibold'] text-[16px] not-italic font-normal leading-[24px] ml-[16px]">
                      Back
                    </span>
                  </button>
                  </div>
                  <div className="node-popup-actions-right flex items-center justify-end pr-[16px]">
                  <div className="node-popup-autosave-label text-[#fff] font-['inter-semibold'] text-[12px] not-italic font-normal leading-[15px] opacity-50 mr-[16px]">Autosaved</div>  
                  <button
                  onClick={formik.submitForm}
                                type="submit"
                                className="primary_btn_hover flex items-center justify-center [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[32px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-medium] text-[14px] not-italic font-normal leading-[19.6px] mr-[16px]"
                              >
                                <span className="mr-[8px]">Test Step</span>
                                
                                <img
                                  src="../../images/rete-btn-loader.svg"
                                  alt="rete-btn-loader" 
                                  className={`rete-btn-loader w-[16px] ${isMainFormSubmitted?"":"hidden"}`}
                                  width={16}
                                />
                                  <img
                                  src="../../images/node_inn_flask.svg"
                                  alt="node_inn_flask"
                                  className={`${isMainFormSubmitted?"hidden":""}`}
                                /> 
                              </button>
                <button type='button' onClick={handelDeleteNode}>
                  <img src="../../images/node_inn_delete.svg" alt="node_inn_delete" />  
                </button>                             
                              </div>
                </div>
                <div className="node-popup-views flex h-[calc(100vh_-_120px)]" ref={containerRef}>
                  <div className="node-popup-view node-popup-input-view w-[calc(50%_-_246px)] left" style={{ width: leftWidth }}>
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs>
                        <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-head flex items-center justify-start">
                            <div className="node-popup-output-title mr-[16px] text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">
                                Input
                              </div>
                              {/* <Select
                                className="midform-select-main"
                                classNamePrefix="midform-select"
                                options={inputOption}
                              /> */}
                            </div>
                            <TabList className="flex items-center justify-end">
                              <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  Table
                                </Tab>
                                <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  JSON
                                </Tab>
                                <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                  Schema
                                </Tab>
                              </div>
                              {/* <button className="edit-view-btn flex items-center justify-center ml-[8px] rounded-[4px] bg-[#fff] w-[36px] h-[40px]">
                              <img
                                src="../../images/node-popup-edit.svg"
                                alt="node-popup-edit"
                              />
                            </button> */}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                  {(inputData != null ||
                                    inputData != undefined) && Array.isArray(inputData) && (
                                    <DynamicTable data={inputData} />
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-json-view node-json-view-readonly webhook-node-json-view h-[calc(100vh_-_298px)]">
                              {(inputData != null ||
                                inputData != undefined) && Array.isArray(inputData) && (
                                <JSONInput
                                  id="webhook-json"
                                  placeholder={inputData}
                                  locale={locale}
                                  theme="light_mitsuketa_tribute"
                                  colors={{
                                    default: "#39ADB5",
                                    keys: "#E53935",
                                    string: "#91B859",
                                    number: "#F76D47",
                                    background: "#F7F7F7",
                                  }}
                                  width={"100%"}
                                  height={"100%"}
                                  style={{
                                    outerBox: {
                                      borderRadius: "6px",
                                      border: "1px solid #CCC",
                                      overflow: "hidden",
                                      background: "#F7F7F7",
                                    },
                                  }}
                                  viewOnly={true}
                                />
                              )}
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                                {(inputData != null ||
                                  inputData != undefined) && Array.isArray(inputData) &&
                                  inputData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={true}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-fields-view w-[492px] relative -top-[56px] has-fixed-express-actions middle">
                    <div className="node-popup-midform">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="midform-head">
                          <div className="midform-head-wrap relative">
                            {isLableEdit && (
                              <div className="edit-comm-name absolute z-[3] top-2/4 left-[200px] w-[200px] -translate-y-1/2 bg-[#fff] rounded-[4px] [box-shadow:0_0_15px_-5px_rgba(0,_0,_0,_0.25)] p-[10px]">
                                <>
                                  <div className="edit-comm-name-title font-[inter-medium] text-[14px] leading-[normal] text-[rgba(0,0,0,0.8)] font-normal mb-[10px]">
                                    Rename node
                                  </div>
                                  <input
                                    type="text"
                                    onChange={handleChangeLable}
                                    value={label}
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />
                                  <div className="flex items-center justify-end mt-[10px]">
                                    <button
                                      className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                      onClick={handelLableEdit}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                      onClick={handelSaveLableEdit}
                                    >
                                      Save
                                    </button>
                                  </div>
                                  <span className="edit-comm-name-diamond w-[10px] h-[10px] bg-[#fff] rotate-45 absolute top-[0] bottom-[0] m-auto -left-[5px]"></span>
                                </>
                              </div>
                            )}
                            <div className="midform-dots absolute -top-[24px] left-[0] right-[0] m-auto flex items-center justify-center z-[1] w-[66px] h-[24px]" onMouseDown={handleMouseDown}>                              
                            </div>
                            <div className="midform-head-inn relative flex items-center justify-between h-[70px] px-[20px]">
                              <div className="midform-head-title relative z-[2] flex items-center justify-start">
                                <img
                                  src="../../images/code_node_inn_icon.svg"
                                  alt="code_node_inn_icon"
                                  className="w-[24px]"
                                />
                                <span
                                  onClick={handelLableEdit}
                                  className="ml-[8px] text-[#30313D] font-['inter-semibold'] text-[20px] not-italic font-normal leading-[28px]"
                                  title={props.data.label}
                                >
                                  {props.data.label}
                                </span>
                              </div>
                              {/* <button
                                type="submit"
                                className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 relative z-[1] [box-shadow:none] h-[34px] rounded-[4px] bg-[#146DE0] border-0 !px-[12px] !py-[0] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                              >
                                Test Workflow
                              </button> */}
                              <div className="midform-head-bg absolute w-full h-full top-[0] left-[0] z-0 bg-[#E8F0FC] rounded-tl-[8px] rounded-tr-[8px]"></div>
                            </div>
                          </div>
                        </div>
                        <div className="midform-body rounded-bl-[8px] rounded-br-[8px] bg-[#fff]">
                        <Tabs>
                        <TabList className="flex items-center justify-end">
                              <div className="node-inn-main-acts-tabs flex items-center justify-center w-full bg-[#F2F7FD]">
                                <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Parameters
                                </Tab>
                                <Tab className="relative text-center w-[50%] [transition:0.3s_all_ease] cursor-pointer py-[14px] font-['inter-medium'] text-[16px] not-italic font-normal leading-[22px] text-[#30313D] hover:text-[#146DE0]">
                                  Settings
                                </Tab>                                
                              </div>
                            </TabList>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full">
                        <div className="midform-fields">
                          <div className="midform-field midform-field-select relative mb-[30px]">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                              Language
                            </label>
                            <Select
                              className="midform-select-main"
                              classNamePrefix="midform-select"
                              options={options}
                              name="languge"
                              value={options.find(
                                (option) => option.value === "Javascript"
                              )}
                            />
                          </div>
                          <div className="midform-field midform-code-editor relative mb-[30px]">
                            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                              JavaScript
                            </label>
                            {/* <CodeEditor
                              fieldName="code"
                              formik={formik}
                              codeval={inputData}
                            /> */}
                             {MemoizedCodeEditor}
                          </div>
                        </div>
                        </div>
                              </div>
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view border-[1px] border-[solid] border-[#DCE4E8] rounded-bl-[8px] rounded-br-[8px]">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full min-h-[calc(100vh_-_233px)]">
                                  
                                <div className="midform-fields">
                                <div className="midform-field mb-[30px] relative">
                                  <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                                    Node description
                                  </label>
                                  <input
                                    type="number"
                                    className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                                  />                                  
                                </div>
                                </div>

                                </div>
                              </div>
                            </TabPanel>                            
                            </Tabs> 
                            </div>
                      </form>
                    </div>
                  </div>
                  <div className="node-popup-view node-popup-output-view w-[calc(50%_-_246px)] right" style={{ width: rightWidth }}>
                    <div className="node-popup-output-view-inn">
                      <div className="node-popup-output-tabs">
                        <Tabs
                          selectedIndex={tabIndexOutput}
                          onSelect={(index) => HandelOnselectTab(index)}
                        >
                          <div className="node-popup-output-tabs-wrap flex items-center justify-between bg-[#F9FAFB] border-b [border-bottom-style:solid] [border-bottom-color:#E3E3E8] h-[65px] px-[24px]">
                            <div className="node-popup-output-title mr-[16px] text-[#6C7278] font-['inter-bold'] text-[16px] not-italic font-normal leading-[28px] tracking-[3px] uppercase">
                              Output
                            </div>
                            <TabList className="flex items-center justify-end">
                              {!editOutputJson ? (
                                <>
                                  <div className="node-popup-output-tabs-inn flex items-center justify-end bg-[#fff] border-[1px] border-[solid] border-[#D1D4D9] rounded-[8px] p-[4px]">
                                    <Tab className="[transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      Table
                                    </Tab>
                                    <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      JSON
                                    </Tab>
                                    <Tab className="ml-[4px] [transition:0.3s_all_ease] cursor-pointer rounded-[6px] px-[10px] py-[6px] font-['inter-medium'] text-[12px] not-italic font-normal leading-[normal] text-[#1A1C1E] hover:bg-[#74757D] hover:text-[#fff]">
                                      Schema
                                    </Tab>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[rgba(0,0,0,0.6)] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#fff] mr-[10px] rounded-[4px]"
                                    onClick={handelJsonedit}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="primary_btn_hover [transition:0.3s_opacity_ease] opacity-100 hover:opacity-50 flex items-center justify-center h-[34px] text-[#fff] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] px-[12px] bg-[#146DE0] rounded-[4px]"
                                    onClick={handelSaveOutputJson}
                                    disabled={!isValidJson}
                                  >
                                    Save
                                  </button>
                                </>
                              )}
                              {!editOutputJson && (
                                <button
                                  className="[transition:0.3s_all_ease] flex items-center justify-center ml-[8px] rounded-[8px] bg-[#fff] w-[36px] h-[36px] border-[1px] border-[solid] border-[#D1D4D9] hover:border-[#74757D]"
                                  onClick={handelJsonedit}
                                >
                                  <img
                                    src="../../images/node_inn_edit.svg"
                                    alt="node_inn_edit"
                                  />
                                </button>
                              )}
                            </TabList>
                          </div>
                          <div className="node-popup-output-tab-panels px-[30px] py-[26px]">
                            <TabPanel className="node-popup-output-tab-panel node-table-view webhook-node-table-view">
                              <div className="node-table-view-wrap">
                                <div className="node-table-main relative overflow-x-auto w-full h-[calc(100vh_-_298px)]">
                                  {(outPutData != null ||
                                    outPutData != undefined) && (
                                    <DynamicTable data={outPutData} />
                                  )}
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel
                              className={`node-popup-output-tab-panel node-json-view ${
                                editOutputJson ? "" : "node-json-view-readonly"
                              } webhook-node-json-view h-[calc(100vh_-_298px)]`}
                            >
                              <JSONInput
                                id="webhook-json"
                                placeholder={outPutData}
                                locale={locale}
                                theme="light_mitsuketa_tribute"
                                colors={{
                                  default: "#39ADB5",
                                  keys: "#E53935",
                                  string: "#91B859",
                                  number: "#F76D47",
                                  background: "#F7F7F7",
                                }}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  outerBox: {
                                    borderRadius: "6px",
                                    border: "1px solid #CCC",
                                    overflow: "hidden",
                                    background: "#F7F7F7",
                                  },
                                }}
                                viewOnly={!editOutputJson}
                                onChange={handleJsonChange}
                              />
                            </TabPanel>
                            <TabPanel className="node-popup-output-tab-panel node-schema-view webhook-node-schema-view">
                              <div className="node-schema-tree-view webhook-node-schema-tree-view h-[calc(100vh_-_298px)] overflow-auto">
                                {(outPutData != null ||
                                  outPutData != undefined) &&
                                  outPutData.map((item, index) => (
                                    <RecursiveComponent
                                      key={index}
                                      item={item}
                                      onFieldDrop={handleDrop}
                                      draggable={false}
                                    />
                                  ))}
                              </div>
                            </TabPanel>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </DndProvider>
      <NodeStyles
        selected={selected}
        width={280}
        height={61}
        className="nodeWrap node-comm node-code"
        data-testid="node"
      >
        <div
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            setShowpopUp((pop) => !pop);
            toggleVisibility(true);
            e.stopPropagation();
          }}
          className="title"
          data-testid="title"
        >
          {/* {label} */}
          <div className="node-block-parent">
          {/* <div className="node-block-plus-actions flex items-center justify-center absolute left-[185px] top-[0] bottom-[0] m-auto flex items-center justify-start h-[34px] leading-0">
            <div className="node-block-plus-actions-arrow flex items-center justify-center relative">
              <div className="node-block-plus-actions-arrow-line bg-[#919394] h-[2px] w-[105px]"></div>
              <div className="node-block-plus-actions-arrow-text text-[#60809F] text-center font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] bg-[#F2F5F7] absolute left-[0] right-[0] m-auto block w-[40px]">
                GET
              </div>
            </div>
            <button className="node-block-plus-actions-btn w-[34px] h-[34px]">
              <img src="../../images/plus-icon-node.svg" alt="plus-icon-node" />
            </button>
          </div> */}
            <div className="node-block flex items-center justify-center">
              <div className="node-block-actions absolute top-[100%] right-[0] flex items-center justify-end z-[1] opacity-0 scale-0 w-[0] h-[0] [transition:0.3s_opacity_ease] pt-[17px] [cursor:initial]">
              <div className="node-block-action relative">
                <button onClick={handelEnableDisableNode} className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center">
                  <img
                    src="../../images/node_main_action_run.svg"
                    alt="node_main_action_run"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Run</span>
                </div>
                <div className="node-block-action relative">
                <button onClick={handelCopyNode} className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center">
                <img
                    src="../../images/node_main_action_copy.svg"
                    alt="node_main_action_copy"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Copy</span>
                </div>
                <div className="node-block-action relative">
                <button  onClick={handelDeleteNode} className="cursor-pointer mr-[4px] bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center">
                <img
                    src="../../images/node_main_action_delete.svg"
                    alt="node_main_action_delete"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">Delete</span>
                </div>
                <div className="node-block-action relative">
                <button                  
                  className="cursor-pointer bg-[#EAEAEC] rounded-[5px] w-[28px] h-[28px] flex items-center justify-center"
                >
                  <img
                    src="../../images/node_main_action_dots.svg"
                    alt="node_main_action_dots"
                  />
                </button>
                <span className="canvas-action-tooltip absolute left-2/4 -translate-x-1/2 -bottom-[25px] bg-[#595A64] rounded-[4px] flex items-center justify-center w-[50px] h-[20px] text-[#FFFFFF] font-[inter-regular] text-[11px] leading-[14.52px]">More</span>
                </div>
              </div>
              <div className="node-block-icon-parent w-[36px] h-[36px] flex items-center justify-start mr-[8px]">
                <img
                  className="node-block-icon"
                  src="../../images/code_node_main_icon.svg"
                  alt="code_node_main_icon"
                />
              </div>
              <div className="node-block_text">
              <span
                className="node-block-title block text-[#30313D] font-[inter-semibold] text-[14px] not-italic font-normal leading-[19.6px]"
                title={props.data.label}
              >
                {props.data.label}
              </span>
              <span className="node-block-desc block text-[#74757D] font-[inter-medium] text-[12px] not-italic font-normal leading-[14.52px] mt-[3px]">
                Code node desc text   
              </span>
              </div>
            </div>
          </div>
        </div>
        {outputs.map(
          ([key, output]) =>
            output && (
              <div className="output" key={key} data-testid={`output-${key}`}>
                <div className="output-title" data-testid="output-title">
                  {output?.label}
                </div>
                <RefSocket
                  name="output-socket"
                  side="output"
                  emit={props.emit}
                  socketKey={key}
                  nodeId={id}
                  payload={output.socket}
                />
              </div>
            )
        )}
        <div>{/* <CodeEditor codeval={codesData} /> */}</div>
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="input" key={key} data-testid={`input-${key}`}>
                <RefSocket
                  name="input-socket"
                  emit={props.emit}
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  payload={input.socket}
                />
                {input && (!input.control || !input.showControl) && (
                  <div className="input-title" data-testid="input-title">
                    {input?.label}
                  </div>
                )}
                {input?.control && input?.showControl && (
                  <span className="input-control">
                    <RefControl
                      key={key}
                      name="input-control"
                      emit={props.emit}
                      payload={input.control}
                    />
                  </span>
                )}
              </div>
            )
        )}
      </NodeStyles>
    </>
  );
}
