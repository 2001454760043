import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import FixedExpressionTabs from "./FixedExpressionTabs";
import Editor from "@monaco-editor/react";
const ItemType = {
  FIELD: "field",
};

const DraggableFieldDynamicInput = ({
  index,
  inputField,
  handleChangeInput,
  handleRemoveField,
  tab,
  setTab,
  handleDrop,
  inputFields,
  inputData,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [tab1, setTab1] = useState("fixed");
  const [tab2, setTab2] = useState("fixed");
  const [editorReady, setEditorReady] = useState(false);
  const editorRef = useRef(null);
  const [, drag] = useDrag({
    type: ItemType.FIELD,
    item: { index },
  });

  const [{ canDropKey, isOverKey }, dropKey] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "key"),
    collect: (monitor) => ({
      isOverKey: monitor.isOver(),
      canDropKey: monitor.canDrop(),
    }),
  });

  const [{ canDropValue, isOverValue }, dropValue] = useDrop({
    accept: ItemType.FIELD,
    drop: (item) => handleDrop(item, index, "value"),
    collect: (monitor) => ({
      isOverValue: monitor.isOver(),
      canDropValue: monitor.canDrop(),
    }),
  });

  const extractKeyFromExpression = (expression) => {
    const regex = /{{\s*inputData\["(.*?)"\]\s*}}/;
    const match = expression.match(regex);
    return match ? match[1].trim() : null;
  };
  const extractKeysFromExpression = (expression) => {
    const regex = /{{\s*inputData\["(.*?)"\]\s*}}/g;
    let matches;
    const keys = [];
    while ((matches = regex.exec(expression)) !== null) {
      keys.push(matches[1]);
    }
    return keys;
  };
  const getValueFromKey = (key, data) => {
    if (!key) return "";

    const keys = key.split(/\.|\[|\]/).filter((k) => k); // Handle dot notation and array indices
    let value = data;

    for (const k of keys) {
      if (value[k] !== undefined) {
        value = value[k];
      } else {
        return "";
      }
    }

    return value;
  };

  const updateInputValue = (expression, data) => {
    const keys = extractKeysFromExpression(expression);
    const value = keys.map((key) => getValueFromKey(key, data));
    setInputValue(value.join(""));
  };

  useEffect(() => {
    updateInputValue(inputField.value, inputData[0]);
    updateInputValue(inputField.key, inputData[0]);
  }, [inputField.value, inputField.key, inputData]);
  function getDropValue(keyData) {
    const keys = extractKeysFromExpression(keyData);
    const value = keys.map((key) => getValueFromKey(key, inputData[0]));
    return value.join("");
  }
  /*  const handleEditorChange = (value) => {
    setEditorValue(value);
    formik.setFieldValue(fieldName, value);
    updateInputValues(value);
  }; */
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    setEditorReady(true);
  };
  const updateInputValues = (expression) => {
    const regex = /\{\{(.*?)\}\}/g;

    console.log(expression);

    const result = expression.replace(regex, (match, expression) => {
      const modifiedExpression = expression.replace(/\$json/g, "inputData[0]");

      try {
        const result = eval(modifiedExpression);
        console.log("jsdbvjhvbe", result !== expression);
        return result !== undefined && result !== expression ? String(result) : "Invalid syntax";
      } catch (error) {
        console.error(`Error evaluating expression: ${expression}`, error);
        return "Invalid syntax";
      }
    });
    console.log("result>>>>", result);
    return  result
  };
  return (
    <div
      /* ref={drag} */
      className="relative border-b [border-bottom-style:dashed] [border-bottom-color:#DCE4E8] mb-[10px]"
    >
      <div className="diff-dynamic-fields-with-dots-drop midform-field-has-dots-drop">
        <FixedExpressionTabs
          onExpressionChange={(value) => {
            console.log(value);
            setTab1(value);
            //  handleChangeInput(index, { target: { name: "key", value } })
          }}
        >
          <div
            ref={dropKey}
            className={`input-container flex flex-col justify-start items-end ${isOverKey ? "highlight" : ""}`}
          >
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px] w-[90%]">
              Key
            </label>
            {tab1 !== "fixed" ? (
              <div className="monaco_editor_parent">
              <Editor
                name="key"
                height="150px"
                defaultLanguage="javascript"
                value={inputField.key}
                theme="vs-light"
                onChange={(event) => {handleChangeInput(index, event,true,'key')}}
                onMount={handleEditorDidMount}
                options={{
                  automaticLayout: true,
                  suggestOnTriggerCharacters: true,
                  acceptSuggestionOnEnter: "on",
                  minimap: { enabled: false },
                }}
              />
              </div>
            ) : (
              <input
                name="key"
                placeholder="Key"
                value={inputField.key}
                onChange={(event) => handleChangeInput(index, event)}
                className="mb-[10px] w-[90%] h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
              />
            )}
            {inputField.key && tab1 !== "fixed" && (
              <div className="post-drag-value pb-[5px] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-50 -mt-[7px] mb-[3px]" title={updateInputValues(inputField.key)}>
                {updateInputValues(inputField.key)}
              </div>
            )}
          </div>
        </FixedExpressionTabs>
      </div>
      <div className="diff-dynamic-fields-with-dots-drop midform-field-has-dots-drop">
        <FixedExpressionTabs onExpressionChange={(value) => setTab2(value)}>
          <div
            ref={dropValue}
            className={`input-container flex flex-col justify-start items-end ${
              isOverValue ? "highlight" : ""
            }`}
          >
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px] w-[90%]">
              Value
            </label>
            {tab2 !== "fixed" ? (
              <div className="monaco_editor_parent">
              <Editor
                name="value"
                height="150px"
                defaultLanguage="javascript"
                value={inputField.value}
                theme="vs-light"
                onChange={(event) => handleChangeInput(index, event,true,'value')}
                onMount={handleEditorDidMount}
                options={{
                  automaticLayout: true,
                  suggestOnTriggerCharacters: true,
                  acceptSuggestionOnEnter: "on",
                  minimap: { enabled: false },
                }}
              />
              </div>
            ) : (
              <input
                name="value"
                placeholder="Value"
                value={inputField.value}
                onChange={(event) => handleChangeInput(index, event)}
                className="mb-[20px] w-[90%] h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#30313D] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal]"
              />
            )}
            {inputField.value && tab2 !== "fixed" && (
              <div className="post-drag-value pb-[5px] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal] opacity-50 -mt-[7px] mb-[3px]" title={updateInputValues(inputField.value)}>
                {updateInputValues(inputField.value)}
              </div>
            )}
          </div>
        </FixedExpressionTabs>
      </div>

      {inputFields.length > 1 && (
        <button
          onPointerDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={() => handleRemoveField(index)}
          className="w-[40px] h-[40px] flex items-center justify-center cursor-pointer opacity-100 hover:opacity-50 [transition:0.3s_opacity_ease] absolute top-[26px] left-[0]"
        >
          <img
            src="../../images/remove-minus-circle.svg"
            alt="remove-minus-circle"
            className="w-[20px]"
          />
        </button>
      )}
    </div>
  );
};

export default DraggableFieldDynamicInput;
