import { createRoot } from "react-dom/client";

import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions, Area2D } from "rete-area-plugin";
import {
  ClassicFlow,
  ConnectionPlugin,
  Presets as ConnectionPresets,
  getSourceTarget
} from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import {
  AutoArrangePlugin,
  Presets as ArrangePresets,
  ArrangeAppliers
} from "rete-auto-arrange-plugin";
import { ControlFlowEngine, DataflowEngine, DataflowNode } from "rete-engine";
import {
  ContextMenuExtra,
  ContextMenuPlugin,
  Presets as ContextMenuPresets
} from "rete-context-menu-plugin";
import { curveStep, curveMonotoneX, curveLinear, CurveFactory } from "d3-shape";
import { ConnectionPathPlugin } from "rete-connection-path-plugin";
import HttpNode from "./customization/Httpnode";
import { NodeProps, Schemes } from "./rete/types";
import { getConnectionSockets, sleep } from "./utils";
import * as ContextMenuComponents from './ui/context-menu';
import { ActionConnectionComponent } from "./ui/ActionConnection";
import { TextConnectionComponent } from "./ui/TextConnection";
import { ActionSocketComponent } from "./ui/ActionSocket";
import { TextSocketComponent } from "./ui/TextSocket";
import { addCustomBackground } from "./customization/custom-background";
import { CustomNodeComponent } from "./ui/CustomNode";
import { ChatNodeComponent } from "./ui/Chat";
import { DebugChat } from "./customization/debug-chat";
import { OnMessage } from "./customization/on-message";
import { HttpNodeComponent } from "./ui/httpNodeUI";
import { node } from "rete-area-3d-plugin/_types/extensions/forms";
import { JsonViewNodeComponent } from "./ui/jsonview";
import ScheduleTrigger from "./customization/ScheduleTrigger";
import { TriggerNode } from "./ui/triggerNode";
import Codenodes from "./customization/codeNodes";
import { CodesNodeUI } from "./ui/codenodes";
import WebHookTrigger from "./customization/webhookTrigger";
import { WebhookComponent } from "./ui/webhookTriggerUI";
import EditFields from "./customization/editFieldsNode";
import { EditFieldComponent } from "./ui/editfieldUI";
import MySqlNode from "./customization/mysqlNode";
import { MySqlNodeComponent } from "./ui/MySqlUI";
import createAPI from "./api"; 
import { exportGraph, importGraph } from "./services/import-export";
import HttpNodeCustomNode from "./customization/HttpnodecustomNode";
import { HttpNodeComponentCustomNode } from "./ui/httpNodeUIcustomNode";
const socket = new ClassicPreset.Socket("socket");

export class Start extends ClassicPreset.Node<{}, { exec: ClassicPreset.Socket }, {}> implements DataflowNode {
  width = 180;
  height = 90;

  constructor() {
    super("Start");
    this.addOutput("exec", new ClassicPreset.Output(socket, "Exec"));
  }
  data(): { value: any } {
    return {
      value: true
    };
  }
  execute(_: never, forward: (output: "exec") => void) {
    //    forward("exec");
  }
}

export type Context = {
  process: () => void;
  //modules: Modules<Schemes>;
  editor: NodeEditor<Schemes>;
  area: AreaPlugin<Schemes, any>;
  dataflow: DataflowEngine<Schemes>;
};

export class Log extends ClassicPreset.Node<
  { exec: ClassicPreset.Socket },
  { exec: ClassicPreset.Socket },
  { message: ClassicPreset.InputControl<"text"> }
> implements DataflowNode {
  width = 180;
  height = 150;

  constructor(message: string, private log: (text: string) => void) {
    super("Log");
    const control = new ClassicPreset.InputControl("text", {
      initial: message
    });

    this.addInput("exec", new ClassicPreset.Input(socket, "Exec", true));
    this.addControl("message", control);
    this.addOutput("exec", new ClassicPreset.Output(socket, "Exec"));
  }
  data(): { value: any } {
    return {
      value: this.controls.message.value
    };
  }
  execute(input: "exec", forward: (output: "exec") => void) {
    //this.log(this.controls.message.value as string);

    forward("exec");
  }
}
export class JsonView extends ClassicPreset.Node<
  { input: ClassicPreset.Socket },
  { exec: ClassicPreset.Socket },
  { json: ClassicPreset.InputControl<"text"> }
> implements DataflowNode {
  [x: string]: any;
  width = 180;
  height = 150;
  httpData: any
  constructor(json: string, private dataflow: DataflowEngine<Schemes>) {
    super("Log");
    this.dataflow = dataflow
    const control = new ClassicPreset.InputControl("text", {
      initial: json
    }
    );

    this.addInput('input', new ClassicPreset.Input(new TextSocket(), 'Json'));
    this.addControl("json", control);

  }

  worker(node: any, inputs: any) {
    console.log("Input data:", inputs.input);
    // Do something with the input data
    //this.addOutput("output", new ClassicPreset.Output(new TextSocket(), "Output"));
  }
  data() {
    console.log(this.httpData)
    return {
      text: this.httpData || ""
    };
  }
  async execute(_: never, forward: (output: "exec") => void) {
    //   const inputs = await this.dataflow.fetchInputs(this.id);

    //this.log(this.controls.message.value as string);
    console.log(this.control)
    //forward("exec");
  }
}

export class Delay extends ClassicPreset.Node<
  { exec: ClassicPreset.Socket },
  { exec: ClassicPreset.Socket },
  { value: ClassicPreset.InputControl<"number"> }
> implements DataflowNode {
  width = 180;
  height = 150;
  data: any; // Add this line

  constructor(seconds: number) {
    super("Delay");
    this.addInput("exec", new ClassicPreset.Input(socket, "Exec", true));
    this.addControl(
      "value",
      new ClassicPreset.InputControl("number", { initial: seconds })
    );
    this.addOutput("exec", new ClassicPreset.Output(socket, "Exec"));
  }

  execute(input: "exec", forward: (output: "exec") => void) {
    const value = this.controls.value.value;
    setTimeout(
      () => {
        forward("exec");
      },
      value ? value * 1000 : 1000
    );
  }
}

export class Connection<
  A extends NodeProps,
  B extends NodeProps,

> extends ClassicPreset.Connection<A, B> {
  isLoop?: boolean;
  curve?: CurveFactory;
}

/* type NodeProps = Start | Log | Delay | HttpNode | JsonView;
type ConnProps =
    | Connection<Start, Log>
    | Connection<Delay, Log>
    | Connection<Log, Delay>
    | Connection<Log, Log>
    | Connection<Delay, Delay>
    | Connection<HttpNode, Start>
    | Connection<JsonView, HttpNode>
    | Connection<HttpNode, JsonView>

type Schemes = GetSchemes<NodeProps, ConnProps>; */

type AreaExtra = ReactArea2D<any> | ContextMenuExtra;
let editor = null
let engine = null
export async function createEditor(
  container: HTMLElement,
  log: (text: string) => void
) {
  editor = new NodeEditor<Schemes>();
  const area = new AreaPlugin<Schemes, AreaExtra>(container);
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
  const arrange = new AutoArrangePlugin<Schemes>();
  engine = new ControlFlowEngine<Schemes>();
  const dataflow = new DataflowEngine<Schemes>(({ inputs, outputs }) => {
    return {
      inputs: () =>
        Object.entries(inputs)
          .filter(([_, input]) => input.socket instanceof TextSocket)
          .map(([name]) => name),
      outputs: () =>
        Object.entries(outputs)
          .filter(([_, output]) => output.socket instanceof TextSocket)
          .map(([name]) => name),
    };
  });
  const contextMenu = new ContextMenuPlugin<Schemes>({
    items: ContextMenuPresets.classic.setup([
      ["ScheduleTrigger", () => new ScheduleTrigger(dataflow, () => { }, log, editor)],
      ["WebHook", () => new WebHookTrigger(dataflow, () => { }, log, editor)],
      ["Http Node", () => new HttpNode(dataflow, (url) => { }, log, editor)],
      ["Code", () => new Codenodes(dataflow, () => { }, log, editor)],
      ["Edit Fields", () => new EditFields(dataflow, () => { }, log, editor)],
      ["MySQL", () => new MySqlNode(dataflow, () => { }, log, editor)], 
    ])
  });
  area.use(contextMenu);
  const chat = new DebugChat((message) => {
    area.update("node", chat.id);
    if (message.own) {
      const onMessage = editor
        .getNodes()
        .filter((n): n is OnMessage => n instanceof OnMessage);
      dataflow.reset();

      for (const node of onMessage) {
        node.inputMessage = message.message;
        engine.execute(node.id);
      }
    }
  });
  const webhook = new HttpNode(dataflow, (data) => {
    area.update("node", webhook.id);
    console.log('json', data)
    const HttpNoded = editor
      .getNodes()
      .filter((n): n is JsonView => n instanceof JsonView);
    for (const node of HttpNoded) {
      node.httpData = data;
      engine.execute(node.id);
    }
  }, log, editor); 
  const HttpNodeCustomNodeData = new HttpNodeCustomNode(dataflow, (data) => {
    area.update("node", webhook.id);
    console.log('json', data)
    const HttpNoded = editor
      .getNodes()
      .filter((n): n is JsonView => n instanceof JsonView);
    for (const node of HttpNoded) {
      node.httpData = data;
      engine.execute(node.id);
    }
  }, log, editor); 

  const scheduleTrigger = new ScheduleTrigger(dataflow, (data) => {

  }, log, editor);
  const codeNode = new Codenodes(dataflow, (data) => {
  }, log, editor);
  const mySqlNode = new MySqlNode(dataflow, (data) => {
  }, log, editor);

  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl()
  });

  render.addPreset(
    Presets.contextMenu.setup({
      customize: {
        main: () => ContextMenuComponents.Menu,
        item: () => ContextMenuComponents.Item,
        common: () => ContextMenuComponents.Common,
        search: () => ContextMenuComponents.Search,
        subitems: () => ContextMenuComponents.Subitems,
      },
    })
  );
  render.addPreset(
    Presets.classic.setup({
      customize: {
        connection(data) {
          const { source, target } = getConnectionSockets(editor, data.payload);
          return ActionConnectionComponent;
          if (
            source instanceof ActionSocket ||
            target instanceof ActionSocket
          ) {
            return ActionConnectionComponent;
          }
          return TextConnectionComponent;
        },
        socket(data) {
          
          //  return ActionSocketComponent;
          if (data.payload instanceof ActionSocket) {
            return ActionSocketComponent;
          }
          if (data.payload instanceof TextSocket) {
            return TextSocketComponent;
          }
          return Presets.classic.Socket;
        },
        node(data) {

          if (data.payload instanceof DebugChat) {
            return ChatNodeComponent;
          }
          if (data.payload instanceof HttpNode) {
            return HttpNodeComponent;
          }
          if (data.payload instanceof HttpNodeCustomNode) {
            return HttpNodeComponentCustomNode;
          }
          if (data.payload instanceof JsonView) {
            return JsonViewNodeComponent;
          }
          if (data.payload instanceof ScheduleTrigger) {
            return TriggerNode;
          }
          if (data.payload instanceof Codenodes) {
            return CodesNodeUI;
          }
          if (data.payload instanceof WebHookTrigger) {
            return WebhookComponent;
          }
          if (data.payload instanceof EditFields) {
            return EditFieldComponent;
          }
          if (data.payload instanceof MySqlNode) {
            return MySqlNodeComponent;
          }
           
          return CustomNodeComponent;
        },
      },
    })
  );
  render.addPreset(Presets.contextMenu.setup());
  render.addPreset(Presets.classic.setup());

  connection.addPreset(ConnectionPresets.classic.setup());

  arrange.addPreset(ArrangePresets.classic.setup());
  
  const pathPlugin = new ConnectionPathPlugin<Schemes, Area2D<Schemes>>({
    curve: (c) => c.curve || curveStep,
    // transformer: () => Transformers.classic({ vertical: false }),
    arrow: () => true,
  });

  // @ts-ignore
 // render.use(pathPlugin);
  editor.use(dataflow);
  editor.use(engine);
  editor.use(area);
  area.use(connection);
  area.use(render);
  area.use(arrange);

  AreaExtensions.simpleNodesOrder(area);
  AreaExtensions.showInputControl(area);
  addCustomBackground(area);

  
  editor.addPipe(async (context) => {
    
    if (context.type === 'nodecreate') {
      if (hasInputs(context.data) && editor.getNodes().length) {
        let lastNodeIndex = editor.getNodes().length - 1
        let lastNode = editor.getNodes()[lastNodeIndex]
        let currentNode = context.data
       
        const con1 = new Connection(lastNode, "output", currentNode, "input");
        await editor.addConnection(con1);
      } 
      return context
    }
    console.log(context)
    if (context.type === 'noderemove') {
   //  area.update("connection", context.data.editor.connections[0].id);
          context.data.editor.connections.forEach(val =>{ 
            if(val.source === context.data.id || val.target === context.data.id){
              area.removeConnectionView(val.id)
            }
          })
    }
    return context
  }) 
  await arrange.layout();
  AreaExtensions.zoomAt(area, editor.getNodes());
  console.log(Presets.contextMenu.Item)
 
  return {
    editor,
    dataflow,
    log,
    nodeContext: [
      new ScheduleTrigger(dataflow, () => { }, log, editor),
      new WebHookTrigger(dataflow, () => { }, log, editor),
      new HttpNode(dataflow, (url) => { }, log, editor),
      new Codenodes(dataflow, () => { }, log, editor),
      new EditFields(dataflow, () => { }, log, editor),
      new MySqlNode(dataflow, () => { }, log, editor), 
    ],
    saveModule: async (title) => { 
     // const data = editor.toJSON(); 
      let data = await exportGraph(editor)
      return { rete: data, title: title } 
    },
    runNodes: async () => {
      const onMessage = editor
        .getNodes()
        .filter((n): n is ScheduleTrigger => n instanceof ScheduleTrigger);
      //dataflow.reset();

      for (const node of onMessage) {
        //   node.inputMessage = message.message;

        engine.execute(node.id);

      }
      const webhooks = editor
        .getNodes()
        .filter((n): n is WebHookTrigger => n instanceof WebHookTrigger);
      //dataflow.reset();

      for (const webhook of webhooks) {
        engine.execute(webhook.id);

      }
    },
    importNodeByid: async (data)=>{
     await importGraph(data,editor,dataflow,log) 
     await arrange.layout();
     AreaExtensions.zoomAt(area, editor.getNodes());
     
    },
    destroy: () => area.destroy()
  };
}


// Socket

export class ActionSocket extends ClassicPreset.Socket {
  constructor() {
    super("Action");
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof ActionSocket;
  }
}

export class TextSocket extends ClassicPreset.Socket {
  constructor() {
    super("Text");
  }

  isCompatibleWith(socket: ClassicPreset.Socket) {
    return socket instanceof TextSocket;
  }
}
 
function serializePort(
  port:
    | ClassicPreset.Input<ClassicPreset.Socket>
    | ClassicPreset.Output<ClassicPreset.Socket>
) {
  return {
    id: port.id,
    label: port.label,
    socket: {
      name: port.socket.name
    }
  };
}

function serializeControl(control: ClassicPreset.Control) {
  if (control instanceof ClassicPreset.InputControl) {
    return {
      __type: "ClassicPreset.InputControl" as const,
      id: control.id,
      readonly: control.readonly,
      type: control.type,
      value: control.value
    };
  }
  return null;
}
export function deleteNodes(id) {
  editor.removeNode(id)
}
export async function copyNodeWithConnections(nodeId) {
  console.log(editor)
  const originalNode = editor.nodes.find(n => n.id === nodeId);
  if (!originalNode) {
    console.error("Node not found");
    return;
  }

  // Create a new node instance using the same constructor as the original node
  const NewNodeClass = originalNode.constructor;
  const newNode = new NewNodeClass();

  newNode.meta = { ...originalNode.meta };
  newNode.data = { ...originalNode.data };

  // Add the new node to the editor
  await editor.addNode(newNode);

}
export function isEmpty(obj) { 
  return Object.keys(obj).length === 0;
}
export function hasInputs(node) {
  return !isEmpty(node.inputs);
}
export function IsEntryNode(node) {
  return node?.isEntryNode ? false : true;
}
export async function copyNodeWithConnectionsCopy(nodeId, editor) {
  console.log(editor)
  const originalNode = editor.nodes.find(n => n.id === nodeId);
  if (!originalNode) {
    console.error("Node not found");
    return;
  }

  // Create a new node instance using the same constructor as the original node
  const NewNodeClass = originalNode.constructor;
  const newNode = new NewNodeClass();


  // Copy the state (inputs, outputs, controls)
  newNode.meta = { ...originalNode.meta };
  newNode.data = { ...originalNode.data };

  // Add the new node to the editor
  await editor.addNode(newNode);

}

export function disableNode(nodeIdToDisable) {
  const node = editor.nodes.find(n => n.id === nodeIdToDisable);
  // Add a class to the node to indicate it is disabled
  const nodeView = document.querySelector(`.node[data-id="${node.id}"]`);
  if (nodeView) {
    nodeView.classList.add('disabled-node');
  }
  node.draggable = true;
  node.locked = false;
}

export function enableNode(nodeIdToDisable) {
  const node = editor.nodes.find(n => n.id === nodeIdToDisable);
  // Remove the class to enable the node
  const nodeView = node.vueContext.$el;
  nodeView.classList.remove('disabled-node');
  node.draggable = true;
  node.locked = false;

  node.inputs.forEach(input => {
    input.socket.disabled = false;
  });
  node.outputs.forEach(output => {
    output.socket.disabled = false;
  });
}

export async function runNodes() {
  logMessage('Workflow started');

  const onMessage = editor
    .getNodes()
    .filter((n): n is ScheduleTrigger => n instanceof ScheduleTrigger);

  for (const node of onMessage) {
    try {
       engine.execute(node.id);
      logMessage(`Node ${node.id} executed successfully`);
    } catch (error) {
      logMessage(`Error executing node ${node.id}: ${error.message}`);
    }
  }
  const webhooks = editor
    .getNodes()
    .filter((n): n is WebHookTrigger => n instanceof WebHookTrigger);
  //dataflow.reset();

  for (const webhook of webhooks) {
    try {
      engine.execute(webhook.id);
      console.log('Hi form runcode')
      logMessage(`Node ${webhook.id} executed successfully`);
    } catch (error) {
      logMessage(`Error executing node ${webhook.id}: ${error.message}`);
    }

  }
  // Repeat for other node types if necessary
  logMessage('Workflow completed');
}
export function logMessage(message) {
  console.log(message); // Replace with your preferred logging method
  // Optionally send logs to a server or store them locally
}
