import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext"; // Import useAuth to access login method
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirecting after login
import createAPI from "../../api";
import { Link } from 'react-router-dom';

const Signin = () => {
  const [buttonText, setButtonText] = useState("Sign in");
  const { login } = useAuth(); // Destructure the login method from useAuth
  const navigate = useNavigate(); // Initialize navigate for redirecting
  const API = createAPI();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setButtonText("Submitting....");
        const response = await API.post("/auth/login", values);

        // Extract the user data and tokens from the response
        const { userData, tokens } = response.data.result;

        // Save the user data and tokens in the Auth context
        login(userData, tokens);

        // Show a success toast message
        toast.success(response.data.serverResponse.message, {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });

        // Redirect to the home page after successful login
        navigate("/home/workflow");
      } catch (error) {
        setButtonText("Sign in");
        // Show an error toast message
        toast.error(
          error.response?.data?.serverResponse?.message ||
            "Signin failed. Please try again.",
          {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          }
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="auth-main min-h-screen relative bg-[#F6F8FA]">
        <div className="auth-bg-block bg-[#E8F0FC] w-full h-[390px] absolute left-[0] top-[0] z-[0]"></div>
        <div className="auth-bg-icon auth-bg-icon-left block absolute left-[0] bottom-[0] z-[0]">
          <img src="/images/auth_left_watermark.svg" alt="auth_left_watermark" />
        </div>
        <div className="auth-bg-icon auth-bg-icon-right block absolute right-[0] top-[470px] z-[0]">
          <img src="/images/auth_right_watermark.svg" alt="auth_right_watermark" />
        </div>
        <div className="auth-inn max-w-[552px] mx-auto relative z-[0] pb-[100px]">
        <div className="auth-logo flex items-end justify-center pt-[100px] pb-[90px]">
          <img src="/images/auth_logo.svg" alt="auth_logo" width={52} />
          <span className="ml-[8px] text-[#1A1C1E] font-[plus-jakarta-sans-extrabold] text-[32px] leading-[48px]">WORKFLOW</span>
        </div>
        <div className="auth-msg text-center text-[#30313D] font-[inter-bold] text-[24px] leading-[28px] mb-[35px]">Sign in</div>
          <div className="auth-frame bg-[#fff] rounded-[8px] p-[40px]">
          <div className="auth-head mb-[30px]">
            <div className="auth-head-title text-[#30313D] font-[inter-bold] text-[18px] leading-[25px] mb-[5px]">Sign in</div>
            <div className="auth-head-subtitle text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px] ">Sign in sub heading text goes here.</div>
          </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="midform-field mb-[25px] relative">
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  Email <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="midform-field mb-[25px] relative">
                  <button className="auth_eye_action absolute right-[12px] top-[32px] cursor-pointer z-0">
                  <img src="/images/auth_eye.svg" alt="auth_eye" />
                </button>
                <label className="midform-field-label block text-[#595A64] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  Password <span className="text-[#df1941]">*</span>
                </label>
                <input
                  className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="primary_btn_hover mt-[32px] w-full mx-auto [transition:0.3s_opacity_ease] opacity-100 hover:opacity-80 flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic leading-[normal] !py-0 px-[15px] rounded-[6px]"
              >
                <span>{buttonText}</span>
              </button>
            </form>
            <div className="seperation-or relative flex align-center justify-center my-[10px]">
              <span className="seperation-or-text bg-[#fff] text-[#666] text-[12px] relative z-[1] px-[20px] font-[inter-regular] font-normal leading-[normal]">
                Or
              </span>
              <span className="seperation-or-rod block bg-[#146DE0] w-full h-[1px] absolute m-[auto] top-0 bottom-0 z-0 opacity-30"></span>
            </div>
            <Link
              to="/auth/signup"
              className="w-full mx-auto [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#146DE0] border-[#146DE0] text-[#146DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]"
            >
              Sign up
            </Link>
            <Link
              to="/auth/forgot"
              className="mt-[12px] w-full mx-auto [transition:0.3s_all_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] border-[1px] border-[solid] bg-transparent hover:bg-[#146DE0] border-[#146DE0] text-[#146DE0] hover:text-[#FFF] font-[inter-regular] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]"
            >
              Forgot Password
            </Link>
          </div>
          <div className="auth_support_text mt-[30px] text-center text-[#595A64] font-[inter-medium] text-[14px] leading-[19.6px]">
          If you encounter any issues while creating your password, feel free to contact our <a href="#" className="text-[#146DE0] underline">customer support team</a>.
        </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
