import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import AppDropdown from "./form/AppDropdown";

const DynamicFormPopupWithSelect = ({ isOpen, onClose, config, onSubmit, buttonText, dropdownItems, selectedDropdown, onDropdownChange }) => {
  // Create the validation schema dynamically 
  const [editWorkFlowName, setEditWorkFlowName] = useState(false);
  const [workFlowName, setWorkFlowName] = useState("Credentials");
  const workFlowNameRef = useRef("Credentials");
  const updateWorkFlowName = (name) => {
    setWorkFlowName(name);
    workFlowNameRef.current = name;
  };
  const validationSchema = config.fields.reduce((schema, field) => {
    if (field.validation) {
      schema[field.name] = field.validation;
    }
    return schema;
  }, {});

  const formik = useFormik({
    initialValues: config.fields.reduce((values, field) => {
      values[field.name] =
        field.defaultValue || (field.type === "checkbox" ? false : "");
      return values;
    }, {}),
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      
      onSubmit({values,workFlowName});
    },
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.success("Copied to clipboard");
    });
  };

  if (!isOpen) return null;

  return (
    <div className="credentials-popup credentials-popup-2 z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
      <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
        <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">
          <div className="cursor-pointer text-[#1A1C1E] font-[inter-bold] text-[18px] not-italic leading-[25.2px]">
                  {!editWorkFlowName && (
                    <div
                      onClick={() => setEditWorkFlowName((prv) => !prv)}
                      className="flex items-center justify-center"
                    >
                      {/* Display updated workFlowName if it exists, otherwise fall back to workFlowNameRef.current */}
                      {workFlowName || workFlowNameRef.current}
                      <img
                        src="../../images/node_inn_edit.svg"
                        alt="node_inn_edit"
                        className="ml-[5px] w-[18px] opacity-50"
                      />
                    </div>
                  )}
                  {editWorkFlowName && (
                    <div className="relative right-[11px]">
                      <input
                        className="relative border border-solid border-[#DCE4E8] rounded-[6px] h-[35px] px-[10px] py-[0] hover:border-[#146DE0] pr-[60px]"
                        type="text"
                        onChange={(e) => {
                          const newName = e.target.value;
                          if (newName.length > 60) {
                            // Limit the input length to 20 characters
                            //console.log("Maximum character length is 20");
                            return;
                          }
                          updateWorkFlowName(newName); // Update both the state and the ref
                        }}
                        value={workFlowName}
                      />
                      <button 
                        className="bg-[#146DE0] hover:bg-[#6093d5] focus:bg-[#0153bc] focus:border-[1px] border-[solid] border-[#0E4D9F] [transition:0.3s_all_ease] text-[#fff] [box-shadow:none] absolute top-[0] right-[5px] bottom-[0] m-auto h-[25px] w-[50px] rounded-[6px] border-0 !py-[0] font-[inter-medium] text-[12px] not-italic font-normal leading-[normal]"
                        onClick={() => setEditWorkFlowName(false)}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
          </div>
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={onClose}
          >
            <img src="../../images/close-popup.svg" alt="popup-close" />
          </button>
        </div>
        <div className="p-[29px] pb-[32px]">
        <div className="midform-field midform-field-select relative mb-[30px]">
            <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[5px]">Select app or service <span className="text-[#df1941]">*</span></label>
          
            <AppDropdown items={dropdownItems} selectedOption={selectedDropdown} onChange={onDropdownChange} />
          
          </div>
        
          <form onSubmit={formik.handleSubmit}>
            <div className="cred_form_fields_wrap max-h-[338px] overflow-auto">
            {config.fields.map((field) => (
              <div
                key={field.name}
                className="midform-field mb-[30px] relative"
              >
                <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                  {field.label}
                </label>
                <div className="flex items-center">
                  {field.type === "select" ? (
                    <Select
                      className="midform-select-main w-full"
                      classNamePrefix="midform-select"
                      name={field.name}
                      onChange={(option) =>
                        formik.setFieldValue(field.name, option)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values[field.name]}
                      options={field.options}
                      placeholder="Search for app..."
                    />
                  ) : field.type === "checkbox" ? (
                    <>
                    <div className="midform-input-fields-switch node-comm-switch relative flex items-center justify-start">
                      <div className="node-comm-real-switch relative">
                        <input
                          type="checkbox"
                          className="absolute w-full h-full opacity-0 cursor-pointer top-[0] left-[0] z-[1]"
                          name={field.name}
                          onChange={(e) => {
                            formik.setFieldValue(field.name, e.target.checked);
                          }}
                          onBlur={formik.handleBlur}
                          checked={formik.values[field.name]}
                        />
                        <label
                          className="cursor-pointer [text-indent:-9999px] w-[41px] h-[24px] bg-[#BAC1CC] block rounded-[20px] relative"
                          htmlFor="send-query-parameters-switch"
                        >
                          Toggle
                        </label>
                      </div>
                      </div>
                    </>
                  ) : (
                    <input
                      type={field.type || "text"}
                      name={field.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[field.name]}
                      className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                      readOnly={field.readOnly || false}
                      placeholder={field.placeholder || ""}
                    />
                  )}

                  {field.copyable && field.type !== "select" && (
                    <button
                      type="button"
                      onClick={() => copyToClipboard(formik.values[field.name])}
                      className="text-[#146DE0] font-[inter-regular] text-[11px] not-italic font-normal leading-[normal] ml-[10px]"
                    >
                      Copy
                    </button>
                  )}
                </div>
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ))}

            {/* Conditional fields rendering based on the value of checkbox fields */}
            {config.fields.map((field) => {
              if (
                field?.isConditional &&
                field.type === "checkbox" &&
                field.toggleFields
              ) {
                return formik.values[field.name]
                  ? field.toggleFields.map((toggleField) => (
                      <div
                        key={toggleField.name}
                        className="midform-field mb-[30px] relative"
                      >
                        <label className="midform-field-label block text-[#6C7278] font-[inter-regular] text-[12px] not-italic font-normal leading-[16.8px] mb-[4px]">
                          {toggleField.label}
                        </label>
                        <input
                          type={toggleField.type || "text"}
                          name={toggleField.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values[toggleField.name]}
                          className="w-full h-[40px] px-[14px] py-[0] rounded-[6px] border-[1px] border-[solid] border-[#cccccc] text-[#000] font-[inter-regular] text-[12px] not-italic font-normal leading-[normal]"
                          placeholder={toggleField.placeholder || ""}
                        />
                        {formik.touched[toggleField.name] &&
                          formik.errors[toggleField.name] && (
                            <div className="error text-[11px] text-[red] absolute top-full left-[0]">
                              {formik.errors[toggleField.name]}
                            </div>
                          )}
                      </div>
                    ))
                  : null;
              }
              return null;
            })}
            </div>
            <div className="form_comm_actions flex items-center justify-center">
            <button className={`w-[150px] py-2 px-4 rounded [transition:0.3s_opacity_ease] flex items-center justify-center border-0 h-[40px] [box-shadow:none] bg-[#E8F0FC] text-[#146DE0] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px] mr-[8px]`}>
            Cancel
            </button>
            <button
              type="submit"
              className="primary_btn_hover [transition:0.3s_opacity_ease] flex items-center justify-center border-0  w-[150px] h-[40px] [box-shadow:none] bg-[#146DE0] text-[#FFF] font-[inter-medium] text-[14px] not-italic font-normal leading-[normal] !py-0 px-[15px] rounded-[6px]"
            >
              {buttonText}
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DynamicFormPopupWithSelect;
