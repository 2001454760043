import React, { useState, useEffect } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'; 
import DraggableFieldDynamicInput from '../components/DraggableFieldDynamicInput';
import DraggableFieldDynamicInputWithType from './DraggableFieldDynaimicinputWithType';
import { sleep } from '../utils';

const DynamicKeyValueInput = ({ onDataChanged, formik, fieldName,inputData }) => {
    const [inputFields, setInputFields] = useState([{ key: '', value: '', type: '' }]);
    const [tab, setTab] = useState("fixed");
    const [isFirstTime, setFirstTime] = useState(true);
    useEffect(() => {
      if (onDataChanged) {
        validateArrayDetailed(inputFields);
        onDataChanged(inputFields);
        formik.setFieldValue(fieldName, inputFields);
      }
    }, [inputFields]);
    useEffect(() => {
      if (isFirstTime) {
      console.log('first',formik.values[fieldName])
        setInputFields(formik.values[fieldName])
        setFirstTime(false)
       
      }
    }, [isFirstTime]);
    const handleAddField = (event) => {
      event?.stopPropagation();
      const newFields = [...inputFields, { key: '', value: '', type: '' }];
      setInputFields(newFields);
    };
  
    const handleRemoveField = (index) => {
      const newFields = [...inputFields];
      newFields.splice(index, 1);
      setInputFields(newFields);
    };
  
    const handleChangeInput = (index, event,isEditor=false,name='') => {
     /*  const newFields = [...inputFields];
      newFields[index][event.target.name] = event.target.value;
      setInputFields(newFields); */
      
    console.log(event)
    if(isEditor){

      const newFields = [...inputFields];
      newFields[index][name] = event;
      setInputFields(newFields);
    }else{
      const newFields = [...inputFields];
      newFields[index][event.target.name] = event.target.value;
      setInputFields(newFields);
    }
    };
  
    const handleDrop = (item, index, targetType,customData=null) => {
      
       
      console.log(inputFields)
      const newFields = [...inputFields]; 
      const fieldKey = item.fieldKey;
      const formattedFieldKey = fieldKey
      .split('.')
      .map((key, index) => {
        if (!isNaN(key)) {
          // If the key is numeric, use bracket notation
          return `[${key}]`;
        }
        // For first key, just append with dot notation unless it's the root ($json)
        if (index === 0) {
          return `.${key}`;
        }
        // For other keys, use bracket notation if special characters exist
        return key.includes(" ") || key.includes("-") ? `["${key}"]` : `.${key}`;
      })
      .join('');
    const newValue = ` {{ $json${formattedFieldKey} }}`;
      newFields[index][targetType] = newValue
      setInputFields(newFields);
      setTab("expression");
    };
    const handleDropMain = (item, index, targetType,inputFieldsData=null) => { 
      console.log(inputFieldsData)
      const newFields = [...inputFieldsData]; 
      const fieldKey = item.fieldKey;
      const formattedFieldKey = fieldKey
      .split('.')
      .map((key, index) => {
        if (!isNaN(key)) {
          // If the key is numeric, use bracket notation
          return `[${key}]`;
        }
        // For first key, just append with dot notation unless it's the root ($json)
        if (index === 0) {
          return `.${key}`;
        }
        // For other keys, use bracket notation if special characters exist
        return key.includes(" ") || key.includes("-") ? `["${key}"]` : `.${key}`;
      })
      .join('');
    const newValue = ` {{ $json${formattedFieldKey} }}`;
      newFields[index][targetType] = newValue
      setInputFields(newFields);
      setTab("expression");
    };
  
    function validateArrayDetailed(arr) {
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        if (obj.key === '' || obj.value === '' || obj.type === '') {
          console.log(`Validation failed for object at index ${i}:`, obj);
          return false;
        }
      }
      return true;
    }
    const ItemType = {
      FIELD: "field",
    };
    const [{ canDropType, isOverType }, mainDrop] = useDrop({
      accept: ItemType.FIELD,
      drop:async (item) => {
       
      console.log('after sleep')
      console.log(inputFields)
       // setInputFields([{ key: '', value: '', type: '' }])
       handleDropMain(item, 0, 'key',[{ key: '', value: '', type: '' }])
      },
      collect: (monitor) => ({
        isOverType: monitor.isOver(),
        canDropType: monitor.canDrop(),
      }),
    });
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="midform-draggable-fields_stack">
          <div className="midform-draggable-fields_stack-items">
            {inputFields.map((inputField, index) => (
              <DraggableFieldDynamicInputWithType
                key={index}
                index={index}
                inputField={inputField}
                handleChangeInput={handleChangeInput}
                handleRemoveField={handleRemoveField}
                tab={tab}
                setTab={setTab}
                handleDrop={handleDrop}
                inputFields={inputFields}
                inputData={inputData}
              />
            ))}
          </div>
          <div className="midform-draggable-fields_stack-drag-input ml-[30px] rounded-[6px] border border-dashed border-[rgba(0,0,0,0.2)] bg-[#f7f7f7] flex items-center justify-center py-[17px]">
            <div className="midform-draggable-fields_initial-drag-input-action flex items-center justify-center" ref={mainDrop}>
              <div className="midform-draggable-fields_initial-drag-input-text text-center text-[#000] text-center font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] opacity-80">
                Drag input fields here
              </div>
              <div className="midform-draggable-fields_initial-drag-input-sep text-center opacity-80 text-[rgba(0,_0,_0,_0.60)] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] mx-[5px]">
                or
              </div>
              <button className="midform-draggable-fields_initial-drag-input-btn text-center opacity-80 text-[#146DE0] font-['inter-regular'] text-[12px] not-italic font-normal leading-[19.2px] hover:underline" onClick={handleAddField}>
                Add Field
              </button>
            </div>
          </div>
        </div>
      </DndProvider>
    );
};

export default DynamicKeyValueInput;
