import React, { useState } from "react";
import Select from "react-select";
import CustomSelectIconOption from "../CustomSelectIconOption";
import CustomSelectIconValue from "../CustomSelectIconValue";
import CustomSelectMultiValue from "../CustomSelectMultiValue";

const ImportCurlCode = ({ isOpen, onClose, onImportCurl  }) => {
    const [curlCommand, setCurlCommand] = React.useState('');

 

    const handleImport = () => {
      onImportCurl(curlCommand);
    };

  if (!isOpen) return null;

  return (
    <div className="credentials-popup credentials-popup-1 z-[100] fixed top-[0] left-[0] w-full h-full bg-[rgba(0,_0,_0,_0.5)] flex justify-center items-center">
      <div className="w-[624px] bg-[#fff] rounded-[6px] relative">
        <div className="popup-header rounded-tl-[14px] rounded-tr-[14px] bg-[#F5F9FF] px-[29px] flex items-center justify-between h-[57px]">
          <div className="text-[#1C1E21] font-['inter-semibold'] text-[18px] not-italic font-normal leading-[28px] -tracking-[0.34px]">
          Import cURL Command
          </div>
          <textarea
          value={curlCommand}
          onChange={(e) => setCurlCommand(e.target.value)}
          placeholder="Paste your cURL command here"
          style={{ width: "100%", height: "150px", marginBottom: "20px" }}
        />
          <button
            className="[transition:0.3s_opacity_ease] opacity-100 hover:opacity-50"
            onClick={handleImport}
          >
           Import
          </button>
        </div>
        <div className="px-[29px] pt-[32px] pb-[32px]">

        </div>
      </div>
    </div>
  );
};

export default ImportCurlCode;
